#service {
  padding-top: 3rem;
  padding-bottom: 3rem;
  position: relative;
  background-color: #f8f9fa;
}

.service-card {
  position: relative;
  border: 2px solid transparent;
  border-radius: 12px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.service-card:hover {
  border-color: rgba(0, 0, 0, 0.1);
  transform: translateY(-10px);
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.2);
}

.service-card img {
  position: relative;
  z-index: 1;
  transition: transform 0.3s ease-in-out;
}

.service-card:hover img {
  transform: scale(1.2);
}

.service-card::before {
  content: "\f00c";
  font-family: 'FontAwesome';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 60px;
  height: 60px;
  background-color: rgba(0, 0, 0, 0.7); /* Background color for the square */
  color: #fff; /* Icon color */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  transform: translate(-50%, -50%) scale(0);
  z-index: 2;
  transition: transform 0.3s ease-in-out;
}

.service-card:hover::before {
  transform: translate(-50%, -50%) scale(1);
}

.service-card .card-body {
  padding: 2rem;
  transition: all 0.3s ease-in-out;
}

.service-card h4 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.service-card p {
  font-size: 1rem;
  font-weight: 500;
  color: #6c757d;
}
