/* Modal overlay */
.contact-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  /* Center vertically */
  justify-content: center;
  /* Center horizontally */
  z-index: 9999;
}

/* Modal content */
.contact-modal-content {
  background: #fff;
  border-radius: 8px;
  padding: 40px;
  /* Increased padding for larger modal */
  max-width: 800px;
  /* Increased width for larger modal */
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  max-height: 85vh;
  overflow-y: auto;
}

/* Close button */
.contact-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #333;
}

/* Modal header */
.contact-modal-content h3 {
  margin-bottom: 20px;
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  text-align: center;
}

/* Form container */
.contact-form-container {
  display: flex;
  flex-direction: column;
}

/* Form field */
.contact-form-group {
  margin-bottom: 20px;
  /* Increased margin for better spacing */
}

.contact-form-label {
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
}

.contact-form-control {
  border-radius: 4px;
  border: 1px solid #ddd;
  padding: 10px;
  width: 100%;
}

.contact-form-textarea {
  resize: vertical;
  height: 120px;
}

/* Submit button */
.contact-btn-danger {
  background-color: #dc3545;
  border-color: #dc3545;
  color: #fff;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  margin: 20px auto;
  display: block;
  font-size: 0.875rem;
}

.contact-btn-danger:hover {
  background-color: #c82333;
  border-color: #bd2130;
}

.alert-box {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  border-radius: 5px;
  z-index: 10000;
  color: #fff;
  font-size: 1rem;
}

.alert-box.success {
  background-color: #28a745;
  /* Green background for success */
}

.alert-box.error {
  background-color: #dc3545;
  /* Red background for error */
}