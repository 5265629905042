.custom-modal {
    max-width: 100%;
    width: auto;
    padding: 0;
}

.custom-modal .modal-content {
    background-color: transparent;
    border-radius: 10px;
    padding: 0;
    text-align: center;
}

.body-content {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-container {
    position: relative;
    display: inline-block;
    width: auto;
}

.responsive-image {
    width: 70%;
    max-width: 90vw;
    /* Image resizes based on screen size */
    height: auto;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 2rem;
    color: #fff;
    background: none;
    border: none;
    cursor: pointer;
    text-decoration: none;
}

.close-button:focus,
.close-button:hover {
    text-decoration: none;
    color: #fff;
}