:root {
    --bs-blue: #747def;
    --bs-indigo: #6237ff;
    --bs-purple: #a1b0fc;
    --bs-pink: #d42da3;
    --bs-red: #df6951;
    --bs-orange: #ef7f1a;
    --bs-yellow: #f1a501;
    --bs-green: #79b93c;
    --bs-teal: #006380;
    --bs-cyan: #2fc4f1;
    --bs-white: #fffefe;
    --bs-gray: #f0f4f9;
    --bs-gray-dark: #616161;
    --bs-primary: #ef6262;
    --bs-secondary: #5e6282;
    --bs-success: #79b93c;
    --bs-info: #006380;
    --bs-warning: #f1a501;
    --bs-danger: #df6951;
    --bs-light: #f0f4f9;
    --bs-dark: #212832;
    --bs-font-sans-serif: "Poppins", "Rubik", "Open Sans", -apple-system,
        BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif,
        "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    --bs-font-monospace: "SFMono-Regular", Menlo, Monaco, Consolas,
        "Liberation Mono", "Courier New", monospace;
    --bs-gradient: linear-gradient(180deg,
            rgba(255, 254, 254, 0.15),
            rgba(255, 254, 254, 0));
    --bs-border-color: #e4e9f2;
    /* Updated border color */
}

*,
*::before,
*::after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
    :root {
        scroll-behavior: smooth;
    }
}

body {
    margin: 0;
    font-family: "Poppins", "Rubik", -apple-system, BlinkMacSystemFont, "Segoe UI",
        "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
        "Segoe UI Symbol";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #5e6282;
    background-color: #fffefe;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
    margin: 1rem 0;
    color: var(--bs-border-color);
    background-color: currentColor;
    border: 0;
    opacity: 1;
}

hr:not([size]) {
    height: 1px;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-family: "Poppins", "Rubik", "Open Sans", -apple-system,
        BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif,
        "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-weight: 600;
    line-height: 1.2;
    color: #14183e;
}

h1,
.h1 {
    font-size: calc(1.3052rem + 0.66244vw);
}

@media (min-width: 1200px) {

    h1,
    .h1 {
        font-size: 1.80203rem;
    }
}

h2,
.h2 {
    font-size: calc(1.28518rem + 0.42217vw);
}

@media (min-width: 1200px) {

    h2,
    .h2 {
        font-size: 1.60181rem;
    }
}

h3,
.h3 {
    font-size: calc(1.26738rem + 0.20859vw);
}

@media (min-width: 1200px) {

    h3,
    .h3 {
        font-size: 1.42383rem;
    }
}

h4,
.h4 {
    font-size: calc(1.25156rem + 0.01875vw);
}

@media (min-width: 1200px) {

    h4,
    .h4 {
        font-size: 1.26563rem;
    }
}

h5,
.h5 {
    font-size: 1.125rem;
}

h6,
.h6 {
    font-size: 0.88889rem;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}

abbr[title],
abbr[data-bs-original-title] {
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none;
}

address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit;
}

ol,
ul {
    padding-left: 2rem;
}

ol,
ul,
dl {
    margin-top: 0;
    margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
    margin-bottom: 0;
}

dt {
    font-weight: 700;
}

dd {
    margin-bottom: 0.5rem;
    margin-left: 0;
}

blockquote {
    margin: 0 0 1rem;
}

b,
strong {
    font-weight: 800;
}

small,
.small {
    font-size: 75%;
}

mark,
.mark {
    padding: 0.2em;
    background-color: #fcf8e3;
}

sub,
sup {
    position: relative;
    font-size: 0.75em;
    line-height: 0;
    vertical-align: baseline;
}

sub {
    bottom: -0.25em;
}

sup {
    top: -0.5em;
}

a {
    color: #212832;
    text-decoration: none;
}

a:hover {
    color: #ef7f1a;
    text-decoration: underline;
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none;
}

pre,
code,
kbd,
samp {
    font-family: var(--bs-font-monospace);
    font-size: 1em;
    direction: ltr
        /* rtl:ignore */
    ;
    unicode-bidi: bidi-override;
}

pre {
    display: block;
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    font-size: 75%;
}

pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal;
}

code {
    font-size: 75%;
    color: #d42da3;
    word-wrap: break-word;
}

a>code {
    color: inherit;
}

kbd {
    padding: 0.2rem 0.4rem;
    font-size: 75%;
    color: #fffefe;
    background-color: #5e6282;
    border-radius: 0.625rem;
}

kbd kbd {
    padding: 0;
    font-size: 1em;
    font-weight: 700;
}

figure {
    margin: 0 0 1rem;
}

img,
svg {
    vertical-align: middle;
}

table {
    caption-side: bottom;
    border-collapse: collapse;
}

caption {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    color: #bdbdbd;
    text-align: left;
}

th {
    text-align: inherit;
    text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
    border-color: var(--bs-border-color);
    /* Updated border color */
    border-style: solid;
    border-width: 0;
}

label {
    display: inline-block;
}

button {
    border-radius: 0;
}

button:focus:not(:focus-visible) {
    outline: 0;
}

input,
button,
select,
optgroup,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

button,
select {
    text-transform: none;
}

[role="button"] {
    cursor: pointer;
}

select {
    word-wrap: normal;
}

select:disabled {
    opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
    display: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
    -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
    cursor: pointer;
}

::-moz-focus-inner {
    padding: 0;
    border-style: none;
}

textarea {
    resize: vertical;
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
}

legend {
    float: left;
    width: 100%;
    margin-bottom: 0.5rem;
    padding: 0;
    border: 0;
    color: #5e6282;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: normal;
}

form {
    display: block;
    margin: 0;
}

label {
    display: block;
    margin-bottom: 0.5rem;
}

.form-group {
    margin-bottom: 1rem;
}

.form-control {
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212832;
    background-color: #fffefe;
    background-clip: padding-box;
    border: 1px solid #d1d3e0;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control:focus {
    color: #212832;
    background-color: #fffefe;
    border-color: #ef7f1a;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(239, 127, 26, 0.25);
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1;
}

.form-control::placeholder {
    color: #6c757d;
    opacity: 1;
}

.form-control-sm {
    height: calc(1.8125rem + 2px);
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
}

.form-control-lg {
    height: calc(3rem + 2px);
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
}

input[type="checkbox"] {
    margin: 0;
    padding: 0;
}

input[type="radio"] {
    margin: 0;
    padding: 0;
}

input[type="radio"]::before {
    content: "";
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    background: #d42da3;
}

input[type="radio"]:checked::before {
    background: #79b93c;
}

button:focus,
input:focus,
select:focus,
textarea:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(239, 127, 26, 0.25);
}

table {
    width: 100%;
    border-collapse: collapse;
}

table td,
table th {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #d1d3e0;
}

table caption {
    padding: 0.5rem 0;
    color: #6c757d;
    text-align: left;
}

table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #d1d3e0;
}

table tbody+tbody {
    border-top: 2px solid #d1d3e0;
}

table td {
    border-bottom: 1px solid #d1d3e0;
}

table th,
table td {
    border-color: var(--bs-border-color);
    /* Updated border color */
}

@media (min-width: 992px) {
    .col-lg {
        -webkit-box-flex: 1;
        -ms-flex: 1 0 0%;
        flex: 1 0 0%;
    }

    .row-cols-lg-auto>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
    }

    .row-cols-lg-1>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%;
    }

    .row-cols-lg-2>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 50%;
    }

    .row-cols-lg-3>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 33.33333%;
    }

    .row-cols-lg-4>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 25%;
    }

    .row-cols-lg-5>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 20%;
    }

    .row-cols-lg-6>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 16.66667%;
    }

    .col-lg-auto {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
    }

    .col-lg-1 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 8.33333%;
    }

    .col-lg-2 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 16.66667%;
    }

    .col-lg-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 25%;
    }

    .col-lg-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 33.33333%;
    }

    .col-lg-5 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 41.66667%;
    }

    .col-lg-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 50%;
    }

    .col-lg-7 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 58.33333%;
    }

    .col-lg-8 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 66.66667%;
    }

    .col-lg-9 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 75%;
    }

    .col-lg-10 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 83.33333%;
    }

    .col-lg-11 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 91.66667%;
    }

    .col-lg-12 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%;
    }

    .offset-lg-0 {
        margin-left: 0;
    }

    .offset-lg-1 {
        margin-left: 8.33333%;
    }

    .offset-lg-2 {
        margin-left: 16.66667%;
    }

    .offset-lg-3 {
        margin-left: 25%;
    }

    .offset-lg-4 {
        margin-left: 33.33333%;
    }

    .offset-lg-5 {
        margin-left: 41.66667%;
    }

    .offset-lg-6 {
        margin-left: 50%;
    }

    .offset-lg-7 {
        margin-left: 58.33333%;
    }

    .offset-lg-8 {
        margin-left: 66.66667%;
    }

    .offset-lg-9 {
        margin-left: 75%;
    }

    .offset-lg-10 {
        margin-left: 83.33333%;
    }

    .offset-lg-11 {
        margin-left: 91.66667%;
    }

    .g-lg-0,
    .gx-lg-0 {
        --bs-gutter-x: 0;
    }

    .g-lg-0,
    .gy-lg-0 {
        --bs-gutter-y: 0;
    }

    .g-lg-1,
    .gx-lg-1 {
        --bs-gutter-x: 0.25rem;
    }

    .g-lg-1,
    .gy-lg-1 {
        --bs-gutter-y: 0.25rem;
    }

    .g-lg-2,
    .gx-lg-2 {
        --bs-gutter-x: 0.5rem;
    }

    .g-lg-2,
    .gy-lg-2 {
        --bs-gutter-y: 0.5rem;
    }

    .g-lg-3,
    .gx-lg-3 {
        --bs-gutter-x: 1rem;
    }

    .g-lg-3,
    .gy-lg-3 {
        --bs-gutter-y: 1rem;
    }

    .g-lg-4,
    .gx-lg-4 {
        --bs-gutter-x: 1.8rem;
    }

    .g-lg-4,
    .gy-lg-4 {
        --bs-gutter-y: 1.8rem;
    }

    .g-lg-5,
    .gx-lg-5 {
        --bs-gutter-x: 2.5rem;
    }

    .g-lg-5,
    .gy-lg-5 {
        --bs-gutter-y: 2.5rem;
    }

    .g-lg-6,
    .gx-lg-6 {
        --bs-gutter-x: 3rem;
    }

    .g-lg-6,
    .gy-lg-6 {
        --bs-gutter-y: 3rem;
    }

    .g-lg-7,
    .gx-lg-7 {
        --bs-gutter-x: 4rem;
    }

    .g-lg-7,
    .gy-lg-7 {
        --bs-gutter-y: 4rem;
    }

    .g-lg-8,
    .gx-lg-8 {
        --bs-gutter-x: 5rem;
    }

    .g-lg-8,
    .gy-lg-8 {
        --bs-gutter-y: 5rem;
    }

    .g-lg-9,
    .gx-lg-9 {
        --bs-gutter-x: 7.5rem;
    }

    .g-lg-9,
    .gy-lg-9 {
        --bs-gutter-y: 7.5rem;
    }

    .g-lg-10,
    .gx-lg-10 {
        --bs-gutter-x: 10rem;
    }

    .g-lg-10,
    .gy-lg-10 {
        --bs-gutter-y: 10rem;
    }

    .g-lg-11,
    .gx-lg-11 {
        --bs-gutter-x: 12.5rem;
    }

    .g-lg-11,
    .gy-lg-11 {
        --bs-gutter-y: 12.5rem;
    }

    .g-lg-12,
    .gx-lg-12 {
        --bs-gutter-x: 15rem;
    }

    .g-lg-12,
    .gy-lg-12 {
        --bs-gutter-y: 15rem;
    }
}

@media (min-width: 1200px) {
    .col-xl {
        -webkit-box-flex: 1;
        -ms-flex: 1 0 0%;
        flex: 1 0 0%;
    }

    .row-cols-xl-auto>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
    }

    .row-cols-xl-1>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%;
    }

    .row-cols-xl-2>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 50%;
    }

    .row-cols-xl-3>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 33.33333%;
    }

    .row-cols-xl-4>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 25%;
    }

    .row-cols-xl-5>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 20%;
    }

    .row-cols-xl-6>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 16.66667%;
    }

    .col-xl-auto {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
    }

    .col-xl-1 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 8.33333%;
    }

    .col-xl-2 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 16.66667%;
    }

    .col-xl-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 25%;
    }

    .col-xl-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 33.33333%;
    }

    .col-xl-5 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 41.66667%;
    }

    .col-xl-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 50%;
    }

    .col-xl-7 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 58.33333%;
    }

    .col-xl-8 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 66.66667%;
    }

    .col-xl-9 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 75%;
    }

    .col-xl-10 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 83.33333%;
    }

    .col-xl-11 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 91.66667%;
    }

    .col-xl-12 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%;
    }

    .offset-xl-0 {
        margin-left: 0;
    }

    .offset-xl-1 {
        margin-left: 8.33333%;
    }

    .offset-xl-2 {
        margin-left: 16.66667%;
    }

    .offset-xl-3 {
        margin-left: 25%;
    }

    .offset-xl-4 {
        margin-left: 33.33333%;
    }

    .offset-xl-5 {
        margin-left: 41.66667%;
    }

    .offset-xl-6 {
        margin-left: 50%;
    }

    .offset-xl-7 {
        margin-left: 58.33333%;
    }

    .offset-xl-8 {
        margin-left: 66.66667%;
    }

    .offset-xl-9 {
        margin-left: 75%;
    }

    .offset-xl-10 {
        margin-left: 83.33333%;
    }

    .offset-xl-11 {
        margin-left: 91.66667%;
    }

    .g-xl-0,
    .gx-xl-0 {
        --bs-gutter-x: 0;
    }

    .g-xl-0,
    .gy-xl-0 {
        --bs-gutter-y: 0;
    }

    .g-xl-1,
    .gx-xl-1 {
        --bs-gutter-x: 0.25rem;
    }

    .g-xl-1,
    .gy-xl-1 {
        --bs-gutter-y: 0.25rem;
    }

    .g-xl-2,
    .gx-xl-2 {
        --bs-gutter-x: 0.5rem;
    }

    .g-xl-2,
    .gy-xl-2 {
        --bs-gutter-y: 0.5rem;
    }

    .g-xl-3,
    .gx-xl-3 {
        --bs-gutter-x: 1rem;
    }

    .g-xl-3,
    .gy-xl-3 {
        --bs-gutter-y: 1rem;
    }

    .g-xl-4,
    .gx-xl-4 {
        --bs-gutter-x: 1.8rem;
    }

    .g-xl-4,
    .gy-xl-4 {
        --bs-gutter-y: 1.8rem;
    }

    .g-xl-5,
    .gx-xl-5 {
        --bs-gutter-x: 2.5rem;
    }

    .g-xl-5,
    .gy-xl-5 {
        --bs-gutter-y: 2.5rem;
    }

    .g-xl-6,
    .gx-xl-6 {
        --bs-gutter-x: 3rem;
    }

    .g-xl-6,
    .gy-xl-6 {
        --bs-gutter-y: 3rem;
    }

    .g-xl-7,
    .gx-xl-7 {
        --bs-gutter-x: 4rem;
    }

    .g-xl-7,
    .gy-xl-7 {
        --bs-gutter-y: 4rem;
    }

    .g-xl-8,
    .gx-xl-8 {
        --bs-gutter-x: 5rem;
    }

    .g-xl-8,
    .gy-xl-8 {
        --bs-gutter-y: 5rem;
    }

    .g-xl-9,
    .gx-xl-9 {
        --bs-gutter-x: 7.5rem;
    }

    .g-xl-9,
    .gy-xl-9 {
        --bs-gutter-y: 7.5rem;
    }

    .g-xl-10,
    .gx-xl-10 {
        --bs-gutter-x: 10rem;
    }

    .g-xl-10,
    .gy-xl-10 {
        --bs-gutter-y: 10rem;
    }

    .g-xl-11,
    .gx-xl-11 {
        --bs-gutter-x: 12.5rem;
    }

    .g-xl-11,
    .gy-xl-11 {
        --bs-gutter-y: 12.5rem;
    }

    .g-xl-12,
    .gx-xl-12 {
        --bs-gutter-x: 15rem;
    }

    .g-xl-12,
    .gy-xl-12 {
        --bs-gutter-y: 15rem;
    }
}

@media (min-width: 1400px) {
    .col-xxl {
        -webkit-box-flex: 1;
        -ms-flex: 1 0 0%;
        flex: 1 0 0%;
    }

    .row-cols-xxl-auto>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
    }

    .row-cols-xxl-1>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%;
    }

    .row-cols-xxl-2>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 50%;
    }

    .row-cols-xxl-3>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 33.33333%;
    }

    .row-cols-xxl-4>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 25%;
    }

    .row-cols-xxl-5>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 20%;
    }

    .row-cols-xxl-6>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 16.66667%;
    }

    .col-xxl-auto {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
    }

    .col-xxl-1 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 8.33333%;
    }

    .col-xxl-2 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 16.66667%;
    }

    .col-xxl-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 25%;
    }

    .col-xxl-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 33.33333%;
    }

    .col-xxl-5 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 41.66667%;
    }

    .col-xxl-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 50%;
    }

    .col-xxl-7 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 58.33333%;
    }

    .col-xxl-8 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 66.66667%;
    }

    .col-xxl-9 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 75%;
    }

    .col-xxl-10 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 83.33333%;
    }

    .col-xxl-11 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 91.66667%;
    }

    .col-xxl-12 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%;
    }

    .offset-xxl-0 {
        margin-left: 0;
    }

    .offset-xxl-1 {
        margin-left: 8.33333%;
    }

    .offset-xxl-2 {
        margin-left: 16.66667%;
    }

    .offset-xxl-3 {
        margin-left: 25%;
    }

    .offset-xxl-4 {
        margin-left: 33.33333%;
    }

    .offset-xxl-5 {
        margin-left: 41.66667%;
    }

    .offset-xxl-6 {
        margin-left: 50%;
    }

    .offset-xxl-7 {
        margin-left: 58.33333%;
    }

    .offset-xxl-8 {
        margin-left: 66.66667%;
    }

    .offset-xxl-9 {
        margin-left: 75%;
    }

    .offset-xxl-10 {
        margin-left: 83.33333%;
    }

    .offset-xxl-11 {
        margin-left: 91.66667%;
    }

    .g-xxl-0,
    .gx-xxl-0 {
        --bs-gutter-x: 0;
    }

    .g-xxl-0,
    .gy-xxl-0 {
        --bs-gutter-y: 0;
    }

    .g-xxl-1,
    .gx-xxl-1 {
        --bs-gutter-x: 0.25rem;
    }

    .g-xxl-1,
    .gy-xxl-1 {
        --bs-gutter-y: 0.25rem;
    }

    .g-xxl-2,
    .gx-xxl-2 {
        --bs-gutter-x: 0.5rem;
    }

    .g-xxl-2,
    .gy-xxl-2 {
        --bs-gutter-y: 0.5rem;
    }

    .g-xxl-3,
    .gx-xxl-3 {
        --bs-gutter-x: 1rem;
    }

    .g-xxl-3,
    .gy-xxl-3 {
        --bs-gutter-y: 1rem;
    }

    .g-xxl-4,
    .gx-xxl-4 {
        --bs-gutter-x: 1.8rem;
    }

    .g-xxl-4,
    .gy-xxl-4 {
        --bs-gutter-y: 1.8rem;
    }

    .g-xxl-5,
    .gx-xxl-5 {
        --bs-gutter-x: 2.5rem;
    }

    .g-xxl-5,
    .gy-xxl-5 {
        --bs-gutter-y: 2.5rem;
    }

    .g-xxl-6,
    .gx-xxl-6 {
        --bs-gutter-x: 3rem;
    }

    .g-xxl-6,
    .gy-xxl-6 {
        --bs-gutter-y: 3rem;
    }

    .g-xxl-7,
    .gx-xxl-7 {
        --bs-gutter-x: 4rem;
    }

    .g-xxl-7,
    .gy-xxl-7 {
        --bs-gutter-y: 4rem;
    }

    .g-xxl-8,
    .gx-xxl-8 {
        --bs-gutter-x: 5rem;
    }

    .g-xxl-8,
    .gy-xxl-8 {
        --bs-gutter-y: 5rem;
    }

    .g-xxl-9,
    .gx-xxl-9 {
        --bs-gutter-x: 7.5rem;
    }

    .g-xxl-9,
    .gy-xxl-9 {
        --bs-gutter-y: 7.5rem;
    }

    .g-xxl-10,
    .gx-xxl-10 {
        --bs-gutter-x: 10rem;
    }

    .g-xxl-10,
    .gy-xxl-10 {
        --bs-gutter-y: 10rem;
    }

    .g-xxl-11,
    .gx-xxl-11 {
        --bs-gutter-x: 12.5rem;
    }

    .g-xxl-11,
    .gy-xxl-11 {
        --bs-gutter-y: 12.5rem;
    }

    .g-xxl-12,
    .gx-xxl-12 {
        --bs-gutter-x: 15rem;
    }

    .g-xxl-12,
    .gy-xxl-12 {
        --bs-gutter-y: 15rem;
    }
}

.table {
    --bs-table-bg: transparent;
    --bs-table-accent-bg: transparent;
    --bs-table-striped-color: #5e6282;
    --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
    --bs-table-active-color: #5e6282;
    --bs-table-active-bg: rgba(0, 0, 0, 0.1);
    --bs-table-hover-color: #5e6282;
    --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
    width: 100%;
    margin-bottom: 1rem;
    color: #5e6282;
    vertical-align: top;
    border-color: #eeeeee;
}

.table> :not(caption)>*>* {
    padding: 0.5rem 0.5rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: 1px;
    -webkit-box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

.table>tbody {
    vertical-align: inherit;
}

.table>thead {
    vertical-align: bottom;
}

.table> :not(:last-child)> :last-child>* {
    border-bottom-color: currentColor;
}

.caption-top {
    caption-side: top;
}

.table-sm> :not(caption)>*>* {
    padding: 0.25rem 0.25rem;
}

.table-bordered> :not(caption)>* {
    border-width: 1px 0;
}

.table-bordered> :not(caption)>*>* {
    border-width: 0 1px;
}

.table-borderless> :not(caption)>*>* {
    border-bottom-width: 0;
}

.table-striped>tbody>tr:nth-of-type(odd) {
    --bs-table-accent-bg: var(--bs-table-striped-bg);
    color: var(--bs-table-striped-color);
}

.table-active {
    --bs-table-accent-bg: var(--bs-table-active-bg);
    color: var(--bs-table-active-color);
}

.table-hover>tbody>tr:hover {
    --bs-table-accent-bg: var(--bs-table-hover-bg);
    color: var(--bs-table-hover-color);
}

.table-primary {
    --bs-table-bg: #fcedcc;
    --bs-table-striped-bg: #f4e6c7;
    --bs-table-striped-color: #616161;
    --bs-table-active-bg: #eddfc1;
    --bs-table-active-color: #616161;
    --bs-table-hover-bg: #f0e3c4;
    --bs-table-hover-color: #616161;
    color: #616161;
    border-color: #eddfc1;
}

.table-secondary {
    --bs-table-bg: #dfe0e6;
    --bs-table-striped-bg: #d9dadf;
    --bs-table-striped-color: #616161;
    --bs-table-active-bg: #d2d3d9;
    --bs-table-active-color: #616161;
    --bs-table-hover-bg: #d6d6dc;
    --bs-table-hover-color: #616161;
    color: #616161;
    border-color: #d2d3d9;
}

.table-success {
    --bs-table-bg: #e4f1d8;
    --bs-table-striped-bg: #ddead2;
    --bs-table-striped-color: #616161;
    --bs-table-active-bg: #d7e3cc;
    --bs-table-active-color: #616161;
    --bs-table-hover-bg: #dae6cf;
    --bs-table-hover-color: #616161;
    color: #616161;
    border-color: #d7e3cc;
}

.table-info {
    --bs-table-bg: #cce0e6;
    --bs-table-striped-bg: #c7dadf;
    --bs-table-striped-color: #616161;
    --bs-table-active-bg: #c1d3d9;
    --bs-table-active-color: #616161;
    --bs-table-hover-bg: #c4d6dc;
    --bs-table-hover-color: #616161;
    color: #616161;
    border-color: #c1d3d9;
}

.table-warning {
    --bs-table-bg: #fcedcc;
    --bs-table-striped-bg: #f4e6c7;
    --bs-table-striped-color: #616161;
    --bs-table-active-bg: #eddfc1;
    --bs-table-active-color: #616161;
    --bs-table-hover-bg: #f0e3c4;
    --bs-table-hover-color: #616161;
    color: #616161;
    border-color: #eddfc1;
}

.table-danger {
    --bs-table-bg: #f9e1dc;
    --bs-table-striped-bg: #f1dbd6;
    --bs-table-striped-color: #616161;
    --bs-table-active-bg: #ead4d0;
    --bs-table-active-color: #616161;
    --bs-table-hover-bg: #eed7d3;
    --bs-table-hover-color: #616161;
    color: #616161;
    border-color: #ead4d0;
}

.table-light {
    --bs-table-bg: #f0f4f9;
    --bs-table-striped-bg: #e9edf1;
    --bs-table-striped-color: #616161;
    --bs-table-active-bg: #e2e5ea;
    --bs-table-active-color: #616161;
    --bs-table-hover-bg: #e5e9ee;
    --bs-table-hover-color: #616161;
    color: #616161;
    border-color: #e2e5ea;
}

.table-dark {
    --bs-table-bg: #212832;
    --bs-table-striped-bg: #2c333c;
    --bs-table-striped-color: #fffefe;
    --bs-table-active-bg: #373d46;
    --bs-table-active-color: #fffefe;
    --bs-table-hover-bg: #323841;
    --bs-table-hover-color: #fffefe;
    color: #fffefe;
    border-color: #373d46;
}

.table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
    .table-responsive-sm {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
}

@media (max-width: 767.98px) {
    .table-responsive-md {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
}

@media (max-width: 991.98px) {
    .table-responsive-lg {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
}

@media (max-width: 1199.98px) {
    .table-responsive-xl {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
}

@media (max-width: 1399.98px) {
    .table-responsive-xxl {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
}

.form-label {
    margin-bottom: 0.5rem;
}

.col-form-label {
    padding-top: calc(0.375rem + 1px);
    padding-bottom: calc(0.375rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5;
}

.col-form-label-lg {
    padding-top: calc(0.5rem + 1px);
    padding-bottom: calc(0.5rem + 1px);
    font-size: 1rem;
}

.col-form-label-sm {
    padding-top: calc(0.25rem + 1px);
    padding-bottom: calc(0.25rem + 1px);
    font-size: 0.875rem;
}

.form-text {
    margin-top: 0.25rem;
    font-size: 75%;
    color: #bdbdbd;
}

.form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #5e6282;
    background-color: #f5f5f5;
    background-clip: padding-box;
    border: 1px solid #e0e0e0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 1.5rem;
    -webkit-transition: border-color 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
    -o-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .form-control {
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
    }
}

.form-control[type="file"] {
    overflow: hidden;
}

.form-control[type="file"]:not(:disabled):not([readonly]) {
    cursor: pointer;
}

.form-control:focus {
    color: #5e6282;
    background-color: #f5f5f5;
    border-color: #f8d280;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.25rem rgba(241, 165, 1, 0.25);
    box-shadow: 0 0 0 0.25rem rgba(241, 165, 1, 0.25);
}

.form-control::-webkit-date-and-time-value {
    height: 1.5em;
}

.form-control::-webkit-input-placeholder {
    color: #5e6282 !important;
    opacity: 1;
}

.form-control::-moz-placeholder {
    color: #5e6282 !important;
    opacity: 1;
}

.form-control:-ms-input-placeholder {
    color: #5e6282 !important;
    opacity: 1;
}

.form-control::-ms-input-placeholder {
    color: #5e6282 !important;
    opacity: 1;
}

.form-control::placeholder {
    color: #5e6282 !important;
    opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #f5f5f5;
    opacity: 1;
}

.form-control::file-selector-button {
    padding: 0.375rem 0.75rem;
    margin: -0.375rem -0.75rem;
    -webkit-margin-end: 0.75rem;
    margin-inline-end: 0.75rem;
    color: #5e6282;
    background-color: #f5f5f5;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    -webkit-transition: color 0.15s ease-in-out,
        background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -o-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .form-control::file-selector-button {
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
    }
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: #e9e9e9;
}

.form-control::-webkit-file-upload-button {
    padding: 0.375rem 0.75rem;
    margin: -0.375rem -0.75rem;
    -webkit-margin-end: 0.75rem;
    margin-inline-end: 0.75rem;
    color: #5e6282;
    background-color: #f5f5f5;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    -webkit-transition: color 0.15s ease-in-out,
        background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .form-control::-webkit-file-upload-button {
        -webkit-transition: none;
        transition: none;
    }
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
    background-color: #e9e9e9;
}

.form-control-plaintext {
    display: block;
    width: 100%;
    padding: 0.375rem 0;
    margin-bottom: 0;
    line-height: 1.5;
    color: #5e6282;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0;
}

.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0;
}

.form-control-sm {
    min-height: calc(1.5em + 0.5rem + 2px);
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    border-radius: 0.625rem;
}

.form-control-sm::file-selector-button {
    padding: 0.25rem 0.5rem;
    margin: -0.25rem -0.5rem;
    -webkit-margin-end: 0.5rem;
    margin-inline-end: 0.5rem;
}

.form-control-sm::-webkit-file-upload-button {
    padding: 0.25rem 0.5rem;
    margin: -0.25rem -0.5rem;
    -webkit-margin-end: 0.5rem;
    margin-inline-end: 0.5rem;
}

.form-control-lg {
    min-height: calc(1.5em + 1rem + 2px);
    padding: 0.5rem 1rem;
    font-size: 1rem;
    border-radius: 2.25rem;
}

.form-control-lg::file-selector-button {
    padding: 0.5rem 1rem;
    margin: -0.5rem -1rem;
    -webkit-margin-end: 1rem;
    margin-inline-end: 1rem;
}

.form-control-lg::-webkit-file-upload-button {
    padding: 0.5rem 1rem;
    margin: -0.5rem -1rem;
    -webkit-margin-end: 1rem;
    margin-inline-end: 1rem;
}

textarea.form-control {
    min-height: calc(1.5em + 0.75rem + 2px);
}

textarea.form-control-sm {
    min-height: calc(1.5em + 0.5rem + 2px);
}

textarea.form-control-lg {
    min-height: calc(1.5em + 1rem + 2px);
}

.form-control-color {
    max-width: 3rem;
    height: auto;
    padding: 0.375rem;
}

.form-control-color:not(:disabled):not([readonly]) {
    cursor: pointer;
}

.form-control-color::-moz-color-swatch {
    height: 1.5em;
    border-radius: 1.5rem;
}

.form-control-color::-webkit-color-swatch {
    height: 1.5em;
    border-radius: 1.5rem;
}

.form-select {
    display: block;
    width: 100%;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #5e6282;
    background-color: #f5f5f5;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23616161' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    border: 1px solid #e0e0e0;
    border-radius: 1.5rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.form-select:focus {
    border-color: #f8d280;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.25rem rgba(241, 165, 1, 0.25);
    box-shadow: 0 0 0 0.25rem rgba(241, 165, 1, 0.25);
}

.form-select[multiple],
.form-select[size]:not([size="1"]) {
    padding-right: 0.75rem;
    background-image: none;
}

.form-select:disabled {
    background-color: #f5f5f5;
}

.form-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #5e6282;
}

.form-select-sm {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.5rem;
    font-size: 0.875rem;
}

.form-select-lg {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    font-size: 1rem;
}

.form-check {
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5em;
    margin-bottom: 0.125rem;
}

.form-check .form-check-input {
    float: left;
    margin-left: -1.5em;
}

.form-check-input {
    width: 1em;
    height: 1em;
    margin-top: 0.25em;
    vertical-align: top;
    background-color: #f5f5f5;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid rgba(0, 0, 0, 0.25);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    /* Updated */
}


.form-check-input[type="checkbox"] {
    border-radius: 0.25em;
}

.form-check-input[type="radio"] {
    border-radius: 50%;
}

.form-check-input:active {
    -webkit-filter: brightness(90%);
    filter: brightness(90%);
}

.form-check-input:focus {
    border-color: #f8d280;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.25rem rgba(241, 165, 1, 0.25);
    box-shadow: 0 0 0 0.25rem rgba(241, 165, 1, 0.25);
}

.form-check-input:checked {
    background-color: #f1a501;
    border-color: #f1a501;
}

.form-check-input:checked[type="checkbox"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23FFFEFE' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}

.form-check-input:checked[type="radio"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23FFFEFE'/%3e%3c/svg%3e");
}

.form-check-input[type="checkbox"]:indeterminate {
    background-color: #f1a501;
    border-color: #f1a501;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23FFFEFE' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}

.form-check-input:disabled {
    pointer-events: none;
    -webkit-filter: none;
    filter: none;
    opacity: 0.5;
}

.form-check-input[disabled]~.form-check-label,
.form-check-input:disabled~.form-check-label {
    opacity: 0.5;
}

.form-switch {
    padding-left: 2.5em;
}

.form-switch .form-check-input {
    width: 2em;
    margin-left: -2.5em;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
    background-position: left center;
    border-radius: 2em;
    -webkit-transition: background-position 0.15s ease-in-out;
    -o-transition: background-position 0.15s ease-in-out;
    transition: background-position 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .form-switch .form-check-input {
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
    }
}

.form-switch .form-check-input:focus {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23f8d280'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
    background-position: right center;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23FFFEFE'/%3e%3c/svg%3e");
}

.form-check-inline {
    display: inline-block;
    margin-right: 1rem;
}

.btn-check {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
}

.btn-check[disabled]+.btn,
.btn-check:disabled+.btn {
    pointer-events: none;
    -webkit-filter: none;
    filter: none;
    opacity: 0.65;
}

.form-range {
    width: 100%;
    height: 1.5rem;
    padding: 0;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.form-range:focus {
    outline: 0;
}

.form-range:focus::-webkit-slider-thumb {
    -webkit-box-shadow: 0 0 0 1px #fffefe, 0 0 0 0.25rem rgba(241, 165, 1, 0.25);
    box-shadow: 0 0 0 1px #fffefe, 0 0 0 0.25rem rgba(241, 165, 1, 0.25);
}

.form-range:focus::-moz-range-thumb {
    box-shadow: 0 0 0 1px #fffefe, 0 0 0 0.25rem rgba(241, 165, 1, 0.25);
}

.form-range::-moz-focus-outer {
    border: 0;
}

.form-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #f1a501;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
    appearance: none;
}

@media (prefers-reduced-motion: reduce) {
    .form-range::-webkit-slider-thumb {
        -webkit-transition: none;
        transition: none;
    }
}

.form-range::-webkit-slider-thumb:active {
    background-color: #fbe4b3;
}

.form-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #eeeeee;
    border-color: transparent;
    border-radius: 1rem;
}

.form-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #f1a501;
    border: 0;
    border-radius: 1rem;
    -moz-transition: background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    -moz-appearance: none;
    appearance: none;
}

@media (prefers-reduced-motion: reduce) {
    .form-range::-moz-range-thumb {
        -moz-transition: none;
        transition: none;
    }
}

.form-range::-moz-range-thumb:active {
    background-color: #fbe4b3;
}

.form-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #eeeeee;
    border-color: transparent;
    border-radius: 1rem;
}

.form-range:disabled {
    pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
    background-color: #bdbdbd;
}

.form-range:disabled::-moz-range-thumb {
    background-color: #bdbdbd;
}

.form-floating {
    position: relative;
}

.form-floating>.form-control,
.form-floating>.form-select {
    height: calc(3.5rem + 2px);
    padding: 1rem 0.75rem;
}

.form-floating>label {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding: 1rem 0.75rem;
    pointer-events: none;
    border: 1px solid transparent;
    -webkit-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transition: opacity 0.1s ease-in-out,
        -webkit-transform 0.1s ease-in-out;
    transition: opacity 0.1s ease-in-out, -webkit-transform 0.1s ease-in-out;
    -o-transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out,
        -webkit-transform 0.1s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .form-floating>label {
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
    }
}

.form-floating>.form-control::-webkit-input-placeholder {
    color: transparent;
}

.form-floating>.form-control::-moz-placeholder {
    color: transparent;
}

.form-floating>.form-control:-ms-input-placeholder {
    color: transparent;
}

.form-floating>.form-control::-ms-input-placeholder {
    color: transparent;
}

.form-floating>.form-control::placeholder {
    color: transparent;
}

.form-floating>.form-control:not(:-moz-placeholder-shown) {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem;
}

.form-floating>.form-control:not(:-ms-input-placeholder) {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem;
}

.form-floating>.form-control:focus,
.form-floating>.form-control:not(:placeholder-shown) {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem;
}

.form-floating>.form-control:-webkit-autofill {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem;
}

.form-floating>.form-select {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem;
}

.form-floating>.form-control:not(:-moz-placeholder-shown)~label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating>.form-control:not(:-ms-input-placeholder)~label {
    opacity: 0.65;
    -ms-transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-select~label {
    opacity: 0.65;
    -webkit-transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
    -ms-transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating>.form-control:-webkit-autofill~label {
    opacity: 0.65;
    -webkit-transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.input-group {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%;
}

.input-group>.form-control,
.input-group>.form-select {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
}

.input-group>.form-control:focus,
.input-group>.form-select:focus {
    z-index: 3;
}

.input-group .btn {
    position: relative;
    z-index: 2;
}

.input-group .btn:focus {
    z-index: 3;
}

.input-group-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #5e6282;
    text-align: center;
    white-space: nowrap;
    background-color: #f5f5f5;
    border: 1px solid #e0e0e0;
    border-radius: 1.5rem;
}

.input-group-lg>.form-control,
.input-group-lg>.form-select,
.input-group-lg>.input-group-text,
.input-group-lg>.btn {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    border-radius: 2.25rem;
}

.input-group-sm>.form-control,
.input-group-sm>.form-select,
.input-group-sm>.input-group-text,
.input-group-sm>.btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    border-radius: 0.625rem;
}

.input-group-lg>.form-select,
.input-group-sm>.form-select {
    padding-right: 3rem;
}

.input-group:not(.has-validation)> :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n + 3) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group.has-validation> :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation>.dropdown-toggle:nth-last-child(n + 4) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.valid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 75%;
    color: #79b93c;
}

.valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: 0.1rem;
    font-size: 0.875rem;
    color: #fffefe;
    background-color: rgba(121, 185, 60, 0.9);
    border-radius: 1.5rem;
}

.was-validated :valid~.valid-feedback,
.was-validated :valid~.valid-tooltip,
.is-valid~.valid-feedback,
.is-valid~.valid-tooltip {
    display: block;
}

.was-validated .form-control:valid,
.form-control.is-valid {
    border-color: #79b93c;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2379B93C' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
    border-color: #79b93c;
    -webkit-box-shadow: 0 0 0 0.25rem rgba(121, 185, 60, 0.25);
    box-shadow: 0 0 0 0.25rem rgba(121, 185, 60, 0.25);
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
    padding-right: calc(1.5em + 0.75rem);
    background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid,
.form-select.is-valid {
    border-color: #79b93c;
}

.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated .form-select:valid:not([multiple])[size="1"],
.form-select.is-valid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple])[size="1"] {
    padding-right: 4.125rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23616161' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2379B93C' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-position: right 0.75rem center, center right 2.25rem;
    background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-select:valid:focus,
.form-select.is-valid:focus {
    border-color: #79b93c;
    -webkit-box-shadow: 0 0 0 0.25rem rgba(121, 185, 60, 0.25);
    box-shadow: 0 0 0 0.25rem rgba(121, 185, 60, 0.25);
}

.was-validated .form-check-input:valid,
.form-check-input.is-valid {
    border-color: #79b93c;
}

.was-validated .form-check-input:valid:checked,
.form-check-input.is-valid:checked {
    background-color: #79b93c;
}

.was-validated .form-check-input:valid:focus,
.form-check-input.is-valid:focus {
    -webkit-box-shadow: 0 0 0 0.25rem rgba(121, 185, 60, 0.25);
    box-shadow: 0 0 0 0.25rem rgba(121, 185, 60, 0.25);
}

.was-validated .form-check-input:valid~.form-check-label,
.form-check-input.is-valid~.form-check-label {
    color: #79b93c;
}

.form-check-inline .form-check-input~.valid-feedback {
    margin-left: 0.5em;
}

.was-validated .input-group .form-control:valid,
.input-group .form-control.is-valid,
.was-validated .input-group .form-select:valid,
.input-group .form-select.is-valid {
    z-index: 1;
}

.was-validated .input-group .form-control:valid:focus,
.input-group .form-control.is-valid:focus,
.was-validated .input-group .form-select:valid:focus,
.input-group .form-select.is-valid:focus {
    z-index: 3;
}

.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 75%;
    color: #df6951;
}

.invalid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: 0.1rem;
    font-size: 0.875rem;
    color: #fffefe;
    background-color: rgba(223, 105, 81, 0.9);
    border-radius: 1.5rem;
}

.was-validated :invalid~.invalid-feedback,
.was-validated :invalid~.invalid-tooltip,
.is-invalid~.invalid-feedback,
.is-invalid~.invalid-tooltip {
    display: block;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
    border-color: #df6951;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23DF6951'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23DF6951' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
    border-color: #df6951;
    -webkit-box-shadow: 0 0 0 0.25rem rgba(223, 105, 81, 0.25);
    box-shadow: 0 0 0 0.25rem rgba(223, 105, 81, 0.25);
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
    padding-right: calc(1.5em + 0.75rem);
    background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid,
.form-select.is-invalid {
    border-color: #df6951;
}

.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size="1"],
.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size="1"] {
    padding-right: 4.125rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23616161' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23DF6951'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23DF6951' stroke='none'/%3e%3c/svg%3e");
    background-position: right 0.75rem center, center right 2.25rem;
    background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-select:invalid:focus,
.form-select.is-invalid:focus {
    border-color: #df6951;
    -webkit-box-shadow: 0 0 0 0.25rem rgba(223, 105, 81, 0.25);
    box-shadow: 0 0 0 0.25rem rgba(223, 105, 81, 0.25);
}

.was-validated .form-check-input:invalid,
.form-check-input.is-invalid {
    border-color: #df6951;
}

.was-validated .form-check-input:invalid:checked,
.form-check-input.is-invalid:checked {
    background-color: #df6951;
}

.was-validated .form-check-input:invalid:focus,
.form-check-input.is-invalid:focus {
    -webkit-box-shadow: 0 0 0 0.25rem rgba(223, 105, 81, 0.25);
    box-shadow: 0 0 0 0.25rem rgba(223, 105, 81, 0.25);
}

.was-validated .form-check-input:invalid~.form-check-label,
.form-check-input.is-invalid~.form-check-label {
    color: #df6951;
}

.form-check-inline .form-check-input~.invalid-feedback {
    margin-left: 0.5em;
}

.was-validated .input-group .form-control:invalid,
.input-group .form-control.is-invalid,
.was-validated .input-group .form-select:invalid,
.input-group .form-select.is-invalid {
    z-index: 2;
}

.was-validated .input-group .form-control:invalid:focus,
.input-group .form-control.is-invalid:focus,
.was-validated .input-group .form-select:invalid:focus,
.input-group .form-select.is-invalid:focus {
    z-index: 3;
}

.btn {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #5e6282;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.2rem;
    -webkit-transition: color 0.15s ease-in-out,
        background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -o-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .btn {
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
    }
}

.btn:hover {
    color: #5e6282;
    text-decoration: none;
}

.btn-check:focus+.btn,
.btn:focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.25rem rgba(241, 165, 1, 0.25);
    box-shadow: 0 0 0 0.25rem rgba(241, 165, 1, 0.25);
}

.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
    pointer-events: none;
    opacity: 0.65;
}

.btn-primary {
    color: #fffefe;
    background-color: #ef6262;
    border-color: #ad2222;
}

.btn-primary:hover {
    color: #fffefe;
    background-color: #ad2222;
    border-color: orangered;
}

.btn-check:focus+.btn-primary,
.btn-primary:focus {
    color: #fffefe;
    background-color: #ef6262;
    border-color: #c18401;
    -webkit-box-shadow: 0 0 0 0 rgba(243, 178, 39, 0.5);
    box-shadow: 0 0 0 0 rgba(243, 178, 39, 0.5);
}

.btn-check:checked+.btn-primary,
.btn-check:active+.btn-primary,
.btn-primary:active,
.btn-primary.active,
.show>.btn-primary.dropdown-toggle {
    color: #fffefe;
    background-color: #ef6262;
    border-color: #b57c01;
}

.btn-check:checked+.btn-primary:focus,
.btn-check:active+.btn-primary:focus,
.btn-primary:active:focus,
.btn-primary.active:focus,
.show>.btn-primary.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(243, 178, 39, 0.5);
    box-shadow: 0 0 0 0 rgba(243, 178, 39, 0.5);
}

.btn-primary:disabled,
.btn-primary.disabled {
    color: #fffefe;
    background-color: #f1a501;
    border-color: #f1a501;
}

.btn-secondary {
    color: #fffefe;
    background-color: #5e6282;
    border-color: #5e6282;
}

.btn-secondary:hover {
    color: #fffefe;
    background-color: #50536f;
    border-color: #4b4e68;
}

.btn-check:focus+.btn-secondary,
.btn-secondary:focus {
    color: #fffefe;
    background-color: #50536f;
    border-color: #4b4e68;
    -webkit-box-shadow: 0 0 0 0 rgba(118, 121, 149, 0.5);
    box-shadow: 0 0 0 0 rgba(118, 121, 149, 0.5);
}

.btn-check:checked+.btn-secondary,
.btn-check:active+.btn-secondary,
.btn-secondary:active,
.btn-secondary.active,
.show>.btn-secondary.dropdown-toggle {
    color: #fffefe;
    background-color: #4b4e68;
    border-color: #474a62;
}

.btn-check:checked+.btn-secondary:focus,
.btn-check:active+.btn-secondary:focus,
.btn-secondary:active:focus,
.btn-secondary.active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(118, 121, 149, 0.5);
    box-shadow: 0 0 0 0 rgba(118, 121, 149, 0.5);
}

.btn-secondary:disabled,
.btn-secondary.disabled {
    color: #fffefe;
    background-color: #5e6282;
    border-color: #5e6282;
}

.btn-success {
    color: #fffefe;
    background-color: #79b93c;
    border-color: #79b93c;
}

.btn-success:hover {
    color: #fffefe;
    background-color: #679d33;
    border-color: #619430;
}

.btn-check:focus+.btn-success,
.btn-success:focus {
    color: #fffefe;
    background-color: #679d33;
    border-color: #619430;
    -webkit-box-shadow: 0 0 0 0 rgba(141, 195, 89, 0.5);
    box-shadow: 0 0 0 0 rgba(141, 195, 89, 0.5);
}

.btn-check:checked+.btn-success,
.btn-check:active+.btn-success,
.btn-success:active,
.btn-success.active,
.show>.btn-success.dropdown-toggle {
    color: #fffefe;
    background-color: #619430;
    border-color: #5b8b2d;
}

.btn-check:checked+.btn-success:focus,
.btn-check:active+.btn-success:focus,
.btn-success:active:focus,
.btn-success.active:focus,
.show>.btn-success.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(141, 195, 89, 0.5);
    box-shadow: 0 0 0 0 rgba(141, 195, 89, 0.5);
}

.btn-success:disabled,
.btn-success.disabled {
    color: #fffefe;
    background-color: #79b93c;
    border-color: #79b93c;
}

.btn-info {
    color: #fffefe;
    background-color: #006380;
    border-color: #006380;
}

.btn-info:hover {
    color: #fffefe;
    background-color: #00546d;
    border-color: #004f66;
}

.btn-check:focus+.btn-info,
.btn-info:focus {
    color: #fffefe;
    background-color: #00546d;
    border-color: #004f66;
    -webkit-box-shadow: 0 0 0 0 rgba(38, 122, 147, 0.5);
    box-shadow: 0 0 0 0 rgba(38, 122, 147, 0.5);
}

.btn-check:checked+.btn-info,
.btn-check:active+.btn-info,
.btn-info:active,
.btn-info.active,
.show>.btn-info.dropdown-toggle {
    color: #fffefe;
    background-color: #004f66;
    border-color: #004a60;
}

.btn-check:checked+.btn-info:focus,
.btn-check:active+.btn-info:focus,
.btn-info:active:focus,
.btn-info.active:focus,
.show>.btn-info.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(38, 122, 147, 0.5);
    box-shadow: 0 0 0 0 rgba(38, 122, 147, 0.5);
}

.btn-info:disabled,
.btn-info.disabled {
    color: #fffefe;
    background-color: #006380;
    border-color: #006380;
}

.btn-warning {
    color: #fffefe;
    background-color: #f1a501;
    border-color: #f1a501;
}

.btn-warning:hover {
    color: #fffefe;
    background-color: #ef6262;
    border-color: #c18401;
}

.btn-check:focus+.btn-warning,
.btn-warning:focus {
    color: #fffefe;
    background-color: #ef6262;
    border-color: #c18401;
    -webkit-box-shadow: 0 0 0 0 rgba(243, 178, 39, 0.5);
    box-shadow: 0 0 0 0 rgba(243, 178, 39, 0.5);
}

.btn-check:checked+.btn-warning,
.btn-check:active+.btn-warning,
.btn-warning:active,
.btn-warning.active,
.show>.btn-warning.dropdown-toggle {
    color: #fffefe;
    background-color: #c18401;
    border-color: #b57c01;
}

.btn-check:checked+.btn-warning:focus,
.btn-check:active+.btn-warning:focus,
.btn-warning:active:focus,
.btn-warning.active:focus,
.show>.btn-warning.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(243, 178, 39, 0.5);
    box-shadow: 0 0 0 0 rgba(243, 178, 39, 0.5);
}

.btn-warning:disabled,
.btn-warning.disabled {
    color: #fffefe;
    background-color: #f1a501;
    border-color: #f1a501;
}

.btn-danger {
    color: #fffefe;
    background-color: #df6951;
    border-color: #df6951;
}

.btn-danger:hover {
    color: #fffefe;
    background-color: #be5945;
    border-color: #b25441;
}

.btn-check:focus+.btn-danger,
.btn-danger:focus {
    color: #fffefe;
    background-color: #be5945;
    border-color: #b25441;
    -webkit-box-shadow: 0 0 0 0 rgba(228, 127, 107, 0.5);
    box-shadow: 0 0 0 0 rgba(228, 127, 107, 0.5);
}

.btn-check:checked+.btn-danger,
.btn-check:active+.btn-danger,
.btn-danger:active,
.btn-danger.active,
.show>.btn-danger.dropdown-toggle {
    color: #fffefe;
    background-color: #b25441;
    border-color: #a74f3d;
}

.btn-check:checked+.btn-danger:focus,
.btn-check:active+.btn-danger:focus,
.btn-danger:active:focus,
.btn-danger.active:focus,
.show>.btn-danger.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(228, 127, 107, 0.5);
    box-shadow: 0 0 0 0 rgba(228, 127, 107, 0.5);
}

.btn-danger:disabled,
.btn-danger.disabled {
    color: #fffefe;
    background-color: #df6951;
    border-color: #df6951;
}

.btn-light {
    color: #616161;
    background-color: #f0f4f9;
    border-color: #f0f4f9;
}

.btn-light:hover {
    color: #616161;
    background-color: #f2f6fa;
    border-color: #f2f5fa;
}

.btn-check:focus+.btn-light,
.btn-light:focus {
    color: #616161;
    background-color: #f2f6fa;
    border-color: #f2f5fa;
    -webkit-box-shadow: 0 0 0 0 rgba(219, 222, 226, 0.5);
    box-shadow: 0 0 0 0 rgba(219, 222, 226, 0.5);
}

.btn-check:checked+.btn-light,
.btn-check:active+.btn-light,
.btn-light:active,
.btn-light.active,
.show>.btn-light.dropdown-toggle {
    color: #616161;
    background-color: #f3f6fa;
    border-color: #f2f5fa;
}

.btn-check:checked+.btn-light:focus,
.btn-check:active+.btn-light:focus,
.btn-light:active:focus,
.btn-light.active:focus,
.show>.btn-light.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(219, 222, 226, 0.5);
    box-shadow: 0 0 0 0 rgba(219, 222, 226, 0.5);
}

.btn-light:disabled,
.btn-light.disabled {
    color: #616161;
    background-color: #f0f4f9;
    border-color: #f0f4f9;
}

.btn-dark {
    color: #fffefe;
    background-color: #212832;
    border-color: #212832;
}

.btn-dark:hover {
    color: #fffefe;
    background-color: #1c222b;
    border-color: #1a2028;
}

.btn-check:focus+.btn-dark,
.btn-dark:focus {
    color: #fffefe;
    background-color: #1c222b;
    border-color: #1a2028;
    -webkit-box-shadow: 0 0 0 0 rgba(66, 72, 81, 0.5);
    box-shadow: 0 0 0 0 rgba(66, 72, 81, 0.5);
}

.btn-check:checked+.btn-dark,
.btn-check:active+.btn-dark,
.btn-dark:active,
.btn-dark.active,
.show>.btn-dark.dropdown-toggle {
    color: #fffefe;
    background-color: #1a2028;
    border-color: #191e26;
}

.btn-check:checked+.btn-dark:focus,
.btn-check:active+.btn-dark:focus,
.btn-dark:active:focus,
.btn-dark.active:focus,
.show>.btn-dark.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(66, 72, 81, 0.5);
    box-shadow: 0 0 0 0 rgba(66, 72, 81, 0.5);
}

.btn-dark:disabled,
.btn-dark.disabled {
    color: #fffefe;
    background-color: #212832;
    border-color: #212832;
}

.btn-outline-primary {
    color: #f1a501;
    border-color: #f1a501;
}

.btn-outline-primary:hover {
    color: #fffefe;
    background-color: #f1a501;
    border-color: #f1a501;
}

.btn-check:focus+.btn-outline-primary,
.btn-outline-primary:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(241, 165, 1, 0.5);
    box-shadow: 0 0 0 0 rgba(241, 165, 1, 0.5);
}

.btn-check:checked+.btn-outline-primary,
.btn-check:active+.btn-outline-primary,
.btn-outline-primary:active,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show {
    color: #fffefe;
    background-color: #f1a501;
    border-color: #f1a501;
}

.btn-check:checked+.btn-outline-primary:focus,
.btn-check:active+.btn-outline-primary:focus,
.btn-outline-primary:active:focus,
.btn-outline-primary.active:focus,
.btn-outline-primary.dropdown-toggle.show:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(241, 165, 1, 0.5);
    box-shadow: 0 0 0 0 rgba(241, 165, 1, 0.5);
}

.btn-outline-primary:disabled,
.btn-outline-primary.disabled {
    color: #f1a501;
    background-color: transparent;
}

.btn-outline-secondary {
    color: #5e6282;
    border-color: #5e6282;
}

.btn-outline-secondary:hover {
    color: #fffefe;
    background-color: #5e6282;
    border-color: #5e6282;
}

.btn-check:focus+.btn-outline-secondary,
.btn-outline-secondary:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(94, 98, 130, 0.5);
    box-shadow: 0 0 0 0 rgba(94, 98, 130, 0.5);
}

.btn-check:checked+.btn-outline-secondary,
.btn-check:active+.btn-outline-secondary,
.btn-outline-secondary:active,
.btn-outline-secondary.active,
.btn-outline-secondary.dropdown-toggle.show {
    color: #fffefe;
    background-color: #5e6282;
    border-color: #5e6282;
}

.btn-check:checked+.btn-outline-secondary:focus,
.btn-check:active+.btn-outline-secondary:focus,
.btn-outline-secondary:active:focus,
.btn-outline-secondary.active:focus,
.btn-outline-secondary.dropdown-toggle.show:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(94, 98, 130, 0.5);
    box-shadow: 0 0 0 0 rgba(94, 98, 130, 0.5);
}

.btn-outline-secondary:disabled,
.btn-outline-secondary.disabled {
    color: #5e6282;
    background-color: transparent;
}

.btn-outline-success {
    color: #79b93c;
    border-color: #79b93c;
}

.btn-outline-success:hover {
    color: #fffefe;
    background-color: #79b93c;
    border-color: #79b93c;
}

.btn-check:focus+.btn-outline-success,
.btn-outline-success:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(121, 185, 60, 0.5);
    box-shadow: 0 0 0 0 rgba(121, 185, 60, 0.5);
}

.btn-check:checked+.btn-outline-success,
.btn-check:active+.btn-outline-success,
.btn-outline-success:active,
.btn-outline-success.active,
.btn-outline-success.dropdown-toggle.show {
    color: #fffefe;
    background-color: #79b93c;
    border-color: #79b93c;
}

.btn-check:checked+.btn-outline-success:focus,
.btn-check:active+.btn-outline-success:focus,
.btn-outline-success:active:focus,
.btn-outline-success.active:focus,
.btn-outline-success.dropdown-toggle.show:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(121, 185, 60, 0.5);
    box-shadow: 0 0 0 0 rgba(121, 185, 60, 0.5);
}

.btn-outline-success:disabled,
.btn-outline-success.disabled {
    color: #79b93c;
    background-color: transparent;
}

.btn-outline-info {
    color: #006380;
    border-color: #006380;
}

.btn-outline-info:hover {
    color: #fffefe;
    background-color: #006380;
    border-color: #006380;
}

.btn-check:focus+.btn-outline-info,
.btn-outline-info:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 99, 128, 0.5);
    box-shadow: 0 0 0 0 rgba(0, 99, 128, 0.5);
}

.btn-check:checked+.btn-outline-info,
.btn-check:active+.btn-outline-info,
.btn-outline-info:active,
.btn-outline-info.active,
.btn-outline-info.dropdown-toggle.show {
    color: #fffefe;
    background-color: #006380;
    border-color: #006380;
}

.btn-check:checked+.btn-outline-info:focus,
.btn-check:active+.btn-outline-info:focus,
.btn-outline-info:active:focus,
.btn-outline-info.active:focus,
.btn-outline-info.dropdown-toggle.show:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 99, 128, 0.5);
    box-shadow: 0 0 0 0 rgba(0, 99, 128, 0.5);
}

.btn-outline-info:disabled,
.btn-outline-info.disabled {
    color: #006380;
    background-color: transparent;
}

.btn-outline-warning {
    color: #f1a501;
    border-color: #f1a501;
}

.btn-outline-warning:hover {
    color: #fffefe;
    background-color: #f1a501;
    border-color: #f1a501;
}

.btn-check:focus+.btn-outline-warning,
.btn-outline-warning:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(241, 165, 1, 0.5);
    box-shadow: 0 0 0 0 rgba(241, 165, 1, 0.5);
}

.btn-check:checked+.btn-outline-warning,
.btn-check:active+.btn-outline-warning,
.btn-outline-warning:active,
.btn-outline-warning.active,
.btn-outline-warning.dropdown-toggle.show {
    color: #fffefe;
    background-color: #f1a501;
    border-color: #f1a501;
}

.btn-check:checked+.btn-outline-warning:focus,
.btn-check:active+.btn-outline-warning:focus,
.btn-outline-warning:active:focus,
.btn-outline-warning.active:focus,
.btn-outline-warning.dropdown-toggle.show:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(241, 165, 1, 0.5);
    box-shadow: 0 0 0 0 rgba(241, 165, 1, 0.5);
}

.btn-outline-warning:disabled,
.btn-outline-warning.disabled {
    color: #f1a501;
    background-color: transparent;
}

.btn-outline-danger {
    color: #df6951;
    border-color: #df6951;
}

.btn-outline-danger:hover {
    color: #fffefe;
    background-color: #df6951;
    border-color: #df6951;
}

.btn-check:focus+.btn-outline-danger,
.btn-outline-danger:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(223, 105, 81, 0.5);
    box-shadow: 0 0 0 0 rgba(223, 105, 81, 0.5);
}

.btn-check:checked+.btn-outline-danger,
.btn-check:active+.btn-outline-danger,
.btn-outline-danger:active,
.btn-outline-danger.active,
.btn-outline-danger.dropdown-toggle.show {
    color: #fffefe;
    background-color: #df6951;
    border-color: #df6951;
}

.btn-check:checked+.btn-outline-danger:focus,
.btn-check:active+.btn-outline-danger:focus,
.btn-outline-danger:active:focus,
.btn-outline-danger.active:focus,
.btn-outline-danger.dropdown-toggle.show:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(223, 105, 81, 0.5);
    box-shadow: 0 0 0 0 rgba(223, 105, 81, 0.5);
}

.btn-outline-danger:disabled,
.btn-outline-danger.disabled {
    color: #df6951;
    background-color: transparent;
}

.btn-outline-light {
    color: #f0f4f9;
    border-color: #f0f4f9;
}

.btn-outline-light:hover {
    color: #616161;
    background-color: #f0f4f9;
    border-color: #f0f4f9;
}

.btn-check:focus+.btn-outline-light,
.btn-outline-light:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(240, 244, 249, 0.5);
    box-shadow: 0 0 0 0 rgba(240, 244, 249, 0.5);
}

.btn-check:checked+.btn-outline-light,
.btn-check:active+.btn-outline-light,
.btn-outline-light:active,
.btn-outline-light.active,
.btn-outline-light.dropdown-toggle.show {
    color: #616161;
    background-color: #f0f4f9;
    border-color: #f0f4f9;
}

.btn-check:checked+.btn-outline-light:focus,
.btn-check:active+.btn-outline-light:focus,
.btn-outline-light:active:focus,
.btn-outline-light.active:focus,
.btn-outline-light.dropdown-toggle.show:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(240, 244, 249, 0.5);
    box-shadow: 0 0 0 0 rgba(240, 244, 249, 0.5);
}

.btn-outline-light:disabled,
.btn-outline-light.disabled {
    color: #f0f4f9;
    background-color: transparent;
}

.btn-outline-dark {
    color: #212832;
    border-color: #212832;
}

.btn-outline-dark:hover {
    color: #fffefe;
    background-color: #212832;
    border-color: #212832;
}

.btn-check:focus+.btn-outline-dark,
.btn-outline-dark:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(33, 40, 50, 0.5);
    box-shadow: 0 0 0 0 rgba(33, 40, 50, 0.5);
}

.btn-check:checked+.btn-outline-dark,
.btn-check:active+.btn-outline-dark,
.btn-outline-dark:active,
.btn-outline-dark.active,
.btn-outline-dark.dropdown-toggle.show {
    color: #fffefe;
    background-color: #212832;
    border-color: #212832;
}

.btn-check:checked+.btn-outline-dark:focus,
.btn-check:active+.btn-outline-dark:focus,
.btn-outline-dark:active:focus,
.btn-outline-dark.active:focus,
.btn-outline-dark.dropdown-toggle.show:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(33, 40, 50, 0.5);
    box-shadow: 0 0 0 0 rgba(33, 40, 50, 0.5);
}

.btn-outline-dark:disabled,
.btn-outline-dark.disabled {
    color: #212832;
    background-color: transparent;
}

.btn-link {
    font-weight: 400;
    color: #212832;
    text-decoration: none;
}

.btn-link:hover {
    color: #f1a501;
    text-decoration: underline;
}

.btn-link:focus {
    text-decoration: underline;
}

.btn-link:disabled,
.btn-link.disabled {
    color: #f0f4f9;
}

.btn-lg,
.btn-group-lg>.btn {
    padding: 0.8rem 1.7rem;
    font-size: 1.125rem;
    border-radius: 0.625rem;
}

.btn-sm,
.btn-group-sm>.btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    border-radius: 0.625rem;
}

.fade {
    -webkit-transition: opacity 0.15s linear;
    -o-transition: opacity 0.15s linear;
    transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
    .fade {
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
    }
}

.fade:not(.show) {
    opacity: 0;
}

.collapse:not(.show) {
    display: none;
}

.collapsing {
    height: 0;
    overflow: hidden;
    -webkit-transition: height 0.35s ease;
    -o-transition: height 0.35s ease;
    transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
    .collapsing {
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
    }
}

.dropup,
.dropend,
.dropdown,
.dropstart {
    position: relative;
}

.dropdown-toggle {
    white-space: nowrap;
}

.dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
    margin-left: 0;
}

.dropdown-menu {
    position: absolute;
    z-index: 1000;
    display: none;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0;
    font-size: 1rem;
    color: #5e6282;
    text-align: left;
    list-style: none;
    background-color: #fffefe;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 1.5rem;
}

.dropdown-menu[data-bs-popper] {
    top: 100%;
    left: 0;
    margin-top: 0.125rem;
}

.dropdown-menu-start {
    --bs-position: start;
}

.dropdown-menu-start[data-bs-popper] {
    right: auto
        /* rtl:ignore */
    ;
    left: 0
        /* rtl:ignore */
    ;
}

.dropdown-menu-end {
    --bs-position: end;
}

.dropdown-menu-end[data-bs-popper] {
    right: 0
        /* rtl:ignore */
    ;
    left: auto
        /* rtl:ignore */
    ;
}

@media (min-width: 576px) {
    .dropdown-menu-sm-start {
        --bs-position: start;
    }

    .dropdown-menu-sm-start[data-bs-popper] {
        right: auto
            /* rtl:ignore */
        ;
        left: 0
            /* rtl:ignore */
        ;
    }

    .dropdown-menu-sm-end {
        --bs-position: end;
    }

    .dropdown-menu-sm-end[data-bs-popper] {
        right: 0
            /* rtl:ignore */
        ;
        left: auto
            /* rtl:ignore */
        ;
    }
}

@media (min-width: 768px) {
    .dropdown-menu-md-start {
        --bs-position: start;
    }

    .dropdown-menu-md-start[data-bs-popper] {
        right: auto
            /* rtl:ignore */
        ;
        left: 0
            /* rtl:ignore */
        ;
    }

    .dropdown-menu-md-end {
        --bs-position: end;
    }

    .dropdown-menu-md-end[data-bs-popper] {
        right: 0
            /* rtl:ignore */
        ;
        left: auto
            /* rtl:ignore */
        ;
    }
}

@media (min-width: 992px) {
    .dropdown-menu-lg-start {
        --bs-position: start;
    }

    .dropdown-menu-lg-start[data-bs-popper] {
        right: auto
            /* rtl:ignore */
        ;
        left: 0
            /* rtl:ignore */
        ;
    }

    .dropdown-menu-lg-end {
        --bs-position: end;
    }

    .dropdown-menu-lg-end[data-bs-popper] {
        right: 0
            /* rtl:ignore */
        ;
        left: auto
            /* rtl:ignore */
        ;
    }
}

@media (min-width: 1200px) {
    .dropdown-menu-xl-start {
        --bs-position: start;
    }

    .dropdown-menu-xl-start[data-bs-popper] {
        right: auto
            /* rtl:ignore */
        ;
        left: 0
            /* rtl:ignore */
        ;
    }

    .dropdown-menu-xl-end {
        --bs-position: end;
    }

    .dropdown-menu-xl-end[data-bs-popper] {
        right: 0
            /* rtl:ignore */
        ;
        left: auto
            /* rtl:ignore */
        ;
    }
}

@media (min-width: 1400px) {
    .dropdown-menu-xxl-start {
        --bs-position: start;
    }

    .dropdown-menu-xxl-start[data-bs-popper] {
        right: auto
            /* rtl:ignore */
        ;
        left: 0
            /* rtl:ignore */
        ;
    }

    .dropdown-menu-xxl-end {
        --bs-position: end;
    }

    .dropdown-menu-xxl-end[data-bs-popper] {
        right: 0
            /* rtl:ignore */
        ;
        left: auto
            /* rtl:ignore */
        ;
    }
}

.dropup .dropdown-menu[data-bs-popper] {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0;
    border-right: 0.3em solid transparent;
    border-bottom: 0.3em solid;
    border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
    margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: 0.125rem;
}

.dropend .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-right: 0;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid;
}

.dropend .dropdown-toggle:empty::after {
    margin-left: 0;
}

.dropend .dropdown-toggle::after {
    vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: 0.125rem;
}

.dropstart .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
}

.dropstart .dropdown-toggle::after {
    display: none;
}

.dropstart .dropdown-toggle::before {
    display: inline-block;
    margin-right: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-right: 0.3em solid;
    border-bottom: 0.3em solid transparent;
}

.dropstart .dropdown-toggle:empty::after {
    margin-left: 0;
}

.dropstart .dropdown-toggle::before {
    vertical-align: 0;
}

.dropdown-divider {
    height: 0;
    margin: 0.5rem 0;
    overflow: hidden;
    border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: 0.25rem 1rem;
    clear: both;
    font-weight: 400;
    color: #5e6282;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}

.dropdown-item:hover,
.dropdown-item:focus {
    color: #555875;
    text-decoration: none;
    background-color: #f5f5f5;
}

.dropdown-item.active,
.dropdown-item:active {
    color: #fffefe;
    text-decoration: none;
    background-color: #f1a501;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
    color: #bdbdbd;
    pointer-events: none;
    background-color: transparent;
}

.dropdown-menu.show {
    display: block;
}

.dropdown-header {
    display: block;
    padding: 0.5rem 1rem;
    margin-bottom: 0;
    font-size: 0.875rem;
    color: #f0f4f9;
    white-space: nowrap;
}

.dropdown-item-text {
    display: block;
    padding: 0.25rem 1rem;
    color: #5e6282;
}

.dropdown-menu-dark {
    color: #eeeeee;
    background-color: #616161;
    border-color: rgba(0, 0, 0, 0.15);
}

.dropdown-menu-dark .dropdown-item {
    color: #eeeeee;
}

.dropdown-menu-dark .dropdown-item:hover,
.dropdown-menu-dark .dropdown-item:focus {
    color: #fffefe;
    background-color: rgba(255, 254, 254, 0.15);
}

.dropdown-menu-dark .dropdown-item.active,
.dropdown-menu-dark .dropdown-item:active {
    color: #fffefe;
    background-color: #f1a501;
}

.dropdown-menu-dark .dropdown-item.disabled,
.dropdown-menu-dark .dropdown-item:disabled {
    color: #bdbdbd;
}

.dropdown-menu-dark .dropdown-divider {
    border-color: rgba(0, 0, 0, 0.15);
}

.dropdown-menu-dark .dropdown-item-text {
    color: #eeeeee;
}

.dropdown-menu-dark .dropdown-header {
    color: #bdbdbd;
}

.btn-group,
.btn-group-vertical {
    position: relative;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    vertical-align: middle;
}

.btn-group>.btn,
.btn-group-vertical>.btn {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
}

.btn-group>.btn-check:checked+.btn,
.btn-group>.btn-check:focus+.btn,
.btn-group>.btn:hover,
.btn-group>.btn:focus,
.btn-group>.btn:active,
.btn-group>.btn.active,
.btn-group-vertical>.btn-check:checked+.btn,
.btn-group-vertical>.btn-check:focus+.btn,
.btn-group-vertical>.btn:hover,
.btn-group-vertical>.btn:focus,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn.active {
    z-index: 1;
}

.btn-toolbar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.btn-toolbar .input-group {
    width: auto;
}

.btn-group>.btn:not(:first-child),
.btn-group>.btn-group:not(:first-child) {
    margin-left: -1px;
}

.btn-group>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group>.btn-group:not(:last-child)>.btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.btn-group>.btn:nth-child(n + 3),
.btn-group> :not(.btn-check)+.btn,
.btn-group>.btn-group:not(:first-child)>.btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
    padding-right: 0.5625rem;
    padding-left: 0.5625rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropend .dropdown-toggle-split::after {
    margin-left: 0;
}

.dropstart .dropdown-toggle-split::before {
    margin-right: 0;
}

.btn-sm+.dropdown-toggle-split,
.btn-group-sm>.btn+.dropdown-toggle-split {
    padding-right: 0.375rem;
    padding-left: 0.375rem;
}

.btn-lg+.dropdown-toggle-split,
.btn-group-lg>.btn+.dropdown-toggle-split {
    padding-right: 1.275rem;
    padding-left: 1.275rem;
}

.btn-group-vertical {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.btn-group-vertical>.btn,
.btn-group-vertical>.btn-group {
    width: 100%;
}

.btn-group-vertical>.btn:not(:first-child),
.btn-group-vertical>.btn-group:not(:first-child) {
    margin-top: -1px;
}

.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical>.btn-group:not(:last-child)>.btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.btn-group-vertical>.btn~.btn,
.btn-group-vertical>.btn-group:not(:first-child)>.btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.nav-link {
    display: block;
    padding: 0.5rem 1rem;
    color: #212832;
    -webkit-transition: color 0.15s ease-in-out,
        background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
    -o-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .nav-link {
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
    }
}

.nav-link:hover,
.nav-link:focus {
    color: #f1a501;
    text-decoration: none;
}

.nav-link.disabled {
    color: #f0f4f9;
    pointer-events: none;
    cursor: default;
}

.nav-tabs {
    border-bottom: 1px solid #eeeeee;
}

.nav-tabs .nav-link {
    margin-bottom: -1px;
    background: none;
    border: 1px solid transparent;
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
    border-color: #f5f5f5 #f5f5f5 #eeeeee;
    isolation: isolate;
}

.nav-tabs .nav-link.disabled {
    color: #f0f4f9;
    background-color: transparent;
    border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    color: #df6951;
    background-color: rgba(223, 105, 81, 0.15);
    border-color: transparent;
}

.nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.nav-pills .nav-link {
    background: none;
    border: 0;
    border-radius: 1.5rem;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fffefe;
    background-color: #f1a501;
}

.nav-fill>.nav-link,
.nav-fill .nav-item {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    text-align: center;
}

.nav-justified>.nav-link,
.nav-justified .nav-item {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
    width: 100%;
}

.tab-content>.tab-pane {
    display: none;
}

.tab-content>.active {
    display: block;
}

.navbar {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.navbar>.container,
.navbar>.container-fluid,
.navbar>.container-sm,
.navbar>.container-md,
.navbar>.container-lg,
.navbar>.container-xl,
.navbar>.container-xxl {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: inherit;
    flex-wrap: inherit;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.navbar-brand {
    padding-top: 0.18213rem;
    padding-bottom: 0.18213rem;
    font-size: calc(1.26738rem + 0.20859vw);
    white-space: nowrap;
}

@media (min-width: 1200px) {
    .navbar-brand {
        font-size: 1.42383rem;
    }
}

.navbar-brand:hover,
.navbar-brand:focus {
    text-decoration: none;
}

.navbar-nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
}

.navbar-nav .dropdown-menu {
    position: static;
}

.navbar-text {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.navbar-collapse {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.navbar-toggler {
    font-size: 1.2rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.2rem;
    -webkit-transition: -webkit-box-shadow 0.15s ease-in-out;
    transition: -webkit-box-shadow 0.15s ease-in-out;
    -o-transition: box-shadow 0.15s ease-in-out;
    transition: box-shadow 0.15s ease-in-out;
    transition: box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .navbar-toggler {
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
    }
}

.navbar-toggler:hover {
    text-decoration: none;
}

.navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0;
    box-shadow: 0 0 0 0;
}

.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
}

.navbar-nav-scroll {
    max-height: var(--bs-scroll-height, 75vh);
    overflow-y: auto;
}

@media (min-width: 576px) {
    .navbar-expand-sm {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }

    .navbar-expand-sm .navbar-nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
    }

    .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute;
    }

    .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }

    .navbar-expand-sm .navbar-nav-scroll {
        overflow: visible;
    }

    .navbar-expand-sm .navbar-collapse {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
    }

    .navbar-expand-sm .navbar-toggler {
        display: none;
    }
}

@media (min-width: 768px) {
    .navbar-expand-md {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }

    .navbar-expand-md .navbar-nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
    }

    .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute;
    }

    .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }

    .navbar-expand-md .navbar-nav-scroll {
        overflow: visible;
    }

    .navbar-expand-md .navbar-collapse {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
    }

    .navbar-expand-md .navbar-toggler {
        display: none;
    }
}

@media (min-width: 992px) {
    .navbar-expand-lg {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }

    .navbar-expand-lg .navbar-nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
    }

    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute;
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }

    .navbar-expand-lg .navbar-nav-scroll {
        overflow: visible;
    }

    .navbar-expand-lg .navbar-collapse {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
    }

    .navbar-expand-lg .navbar-toggler {
        display: none;
    }
}

@media (min-width: 1200px) {
    .navbar-expand-xl {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }

    .navbar-expand-xl .navbar-nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
    }

    .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute;
    }

    .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }

    .navbar-expand-xl .navbar-nav-scroll {
        overflow: visible;
    }

    .navbar-expand-xl .navbar-collapse {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
    }

    .navbar-expand-xl .navbar-toggler {
        display: none;
    }
}

@media (min-width: 1400px) {
    .navbar-expand-xxl {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }

    .navbar-expand-xxl .navbar-nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
    }

    .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute;
    }

    .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }

    .navbar-expand-xxl .navbar-nav-scroll {
        overflow: visible;
    }

    .navbar-expand-xxl .navbar-collapse {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
    }

    .navbar-expand-xxl .navbar-toggler {
        display: none;
    }
}

.navbar-expand {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.navbar-expand .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
    position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}

.navbar-expand .navbar-nav-scroll {
    overflow: visible;
}

.navbar-expand .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
}

.navbar-expand .navbar-toggler {
    display: none;
}

.navbar-light .navbar-brand {
    color: #f1a501;
}

.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
    color: #f1a501;
}

.navbar-light .navbar-nav .nav-link {
    color: #212832;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
    color: #f1a501;
}

.navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.25);
}

.navbar-light .navbar-nav .show>.nav-link,
.navbar-light .navbar-nav .nav-link.active {
    color: #f1a501;
}

.navbar-light .navbar-toggler {
    color: #212832;
    border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%23212832' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M0 6h30M0 14h30M0 22h30'/%3E%3C/svg%3E");
}

.navbar-light .navbar-text {
    color: #212832;
}

.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
    color: #f1a501;
}

.navbar-dark .navbar-brand {
    color: #fffefe;
}

.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
    color: #fffefe;
}

.navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 254, 254, 0.7);
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 254, 254, 0.9);
}

.navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 254, 254, 0.25);
}

.navbar-dark .navbar-nav .show>.nav-link,
.navbar-dark .navbar-nav .nav-link.active {
    color: #fffefe;
}

.navbar-dark .navbar-toggler {
    color: rgba(255, 254, 254, 0.7);
    border-color: rgba(255, 254, 254, 0.1);
}

.navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 254, 254, 0.7%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
    color: rgba(255, 254, 254, 0.7);
}

.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
    color: #fffefe;
}

.card {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fffefe;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 1.5rem;
    overflow: hidden;
}

.card>hr {
    margin-right: 0;
    margin-left: 0;
}

.card>.list-group {
    border-top: inherit;
    border-bottom: inherit;
}

.card>.list-group:first-child {
    border-top-width: 0;
    border-top-left-radius: calc(1.5rem - 1px);
    border-top-right-radius: calc(1.5rem - 1px);
}

.card>.list-group:last-child {
    border-bottom-width: 0;
    border-bottom-right-radius: calc(1.5rem - 1px);
    border-bottom-left-radius: calc(1.5rem - 1px);
}

.card>.card-header+.list-group,
.card>.list-group+.card-footer {
    border-top: 0;
}

.card-body {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1rem 1rem;
}

.card-title {
    margin-bottom: 0.5rem;
}

.card-subtitle {
    margin-top: -0.25rem;
    margin-bottom: 0;
}

.card-text:last-child {
    margin-bottom: 0;
}

.card-link:hover {
    text-decoration: none;
}

.card-link+.card-link {
    margin-left: 1rem;
}

.card-header {
    padding: 0.5rem 1rem;
    margin-bottom: 0;
    background-color: #f8f8f8;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
    border-radius: calc(1.5rem - 1px) calc(1.5rem - 1px) 0 0;
}

.card-footer {
    padding: 0.5rem 1rem;
    background-color: #f8f8f8;
    border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
    border-radius: 0 0 calc(1.5rem - 1px) calc(1.5rem - 1px);
}

.card-header-tabs {
    margin-right: -0.5rem;
    margin-bottom: -0.5rem;
    margin-left: -0.5rem;
    border-bottom: 0;
}

.card-header-tabs .nav-link.active {
    background-color: #fffefe;
    border-bottom-color: #fffefe;
}

.card-header-pills {
    margin-right: -0.5rem;
    margin-left: -0.5rem;
}

.card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1rem;
    border-radius: calc(1.5rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
    width: 100%;
    height: 200px;
}

.card-img,
.card-img-top {
    border-top-left-radius: calc(1rem - 1px);
    border-top-right-radius: calc(1rem - 1px);
}

.card-img,
.card-img-bottom {
    border-bottom-right-radius: calc(1.5rem - 1px);
    border-bottom-left-radius: calc(1.5rem - 1px);
}

.card-group>.card {
    margin-bottom: 1rem;
}

@media (min-width: 576px) {
    .card-group {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
    }

    .card-group>.card {
        -webkit-box-flex: 1;
        -ms-flex: 1 0 0%;
        flex: 1 0 0%;
        margin-bottom: 0;
    }

    .card-group>.card+.card {
        margin-left: 0;
        border-left: 0;
    }

    .card-group>.card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .card-group>.card:not(:last-child) .card-img-top,
    .card-group>.card:not(:last-child) .card-header {
        border-top-right-radius: 0;
    }

    .card-group>.card:not(:last-child) .card-img-bottom,
    .card-group>.card:not(:last-child) .card-footer {
        border-bottom-right-radius: 0;
    }

    .card-group>.card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .card-group>.card:not(:first-child) .card-img-top,
    .card-group>.card:not(:first-child) .card-header {
        border-top-left-radius: 0;
    }

    .card-group>.card:not(:first-child) .card-img-bottom,
    .card-group>.card:not(:first-child) .card-footer {
        border-bottom-left-radius: 0;
    }
}

.breadcrumb {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0 0;
    margin-bottom: 1rem;
    list-style: none;
}

.breadcrumb-item+.breadcrumb-item {
    padding-left: 0.5rem;
}

.breadcrumb-item+.breadcrumb-item::before {
    float: left;
    padding-right: 0.5rem;
    color: #f0f4f9;
    content: var(--bs-breadcrumb-divider, "/")
        /* rtl: var(--bs-breadcrumb-divider, "/") */
    ;
}

.breadcrumb-item.active {
    color: #f0f4f9;
}

.pagination {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-left: 0;
    list-style: none;
}

.page-link {
    position: relative;
    display: block;
    color: #212832;
    background-color: #fffefe;
    border: 1px solid #eeeeee;
    -webkit-transition: color 0.15s ease-in-out,
        background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -o-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .page-link {
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
    }
}

.page-link:hover {
    z-index: 2;
    color: #f1a501;
    text-decoration: none;
    background-color: #f5f5f5;
    border-color: #eeeeee;
}

.page-link:focus {
    z-index: 3;
    color: #f1a501;
    background-color: #f5f5f5;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.25rem rgba(241, 165, 1, 0.25);
    box-shadow: 0 0 0 0.25rem rgba(241, 165, 1, 0.25);
}

.page-item:not(:first-child) .page-link {
    margin-left: -1px;
}

.page-item.active .page-link {
    z-index: 3;
    color: #fffefe;
    background-color: #f1a501;
    border-color: #f1a501;
}

.page-item.disabled .page-link {
    color: #f0f4f9;
    pointer-events: none;
    background-color: #fffefe;
    border-color: #eeeeee;
}

.page-link {
    padding: 0.375rem 0.75rem;
}

.page-item:first-child .page-link {
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
}

.page-item:last-child .page-link {
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
}

.pagination-lg .page-link {
    padding: 0.75rem 1.5rem;
    font-size: 1.2rem;
}

.pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: 2.25rem;
    border-bottom-left-radius: 2.25rem;
}

.pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: 2.25rem;
    border-bottom-right-radius: 2.25rem;
}

.pagination-sm .page-link {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
}

.pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: 0.625rem;
    border-bottom-left-radius: 0.625rem;
}

.pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem;
}

.badge {
    display: inline-block;
    padding: 0.35em 0.65em;
    font-size: 0.75em;
    font-weight: 700;
    line-height: 1;
    color: #fffefe;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 1.5rem;
}

.badge:empty {
    display: none;
}

.btn .badge {
    position: relative;
    top: -1px;
}

.alert {
    position: relative;
    padding: 1rem 1rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 1.5rem;
}

.alert-heading {
    color: inherit;
}

.alert-link {
    font-weight: 700;
}

.alert-dismissible {
    padding-right: 3rem;
}

.alert-dismissible .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 1.25rem 1rem;
}

.alert-primary {
    color: #916301;
    background-color: #fcedcc;
    border-color: #fbe4b3;
}

.alert-primary .alert-link {
    color: #744f01;
}

.alert-secondary {
    color: #383b4e;
    background-color: #dfe0e6;
    border-color: #cfd0da;
}

.alert-secondary .alert-link {
    color: #2d2f3e;
}

.alert-success {
    color: #496f24;
    background-color: #e4f1d8;
    border-color: #d7eac5;
}

.alert-success .alert-link {
    color: #3a591d;
}

.alert-info {
    color: #003b4d;
    background-color: #cce0e6;
    border-color: #b3d0d9;
}

.alert-info .alert-link {
    color: #002f3e;
}

.alert-warning {
    color: #916301;
    background-color: #fcedcc;
    border-color: #fbe4b3;
}

.alert-warning .alert-link {
    color: #744f01;
}

.alert-danger {
    color: #863f31;
    background-color: #f9e1dc;
    border-color: #f5d2cb;
}

.alert-danger .alert-link {
    color: #6b3227;
}

.alert-light {
    color: #909295;
    background-color: #fcfdfe;
    border-color: #fbfcfd;
}

.alert-light .alert-link {
    color: #737577;
}

.alert-dark {
    color: #14181e;
    background-color: #d3d4d6;
    border-color: #bcbfc2;
}

.alert-dark .alert-link {
    color: #101318;
}

@-webkit-keyframes progress-bar-stripes {
    0% {
        background-position-x: 1rem;
    }
}

@keyframes progress-bar-stripes {
    0% {
        background-position-x: 1rem;
    }
}

.progress {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 1rem;
    overflow: hidden;
    font-size: 0.75rem;
    background-color: #f5f5f5;
    border-radius: 1.875rem;
}

.progress-bar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    overflow: hidden;
    color: #fffefe;
    text-align: center;
    white-space: nowrap;
    background-color: #8a79df;
    -webkit-transition: width 0.6s ease;
    -o-transition: width 0.6s ease;
    transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
    .progress-bar {
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
    }
}

.progress-bar-striped {
    background-image: -o-linear-gradient(45deg,
            rgba(255, 254, 254, 0.15) 25%,
            transparent 25%,
            transparent 50%,
            rgba(255, 254, 254, 0.15) 50%,
            rgba(255, 254, 254, 0.15) 75%,
            transparent 75%,
            transparent);
    background-image: linear-gradient(45deg,
            rgba(255, 254, 254, 0.15) 25%,
            transparent 25%,
            transparent 50%,
            rgba(255, 254, 254, 0.15) 50%,
            rgba(255, 254, 254, 0.15) 75%,
            transparent 75%,
            transparent);
    background-size: 1rem 1rem;
}

.progress-bar-animated {
    -webkit-animation: 1s linear infinite progress-bar-stripes;
    animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
        -webkit-animation: none;
        animation: none;
    }
}

.list-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    border-radius: 1.5rem;
}

.list-group-numbered {
    list-style-type: none;
    counter-reset: section;
}

.list-group-numbered>li::before {
    content: counters(section, ".") ". ";
    counter-increment: section;
}

.list-group-item-action {
    width: 100%;
    color: #757575;
    text-align: inherit;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
    z-index: 1;
    color: #757575;
    text-decoration: none;
    background-color: #fcfcfc;
}

.list-group-item-action:active {
    color: #5e6282;
    background-color: #f5f5f5;
}

.list-group-item {
    position: relative;
    display: block;
    padding: 0.5rem 1rem;
    color: #5e6282;
    background-color: #fffefe;
    border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
}

.list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit;
}

.list-group-item.disabled,
.list-group-item:disabled {
    color: #f0f4f9;
    pointer-events: none;
    background-color: #fffefe;
}

.list-group-item.active {
    z-index: 2;
    color: #fffefe;
    background-color: #f1a501;
    border-color: #f1a501;
}

.list-group-item+.list-group-item {
    border-top-width: 0;
}

.list-group-item+.list-group-item.active {
    margin-top: -1px;
    border-top-width: 1px;
}

.list-group-horizontal {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
}

.list-group-horizontal>.list-group-item:first-child {
    border-bottom-left-radius: 1.5rem;
    border-top-right-radius: 0;
}

.list-group-horizontal>.list-group-item:last-child {
    border-top-right-radius: 1.5rem;
    border-bottom-left-radius: 0;
}

.list-group-horizontal>.list-group-item.active {
    margin-top: 0;
}

.list-group-horizontal>.list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
}

.list-group-horizontal>.list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
}

@media (min-width: 576px) {
    .list-group-horizontal-sm {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
    }

    .list-group-horizontal-sm>.list-group-item:first-child {
        border-bottom-left-radius: 1.5rem;
        border-top-right-radius: 0;
    }

    .list-group-horizontal-sm>.list-group-item:last-child {
        border-top-right-radius: 1.5rem;
        border-bottom-left-radius: 0;
    }

    .list-group-horizontal-sm>.list-group-item.active {
        margin-top: 0;
    }

    .list-group-horizontal-sm>.list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0;
    }

    .list-group-horizontal-sm>.list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px;
    }
}

@media (min-width: 768px) {
    .list-group-horizontal-md {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
    }

    .list-group-horizontal-md>.list-group-item:first-child {
        border-bottom-left-radius: 1.5rem;
        border-top-right-radius: 0;
    }

    .list-group-horizontal-md>.list-group-item:last-child {
        border-top-right-radius: 1.5rem;
        border-bottom-left-radius: 0;
    }

    .list-group-horizontal-md>.list-group-item.active {
        margin-top: 0;
    }

    .list-group-horizontal-md>.list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0;
    }

    .list-group-horizontal-md>.list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px;
    }
}

@media (min-width: 992px) {
    .list-group-horizontal-lg {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
    }

    .list-group-horizontal-lg>.list-group-item:first-child {
        border-bottom-left-radius: 1.5rem;
        border-top-right-radius: 0;
    }

    .list-group-horizontal-lg>.list-group-item:last-child {
        border-top-right-radius: 1.5rem;
        border-bottom-left-radius: 0;
    }

    .list-group-horizontal-lg>.list-group-item.active {
        margin-top: 0;
    }

    .list-group-horizontal-lg>.list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0;
    }

    .list-group-horizontal-lg>.list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px;
    }
}

@media (min-width: 1200px) {
    .list-group-horizontal-xl {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
    }

    .list-group-horizontal-xl>.list-group-item:first-child {
        border-bottom-left-radius: 1.5rem;
        border-top-right-radius: 0;
    }

    .list-group-horizontal-xl>.list-group-item:last-child {
        border-top-right-radius: 1.5rem;
        border-bottom-left-radius: 0;
    }

    .list-group-horizontal-xl>.list-group-item.active {
        margin-top: 0;
    }

    .list-group-horizontal-xl>.list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0;
    }

    .list-group-horizontal-xl>.list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px;
    }
}

@media (min-width: 1400px) {
    .list-group-horizontal-xxl {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
    }

    .list-group-horizontal-xxl>.list-group-item:first-child {
        border-bottom-left-radius: 1.5rem;
        border-top-right-radius: 0;
    }

    .list-group-horizontal-xxl>.list-group-item:last-child {
        border-top-right-radius: 1.5rem;
        border-bottom-left-radius: 0;
    }

    .list-group-horizontal-xxl>.list-group-item.active {
        margin-top: 0;
    }

    .list-group-horizontal-xxl>.list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0;
    }

    .list-group-horizontal-xxl>.list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px;
    }
}

.list-group-flush {
    border-radius: 0;
}

.list-group-flush>.list-group-item {
    border-width: 0 0 1px;
}

.list-group-flush>.list-group-item:last-child {
    border-bottom-width: 0;
}

.list-group-item-primary {
    color: #916301;
    background-color: #fcedcc;
}

.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
    color: #916301;
    background-color: #e3d5b8;
}

.list-group-item-primary.list-group-item-action.active {
    color: #fffefe;
    background-color: #916301;
    border-color: #916301;
}

.list-group-item-secondary {
    color: #383b4e;
    background-color: #dfe0e6;
}

.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
    color: #383b4e;
    background-color: #c9cacf;
}

.list-group-item-secondary.list-group-item-action.active {
    color: #fffefe;
    background-color: #383b4e;
    border-color: #383b4e;
}

.list-group-item-success {
    color: #496f24;
    background-color: #e4f1d8;
}

.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
    color: #496f24;
    background-color: #cdd9c2;
}

.list-group-item-success.list-group-item-action.active {
    color: #fffefe;
    background-color: #496f24;
    border-color: #496f24;
}

.list-group-item-info {
    color: #003b4d;
    background-color: #cce0e6;
}

.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
    color: #003b4d;
    background-color: #b8cacf;
}

.list-group-item-info.list-group-item-action.active {
    color: #fffefe;
    background-color: #003b4d;
    border-color: #003b4d;
}

.list-group-item-warning {
    color: #916301;
    background-color: #fcedcc;
}

.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
    color: #916301;
    background-color: #e3d5b8;
}

.list-group-item-warning.list-group-item-action.active {
    color: #fffefe;
    background-color: #916301;
    border-color: #916301;
}

.list-group-item-danger {
    color: #863f31;
    background-color: #f9e1dc;
}

.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
    color: #863f31;
    background-color: #e0cbc6;
}

.list-group-item-danger.list-group-item-action.active {
    color: #fffefe;
    background-color: #863f31;
    border-color: #863f31;
}

.list-group-item-light {
    color: #909295;
    background-color: #fcfdfe;
}

.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
    color: #909295;
    background-color: #e3e4e5;
}

.list-group-item-light.list-group-item-action.active {
    color: #fffefe;
    background-color: #909295;
    border-color: #909295;
}

.list-group-item-dark {
    color: #14181e;
    background-color: #d3d4d6;
}

.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
    color: #14181e;
    background-color: #bebfc1;
}

.list-group-item-dark.list-group-item-action.active {
    color: #fffefe;
    background-color: #14181e;
    border-color: #14181e;
}

.btn-close {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: 0.25em 0.25em;
    color: #000;
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
    border: 0;
    border-radius: 1.5rem;
    opacity: 0.5;
}

.btn-close:hover {
    color: #000;
    text-decoration: none;
    opacity: 0.75;
}

.btn-close:focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.25rem rgba(241, 165, 1, 0.25);
    box-shadow: 0 0 0 0.25rem rgba(241, 165, 1, 0.25);
    opacity: 1;
}

.btn-close:disabled,
.btn-close.disabled {
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    opacity: 0.25;
}

.btn-close-white {
    -webkit-filter: invert(1) grayscale(100%) brightness(200%);
    filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
    width: 350px;
    max-width: 100%;
    font-size: 0.875rem;
    pointer-events: auto;
    background-color: rgba(255, 254, 254, 0.85);
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 6.25rem 5rem 0 rgba(0, 0, 0, 0.02),
        0 4.05063rem 2.92813rem 0 rgba(0, 0, 0, 0.0152),
        0 2.4075rem 1.5925rem 0 rgba(0, 0, 0, 0.0121),
        0 1.25rem 0.8125rem 0 rgba(0, 0, 0, 0.01),
        0 0.50938rem 0.4075rem 0 rgba(0, 0, 0, 0.0079),
        0 0.11563rem 0.19687rem 0 rgba(0, 0, 0, 0.0048);
    box-shadow: 0 6.25rem 5rem 0 rgba(0, 0, 0, 0.02),
        0 4.05063rem 2.92813rem 0 rgba(0, 0, 0, 0.0152),
        0 2.4075rem 1.5925rem 0 rgba(0, 0, 0, 0.0121),
        0 1.25rem 0.8125rem 0 rgba(0, 0, 0, 0.01),
        0 0.50938rem 0.4075rem 0 rgba(0, 0, 0, 0.0079),
        0 0.11563rem 0.19687rem 0 rgba(0, 0, 0, 0.0048);
    border-radius: 1.5rem;
}

.toast:not(.showing):not(.show) {
    opacity: 0;
}

.toast.hide {
    display: none;
}

.toast-container {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    max-width: 100%;
    pointer-events: none;
}

.toast-container> :not(:last-child) {
    margin-bottom: 1rem;
}

.toast-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0.5rem 0.75rem;
    color: #f0f4f9;
    background-color: rgba(255, 254, 254, 0.85);
    background-clip: padding-box;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    border-top-left-radius: calc(1.5rem - 1px);
    border-top-right-radius: calc(1.5rem - 1px);
}

.toast-header .btn-close {
    margin-right: -0.375rem;
    margin-left: 0.75rem;
}

.toast-body {
    padding: 0.75rem;
    word-wrap: break-word;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1060;
    display: none;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none;
}

.modal.fade .modal-dialog {
    -webkit-transition: -webkit-transform 0.3s ease-out;
    transition: -webkit-transform 0.3s ease-out;
    -o-transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -50px);
    -ms-transform: translate(0, -50px);
    transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
    .modal.fade .modal-dialog {
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
    }
}

.modal.show .modal-dialog {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
}

.modal.modal-static .modal-dialog {
    -webkit-transform: scale(1.02);
    -ms-transform: scale(1.02);
    transform: scale(1.02);
}

.modal-dialog-scrollable {
    height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
    max-height: 100%;
    overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
    overflow-y: auto;
}

.modal-dialog-centered {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: calc(100% - 1rem);
}

.modal-content {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fffefe;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 2.25rem;
    outline: 0;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000;
}

.modal-backdrop.fade {
    opacity: 0;
}

.modal-backdrop.show {
    opacity: 0.5;
}

.modal-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 1px solid #eeeeee;
    border-top-left-radius: calc(2.25rem - 1px);
    border-top-right-radius: calc(2.25rem - 1px);
}

.modal-header .btn-close {
    padding: 0.5rem 0.5rem;
    margin: -0.5rem -0.5rem -0.5rem auto;
}

.modal-title {
    margin-bottom: 0;
    line-height: 1.5;
}

.modal-body {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1rem;
}

.modal-footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: 0.75rem;
    border-top: 1px solid #eeeeee;
    border-bottom-right-radius: calc(2.25rem - 1px);
    border-bottom-left-radius: calc(2.25rem - 1px);
}

.modal-footer>* {
    margin: 0.25rem;
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 500px;
        margin: 1.75rem auto;
    }

    .modal-dialog-scrollable {
        height: calc(100% - 3.5rem);
    }

    .modal-dialog-centered {
        min-height: calc(100% - 3.5rem);
    }

    .modal-sm {
        max-width: 300px;
    }
}

@media (min-width: 992px) {

    .modal-lg,
    .modal-xl {
        max-width: 800px;
    }
}

@media (min-width: 1200px) {
    .modal-xl {
        max-width: 1140px;
    }
}

.modal-fullscreen {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
}

.modal-fullscreen .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
}

.modal-fullscreen .modal-header {
    border-radius: 0;
}

.modal-fullscreen .modal-body {
    overflow-y: auto;
}

.modal-fullscreen .modal-footer {
    border-radius: 0;
}

@media (max-width: 575.98px) {
    .modal-fullscreen-sm-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0;
    }

    .modal-fullscreen-sm-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0;
    }

    .modal-fullscreen-sm-down .modal-header {
        border-radius: 0;
    }

    .modal-fullscreen-sm-down .modal-body {
        overflow-y: auto;
    }

    .modal-fullscreen-sm-down .modal-footer {
        border-radius: 0;
    }
}

@media (max-width: 767.98px) {
    .modal-fullscreen-md-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0;
    }

    .modal-fullscreen-md-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0;
    }

    .modal-fullscreen-md-down .modal-header {
        border-radius: 0;
    }

    .modal-fullscreen-md-down .modal-body {
        overflow-y: auto;
    }

    .modal-fullscreen-md-down .modal-footer {
        border-radius: 0;
    }
}

@media (max-width: 991.98px) {
    .modal-fullscreen-lg-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0;
    }

    .modal-fullscreen-lg-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0;
    }

    .modal-fullscreen-lg-down .modal-header {
        border-radius: 0;
    }

    .modal-fullscreen-lg-down .modal-body {
        overflow-y: auto;
    }

    .modal-fullscreen-lg-down .modal-footer {
        border-radius: 0;
    }
}

@media (max-width: 1199.98px) {
    .modal-fullscreen-xl-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0;
    }

    .modal-fullscreen-xl-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0;
    }

    .modal-fullscreen-xl-down .modal-header {
        border-radius: 0;
    }

    .modal-fullscreen-xl-down .modal-body {
        overflow-y: auto;
    }

    .modal-fullscreen-xl-down .modal-footer {
        border-radius: 0;
    }
}

@media (max-width: 1399.98px) {
    .modal-fullscreen-xxl-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0;
    }

    .modal-fullscreen-xxl-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0;
    }

    .modal-fullscreen-xxl-down .modal-header {
        border-radius: 0;
    }

    .modal-fullscreen-xxl-down .modal-body {
        overflow-y: auto;
    }

    .modal-fullscreen-xxl-down .modal-footer {
        border-radius: 0;
    }
}

.tooltip {
    position: absolute;
    z-index: 1080;
    display: block;
    margin: 0;
    font-family: "Poppins", "Rubik", -apple-system, BlinkMacSystemFont, "Segoe UI",
        "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
        "Segoe UI Symbol";
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.875rem;
    word-wrap: break-word;
    opacity: 0;
}

.tooltip.show {
    opacity: 0.9;
}

.tooltip .tooltip-arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem;
}

.tooltip .tooltip-arrow::before {
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid;
}

.bs-tooltip-top,
.bs-tooltip-auto[data-popper-placement^="top"] {
    padding: 0.4rem 0;
}

.bs-tooltip-top .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
    bottom: 0;
}

.bs-tooltip-top .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
    top: -1px;
    border-width: 0.4rem 0.4rem 0;
    border-top-color: #000;
}

.bs-tooltip-end,
.bs-tooltip-auto[data-popper-placement^="right"] {
    padding: 0 0.4rem;
}

.bs-tooltip-end .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem;
}

.bs-tooltip-end .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
    right: -1px;
    border-width: 0.4rem 0.4rem 0.4rem 0;
    border-right-color: #000;
}

.bs-tooltip-bottom,
.bs-tooltip-auto[data-popper-placement^="bottom"] {
    padding: 0.4rem 0;
}

.bs-tooltip-bottom .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
    top: 0;
}

.bs-tooltip-bottom .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
    bottom: -1px;
    border-width: 0 0.4rem 0.4rem;
    border-bottom-color: #000;
}

.bs-tooltip-start,
.bs-tooltip-auto[data-popper-placement^="left"] {
    padding: 0 0.4rem;
}

.bs-tooltip-start .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem;
}

.bs-tooltip-start .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
    left: -1px;
    border-width: 0.4rem 0 0.4rem 0.4rem;
    border-left-color: #000;
}

.tooltip-inner {
    max-width: 200px;
    padding: 0.25rem 0.5rem;
    color: #fffefe;
    text-align: center;
    background-color: #000;
    border-radius: 1.5rem;
}

.popover {
    position: absolute;
    top: 0;
    left: 0
        /* rtl:ignore */
    ;
    z-index: 1070;
    display: block;
    max-width: 276px;
    font-family: "Poppins", "Rubik", -apple-system, BlinkMacSystemFont, "Segoe UI",
        "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
        "Segoe UI Symbol";
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.875rem;
    word-wrap: break-word;
    background-color: #fffefe;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 2.25rem;
}

.popover .popover-arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
}

.popover .popover-arrow::before,
.popover .popover-arrow::after {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
}

.bs-popover-top>.popover-arrow,
.bs-popover-auto[data-popper-placement^="top"]>.popover-arrow {
    bottom: calc(-0.5rem - 1px);
}

.bs-popover-top>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^="top"]>.popover-arrow::before {
    bottom: 0;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^="top"]>.popover-arrow::after {
    bottom: 1px;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: #fffefe;
}

.bs-popover-end>.popover-arrow,
.bs-popover-auto[data-popper-placement^="right"]>.popover-arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
}

.bs-popover-end>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^="right"]>.popover-arrow::before {
    left: 0;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-end>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^="right"]>.popover-arrow::after {
    left: 1px;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: #fffefe;
}

.bs-popover-bottom>.popover-arrow,
.bs-popover-auto[data-popper-placement^="bottom"]>.popover-arrow {
    top: calc(-0.5rem - 1px);
}

.bs-popover-bottom>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^="bottom"]>.popover-arrow::before {
    top: 0;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^="bottom"]>.popover-arrow::after {
    top: 1px;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: #fffefe;
}

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f0efef;
}

.bs-popover-start>.popover-arrow,
.bs-popover-auto[data-popper-placement^="left"]>.popover-arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
}

.bs-popover-start>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^="left"]>.popover-arrow::before {
    right: 0;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-start>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^="left"]>.popover-arrow::after {
    right: 1px;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: #fffefe;
}

.popover-header {
    padding: 0.5rem 1rem;
    margin-bottom: 0;
    font-size: 1rem;
    color: #14183e;
    background-color: #f0efef;
    border-bottom: 1px solid #d8d7d7;
    border-top-left-radius: calc(2.25rem - 1px);
    border-top-right-radius: calc(2.25rem - 1px);
}

.popover-header:empty {
    display: none;
}

.popover-body {
    padding: 1rem 1rem;
    color: #5e6282;
}

.carousel {
    position: relative;
}

.carousel.pointer-event {
    -ms-touch-action: pan-y;
    touch-action: pan-y;
}

.carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.carousel-inner::after {
    display: block;
    clear: both;
    content: "";
}

.carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: -webkit-transform 0.6s ease-in-out;
    transition: -webkit-transform 0.6s ease-in-out;
    -o-transition: transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .carousel-item {
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
    }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
    display: block;
}

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
    -webkit-transform: translateX(100%);
    -ms-transform: translateX(100%);
    transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
}

/* rtl:end:ignore */
.carousel-fade .carousel-item {
    opacity: 0;
    -webkit-transition-property: opacity;
    -o-transition-property: opacity;
    transition-property: opacity;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
    z-index: 1;
    opacity: 1;
}

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
    z-index: 0;
    opacity: 0;
    -webkit-transition: opacity 0s 0.6s;
    -o-transition: opacity 0s 0.6s;
    transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {

    .carousel-fade .active.carousel-item-start,
    .carousel-fade .active.carousel-item-end {
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
    }
}

.carousel-control-prev,
.carousel-control-next {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 15%;
    padding: 0;
    color: #f1a501;
    text-align: center;
    background: none;
    border: 0;
    opacity: 0.9;
    -webkit-transition: opacity 0.15s ease;
    -o-transition: opacity 0.15s ease;
    transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {

    .carousel-control-prev,
    .carousel-control-next {
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
    }
}

.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
    color: #f1a501;
    text-decoration: none;
    outline: 0;
    opacity: 0.9;
}

.carousel-control-prev {
    left: 0;
}

.carousel-control-next {
    right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    display: inline-block;
    width: 1.8rem;
    height: 1.8rem;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%;
}

/* rtl:options: {
    "autoRename": true,
    "stringMap":[ {
      "name"    : "prev-next",
      "search"  : "prev",
      "replace" : "next"
    } ]
  } */
.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23F1A501' class='bi bi-arrow-left' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23F1A501' class='bi bi-arrow-right' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z'/%3E%3C/svg%3E");
}

.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 0;
    margin-right: 15%;
    margin-bottom: 1rem;
    margin-left: 15%;
    list-style: none;
}

.carousel-indicators [data-bs-target] {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: 6px;
    height: 6px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #f1a501;
    background-clip: padding-box;
    border: 0;
    border-top: 0 solid transparent;
    border-bottom: 0 solid transparent;
    opacity: 0.5;
    -webkit-transition: opacity 0.6s ease;
    -o-transition: opacity 0.6s ease;
    transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
    .carousel-indicators [data-bs-target] {
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
    }
}

.carousel-indicators .active {
    opacity: 1;
}

.carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 1.25rem;
    left: 15%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fffefe;
    text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
    -webkit-filter: invert(1) grayscale(100);
    filter: invert(1) grayscale(100);
}

.carousel-dark .carousel-indicators [data-bs-target] {
    background-color: #000;
}

.carousel-dark .carousel-caption {
    color: #000;
}

@-webkit-keyframes spinner-border {
    to {
        -webkit-transform: rotate(360deg)
            /* rtl:ignore */
        ;
        transform: rotate(360deg)
            /* rtl:ignore */
        ;
    }
}

@keyframes spinner-border {
    to {
        -webkit-transform: rotate(360deg)
            /* rtl:ignore */
        ;
        transform: rotate(360deg)
            /* rtl:ignore */
        ;
    }
}

.spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: -0.125em;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: 0.75s linear infinite spinner-border;
    animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: 0.2em;
}

@-webkit-keyframes spinner-grow {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    50% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes spinner-grow {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    50% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

.spinner-grow {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: -0.125em;
    background-color: currentColor;
    border-radius: 50%;
    opacity: 0;
    -webkit-animation: 0.75s linear infinite spinner-grow;
    animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
    width: 1rem;
    height: 1rem;
}

@media (prefers-reduced-motion: reduce) {

    .spinner-border,
    .spinner-grow {
        -webkit-animation-duration: 1.5s;
        animation-duration: 1.5s;
    }
}

.accordion-button {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    padding: 1.5rem 2.6rem;
    font-size: 1rem;
    color: #5e6282;
    text-align: left;
    background-color: #fcfcfc;
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    -webkit-transition: color 0.15s ease-in-out,
        background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        border-radius 0.15s ease, -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, border-radius 0.15s ease,
        -webkit-box-shadow 0.15s ease-in-out;
    -o-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
        border-radius 0.15s ease;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
        border-radius 0.15s ease;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
        border-radius 0.15s ease, -webkit-box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .accordion-button {
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
    }
}

.accordion-button:not(.collapsed) {
    color: #212832;
    background-color: #fcfcfc;
    -webkit-box-shadow: inset 0 -1px 0 rgba(255, 254, 254, 0.125);
    box-shadow: inset 0 -1px 0 rgba(255, 254, 254, 0.125);
}

.accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23EE4D47' class='bi bi-dash' viewBox='0 0 16 16'%3E%3Cpath d='M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z'/%3E%3C/svg%3E");
    -webkit-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    transform: rotate(-180deg);
}

.accordion-button::after {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 1.8rem;
    height: 1.8rem;
    margin-left: auto;
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23EE4D47' class='bi bi-plus' viewBox='0 0 16 16'%3E%3Cpath d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 1.8rem;
    -webkit-transition: -webkit-transform 0.2s ease-in-out;
    transition: -webkit-transform 0.2s ease-in-out;
    -o-transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .accordion-button::after {
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
    }
}

.accordion-button:hover {
    z-index: 2;
}

.accordion-button:focus {
    z-index: 3;
    border-color: #fffefe;
    outline: 0;
    -webkit-box-shadow: #f0f4f9;
    box-shadow: #f0f4f9;
}

.accordion-header {
    margin-bottom: 0;
}

.accordion-item {
    background-color: #fcfcfc;
    border: 1px solid rgba(255, 254, 254, 0.125);
}

.accordion-item:first-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.accordion-item:first-of-type .accordion-button {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.accordion-item:not(:first-of-type) {
    border-top: 0;
}

.accordion-item:last-of-type {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.accordion-item:last-of-type .accordion-collapse {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.accordion-body {
    padding: 1.5rem 2.6rem;
}

.accordion-flush .accordion-collapse {
    border-width: 0;
}

.accordion-flush .accordion-item {
    border-right: 0;
    border-left: 0;
    border-radius: 0;
}

.accordion-flush .accordion-item:first-child {
    border-top: 0;
}

.accordion-flush .accordion-item:last-child {
    border-bottom: 0;
}

.accordion-flush .accordion-item .accordion-button {
    border-radius: 0;
}

.clearfix::after {
    display: block;
    clear: both;
    content: "";
}

.link-primary {
    color: #f1a501;
}

.link-primary:hover,
.link-primary:focus {
    color: #c18401;
}

.link-secondary {
    color: #5e6282;
}

.link-secondary:hover,
.link-secondary:focus {
    color: #4b4e68;
}

.link-success {
    color: #79b93c;
}

.link-success:hover,
.link-success:focus {
    color: #619430;
}

.link-info {
    color: #006380;
}

.link-info:hover,
.link-info:focus {
    color: #004f66;
}

.link-warning {
    color: #f1a501;
}

.link-warning:hover,
.link-warning:focus {
    color: #c18401;
}

.link-danger {
    color: #df6951;
}

.link-danger:hover,
.link-danger:focus {
    color: #b25441;
}

.link-light {
    color: #f0f4f9;
}

.link-light:hover,
.link-light:focus {
    color: #f3f6fa;
}

.link-dark {
    color: #212832;
}

.link-dark:hover,
.link-dark:focus {
    color: #1a2028;
}

.ratio {
    position: relative;
    width: 100%;
}

.ratio::before {
    display: block;
    padding-top: var(--bs-aspect-ratio);
    content: "";
}

.ratio>* {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.ratio-1x1 {
    --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
    --bs-aspect-ratio: calc(3 / 4 * 100%);
}

.ratio-16x9 {
    --bs-aspect-ratio: calc(9 / 16 * 100%);
}

.ratio-21x9 {
    --bs-aspect-ratio: calc(9 / 21 * 100%);
}

.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
}

.fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
}

.sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
}

@media (min-width: 576px) {
    .sticky-sm-top {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1020;
    }
}

@media (min-width: 768px) {
    .sticky-md-top {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1020;
    }
}

@media (min-width: 992px) {
    .sticky-lg-top {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1020;
    }
}

@media (min-width: 1200px) {
    .sticky-xl-top {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1020;
    }
}

@media (min-width: 1400px) {
    .sticky-xxl-top {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1020;
    }
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;
}

.stretched-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    content: "";
}

.text-truncate {
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.align-baseline {
    vertical-align: baseline !important;
}

.align-top {
    vertical-align: top !important;
}

.align-middle {
    vertical-align: middle !important;
}

.align-bottom {
    vertical-align: bottom !important;
}

.align-text-bottom {
    vertical-align: text-bottom !important;
}

.align-text-top {
    vertical-align: text-top !important;
}

.float-start {
    float: left !important;
}

.float-end {
    float: right !important;
}

.float-none {
    float: none !important;
}

.overflow-unset {
    overflow: unset !important;
}

.overflow-auto {
    overflow: auto !important;
}

.overflow-hidden {
    overflow: hidden !important;
}

.overflow-visible {
    overflow: visible !important;
}

.overflow-scroll {
    overflow: scroll !important;
}

.d-inline {
    display: inline !important;
}

.d-inline-block {
    display: inline-block !important;
}

.d-block {
    display: block !important;
}

.d-grid {
    display: grid !important;
}

.d-table {
    display: table !important;
}

.d-table-row {
    display: table-row !important;
}

.d-table-cell {
    display: table-cell !important;
}

.d-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
}

.d-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
}

.d-none {
    display: none !important;
}

.shadow {
    -webkit-box-shadow: 0 6.25rem 5rem 0 rgba(0, 0, 0, 0.02),
        0 4.05063rem 2.92813rem 0 rgba(0, 0, 0, 0.0152),
        0 2.4075rem 1.5925rem 0 rgba(0, 0, 0, 0.0121),
        0 1.25rem 0.8125rem 0 rgba(0, 0, 0, 0.01),
        0 0.50938rem 0.4075rem 0 rgba(0, 0, 0, 0.0079),
        0 0.11563rem 0.19687rem 0 rgba(0, 0, 0, 0.0048) !important;
    box-shadow: 0 6.25rem 5rem 0 rgba(0, 0, 0, 0.02),
        0 4.05063rem 2.92813rem 0 rgba(0, 0, 0, 0.0152),
        0 2.4075rem 1.5925rem 0 rgba(0, 0, 0, 0.0121),
        0 1.25rem 0.8125rem 0 rgba(0, 0, 0, 0.01),
        0 0.50938rem 0.4075rem 0 rgba(0, 0, 0, 0.0079),
        0 0.11563rem 0.19687rem 0 rgba(0, 0, 0, 0.0048) !important;
}

.shadow-sm {
    -webkit-box-shadow: 0 0.3rem 1rem rgba(0, 0, 0, 0.09) !important;
    box-shadow: 0 0.3rem 1rem rgba(0, 0, 0, 0.09) !important;
}

.shadow-lg {
    -webkit-box-shadow: 0 1rem 4rem rgba(0, 0, 0, 0.175) !important;
    box-shadow: 0 1rem 4rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.position-static {
    position: static !important;
}

.position-absolute {
    position: absolute !important;
}

.position-relative {
    position: relative !important;
}

.position-fixed {
    position: fixed !important;
}

.position-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
}

.top-unset {
    top: unset !important;
}

.top-0 {
    top: 0 !important;
}

.top-50 {
    top: 50% !important;
}

.top-100 {
    top: 100% !important;
}

.bottom-unset {
    bottom: unset !important;
}

.bottom-0 {
    bottom: 0 !important;
}

.bottom-50 {
    bottom: 50% !important;
}

.bottom-100 {
    bottom: 100% !important;
}

.start-unset {
    left: unset !important;
}

.start-0 {
    left: 0 !important;
}

.start-50 {
    left: 50% !important;
}

.start-100 {
    left: 100% !important;
}

.end-unset {
    right: unset !important;
}

.end-0 {
    right: 0 !important;
}

.end-50 {
    right: 50% !important;
}

.end-100 {
    right: 100% !important;
}

.translate-middle {
    -webkit-transform: translateX(-50%) translateY(-50%) !important;
    -ms-transform: translateX(-50%) translateY(-50%) !important;
    transform: translateX(-50%) translateY(-50%) !important;
}

.translate-middle-x {
    -webkit-transform: translateX(-50%) !important;
    -ms-transform: translateX(-50%) !important;
    transform: translateX(-50%) !important;
}

.translate-middle-y {
    -webkit-transform: translateY(-50%) !important;
    -ms-transform: translateY(-50%) !important;
    transform: translateY(-50%) !important;
}

.border {
    border: 1px solid #eeeeee !important;
}

.border-0 {
    border: 0 !important;
}

.border-top {
    border-top: 1px solid #eeeeee !important;
}

.border-top-0 {
    border-top: 0 !important;
}

.border-end {
    border-right: 1px solid #eeeeee !important;
}

.border-end-0 {
    border-right: 0 !important;
}

.border-bottom {
    border-bottom: 1px solid #eeeeee !important;
}

.border-bottom-0 {
    border-bottom: 0 !important;
}

.border-start {
    border-left: 1px solid #eeeeee !important;
}

.border-start-0 {
    border-left: 0 !important;
}

.border-facebook {
    border-color: #3c5a99 !important;
}

.border-google-plus {
    border-color: #dd4b39 !important;
}

.border-twitter {
    border-color: #1da1f2 !important;
}

.border-whatsapp {
    border-color: #1da1f2 !important;
}

.border-linkedin {
    border-color: #0077b5 !important;
}

.border-youtube {
    border-color: #ff0000 !important;
}

.border-github {
    border-color: #333 !important;
}

.border-black {
    border-color: #000 !important;
}

.border-100 {
    border-color: #fcfcfc !important;
}

.border-200 {
    border-color: #f5f5f5 !important;
}

.border-300 {
    border-color: #eeeeee !important;
}

.border-400 {
    border-color: #e0e0e0 !important;
}

.border-500 {
    border-color: #bdbdbd !important;
}

.border-600 {
    border-color: #f0f4f9 !important;
}

.border-700 {
    border-color: #757575 !important;
}

.border-800 {
    border-color: #616161 !important;
}

.border-900 {
    border-color: #5e6282 !important;
}

.border-1000 {
    border-color: #212832 !important;
}

.border-white {
    border-color: #fffefe !important;
}

.border-primary {
    border-color: #f1a501 !important;
}

.border-secondary {
    border-color: #5e6282 !important;
}

.border-success {
    border-color: #79b93c !important;
}

.border-info {
    border-color: #006380 !important;
}

.border-warning {
    border-color: #f1a501 !important;
}

.border-danger {
    border-color: #df6951 !important;
}

.border-light {
    border-color: #f0f4f9 !important;
}

.border-dark {
    border-color: #212832 !important;
}

.border-1 {
    border-width: 1px !important;
}

.border-2 {
    border-width: 2px !important;
}

.border-3 {
    border-width: 3px !important;
}

.border-4 {
    border-width: 4px !important;
}

.border-5 {
    border-width: 5px !important;
}

.w-25 {
    width: 25% !important;
}

.w-50 {
    width: 50% !important;
}

.w-75 {
    width: 75% !important;
}

.w-100 {
    width: 100% !important;
}

.w-auto {
    width: auto !important;
}

.mw-100 {
    max-width: 100% !important;
}

.vw-25 {
    width: 25vw !important;
}

.vw-50 {
    width: 50vw !important;
}

.vw-75 {
    width: 75vw !important;
}

.vw-100 {
    width: 100vw !important;
}

.min-vw-100 {
    min-width: 100vw !important;
}

.h-25 {
    height: 25% !important;
}

.h-50 {
    height: 50% !important;
}

.h-75 {
    height: 75% !important;
}

.h-100 {
    height: 100% !important;
}

.h-auto {
    height: auto !important;
}

.mh-100 {
    max-height: 100% !important;
}

.vh-25 {
    height: 25vh !important;
}

.vh-50 {
    height: 50vh !important;
}

.vh-75 {
    height: 75vh !important;
}

.vh-100 {
    height: 100vh !important;
}

.min-vh-25 {
    min-height: 25vh !important;
}

.min-vh-50 {
    min-height: 50vh !important;
}

.min-vh-75 {
    min-height: 75vh !important;
}

.min-vh-100 {
    min-height: 100vh !important;
}

.flex-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
}

.flex-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
}

.flex-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
}

.flex-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
}

.flex-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
}

.flex-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
}

.flex-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
}

.flex-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
}

.flex-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
}

.flex-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
}

.flex-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
}

.gap-0 {
    gap: 0 !important;
}

.gap-1 {
    gap: 0.25rem !important;
}

.gap-2 {
    gap: 0.5rem !important;
}

.gap-3 {
    gap: 1rem !important;
}

.gap-4 {
    gap: 1.8rem !important;
}

.gap-5 {
    gap: 2.5rem !important;
}

.gap-6 {
    gap: 3rem !important;
}

.gap-7 {
    gap: 4rem !important;
}

.gap-8 {
    gap: 5rem !important;
}

.gap-9 {
    gap: 7.5rem !important;
}

.gap-10 {
    gap: 10rem !important;
}

.gap-11 {
    gap: 12.5rem !important;
}

.gap-12 {
    gap: 15rem !important;
}

.justify-content-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
}

.justify-content-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
}

.justify-content-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
}

.justify-content-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
}

.justify-content-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
}

.justify-content-evenly {
    -webkit-box-pack: space-evenly !important;
    -ms-flex-pack: space-evenly !important;
    justify-content: space-evenly !important;
}

.align-items-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
}

.align-items-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
}

.align-items-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
}

.align-items-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
}

.align-items-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
}

.align-content-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
}

.align-content-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
}

.align-content-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
}

.align-content-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
}

.align-content-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
}

.align-content-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
}

.align-self-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
}

.align-self-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
}

.align-self-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
}

.align-self-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
}

.align-self-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
}

.align-self-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
}

.order-first {
    -webkit-box-ordinal-group: 0 !important;
    -ms-flex-order: -1 !important;
    order: -1 !important;
}

.order-0 {
    -webkit-box-ordinal-group: 1 !important;
    -ms-flex-order: 0 !important;
    order: 0 !important;
}

.order-1 {
    -webkit-box-ordinal-group: 2 !important;
    -ms-flex-order: 1 !important;
    order: 1 !important;
}

.order-2 {
    -webkit-box-ordinal-group: 3 !important;
    -ms-flex-order: 2 !important;
    order: 2 !important;
}

.order-3 {
    -webkit-box-ordinal-group: 4 !important;
    -ms-flex-order: 3 !important;
    order: 3 !important;
}

.order-4 {
    -webkit-box-ordinal-group: 5 !important;
    -ms-flex-order: 4 !important;
    order: 4 !important;
}

.order-5 {
    -webkit-box-ordinal-group: 6 !important;
    -ms-flex-order: 5 !important;
    order: 5 !important;
}

.order-last {
    -webkit-box-ordinal-group: 7 !important;
    -ms-flex-order: 6 !important;
    order: 6 !important;
}

.m-0 {
    margin: 0 !important;
}

.m-1 {
    margin: 0.25rem !important;
}

.m-2 {
    margin: 0.5rem !important;
}

.m-3 {
    margin: 1rem !important;
}

.m-4 {
    margin: 1.8rem !important;
}

.m-5 {
    margin: 2.5rem !important;
}

.m-6 {
    margin: 3rem !important;
}

.m-7 {
    margin: 4rem !important;
}

.m-8 {
    margin: 5rem !important;
}

.m-9 {
    margin: 7.5rem !important;
}

.m-10 {
    margin: 10rem !important;
}

.m-11 {
    margin: 12.5rem !important;
}

.m-12 {
    margin: 15rem !important;
}

.m-auto {
    margin: auto !important;
}

.mx-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
}

.mx-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
}

.mx-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
}

.mx-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
}

.mx-4 {
    margin-right: 1.8rem !important;
    margin-left: 1.8rem !important;
}

.mx-5 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
}

.mx-6 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
}

.mx-7 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
}

.mx-8 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
}

.mx-9 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important;
}

.mx-10 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
}

.mx-11 {
    margin-right: 12.5rem !important;
    margin-left: 12.5rem !important;
}

.mx-12 {
    margin-right: 15rem !important;
    margin-left: 15rem !important;
}

.mx-auto {
    margin-right: auto !important;
    margin-left: auto !important;
}

.my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
}

.my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
}

.my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
}

.my-4 {
    margin-top: 1.8rem !important;
    margin-bottom: 1.8rem !important;
}

.my-5 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
}

.my-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
}

.my-7 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
}

.my-8 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
}

.my-9 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
}

.my-10 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
}

.my-11 {
    margin-top: 12.5rem !important;
    margin-bottom: 12.5rem !important;
}

.my-12 {
    margin-top: 15rem !important;
    margin-bottom: 15rem !important;
}

.my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
}

.mt-0 {
    margin-top: 0 !important;
}

.mt-1 {
    margin-top: 0.25rem !important;
}

.mt-2 {
    margin-top: 0.5rem !important;
}

.mt-3 {
    margin-top: 1rem !important;
}

.mt-4 {
    margin-top: 1.8rem !important;
}

.mt-5 {
    margin-top: 2.5rem !important;
}

.mt-6 {
    margin-top: 3rem !important;
}

.mt-7 {
    margin-top: 4rem !important;
}

.mt-8 {
    margin-top: 5rem !important;
}

.mt-9 {
    margin-top: 7.5rem !important;
}

.mt-10 {
    margin-top: 10rem !important;
}

.mt-11 {
    margin-top: 12.5rem !important;
}

.mt-12 {
    margin-top: 15rem !important;
}

.mt-auto {
    margin-top: auto !important;
}

.me-0 {
    margin-right: 0 !important;
}

.me-1 {
    margin-right: 0.25rem !important;
}

.me-2 {
    margin-right: 0.5rem !important;
}

.me-3 {
    margin-right: 1rem !important;
}

.me-4 {
    margin-right: 1.8rem !important;
}

.me-5 {
    margin-right: 2.5rem !important;
}

.me-6 {
    margin-right: 3rem !important;
}

.me-7 {
    margin-right: 4rem !important;
}

.me-8 {
    margin-right: 5rem !important;
}

.me-9 {
    margin-right: 7.5rem !important;
}

.me-10 {
    margin-right: 10rem !important;
}

.me-11 {
    margin-right: 12.5rem !important;
}

.me-12 {
    margin-right: 15rem !important;
}

.me-auto {
    margin-right: auto !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.mb-1 {
    margin-bottom: 0.25rem !important;
}

.mb-2 {
    margin-bottom: 0.5rem !important;
}

.mb-3 {
    margin-bottom: 1rem !important;
}

.mb-4 {
    margin-bottom: 1.8rem !important;
}

.mb-5 {
    margin-bottom: 2.5rem !important;
}

.mb-6 {
    margin-bottom: 3rem !important;
}

.mb-7 {
    margin-bottom: 4rem !important;
}

.mb-8 {
    margin-bottom: 5rem !important;
}

.mb-9 {
    margin-bottom: 7.5rem !important;
}

.mb-10 {
    margin-bottom: 10rem !important;
}

.mb-11 {
    margin-bottom: 12.5rem !important;
}

.mb-12 {
    margin-bottom: 15rem !important;
}

.mb-auto {
    margin-bottom: auto !important;
}

.ms-0 {
    margin-left: 0 !important;
}

.ms-1 {
    margin-left: 0.25rem !important;
}

.ms-2 {
    margin-left: 0.5rem !important;
}

.ms-3 {
    margin-left: 1rem !important;
}

.ms-4 {
    margin-left: 1.8rem !important;
}

.ms-5 {
    margin-left: 2.5rem !important;
}

.ms-6 {
    margin-left: 3rem !important;
}

.ms-7 {
    margin-left: 4rem !important;
}

.ms-8 {
    margin-left: 5rem !important;
}

.ms-9 {
    margin-left: 7.5rem !important;
}

.ms-10 {
    margin-left: 10rem !important;
}

.ms-11 {
    margin-left: 12.5rem !important;
}

.ms-12 {
    margin-left: 15rem !important;
}

.ms-auto {
    margin-left: auto !important;
}

.m-n1 {
    margin: -0.25rem !important;
}

.m-n2 {
    margin: -0.5rem !important;
}

.m-n3 {
    margin: -1rem !important;
}

.m-n4 {
    margin: -1.8rem !important;
}

.m-n5 {
    margin: -2.5rem !important;
}

.m-n6 {
    margin: -3rem !important;
}

.m-n7 {
    margin: -4rem !important;
}

.m-n8 {
    margin: -5rem !important;
}

.m-n9 {
    margin: -7.5rem !important;
}

.m-n10 {
    margin: -10rem !important;
}

.m-n11 {
    margin: -12.5rem !important;
}

.m-n12 {
    margin: -15rem !important;
}

.mx-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
}

.mx-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
}

.mx-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
}

.mx-n4 {
    margin-right: -1.8rem !important;
    margin-left: -1.8rem !important;
}

.mx-n5 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
}

.mx-n6 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
}

.mx-n7 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
}

.mx-n8 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
}

.mx-n9 {
    margin-right: -7.5rem !important;
    margin-left: -7.5rem !important;
}

.mx-n10 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
}

.mx-n11 {
    margin-right: -12.5rem !important;
    margin-left: -12.5rem !important;
}

.mx-n12 {
    margin-right: -15rem !important;
    margin-left: -15rem !important;
}

.my-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
}

.my-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
}

.my-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
}

.my-n4 {
    margin-top: -1.8rem !important;
    margin-bottom: -1.8rem !important;
}

.my-n5 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
}

.my-n6 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
}

.my-n7 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
}

.my-n8 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
}

.my-n9 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important;
}

.my-n10 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
}

.my-n11 {
    margin-top: -12.5rem !important;
    margin-bottom: -12.5rem !important;
}

.my-n12 {
    margin-top: -15rem !important;
    margin-bottom: -15rem !important;
}

.mt-n1 {
    margin-top: -0.25rem !important;
}

.mt-n2 {
    margin-top: -0.5rem !important;
}

.mt-n3 {
    margin-top: -1rem !important;
}

.mt-n4 {
    margin-top: -1.8rem !important;
}

.mt-n5 {
    margin-top: -2.5rem !important;
}

.mt-n6 {
    margin-top: -3rem !important;
}

.mt-n7 {
    margin-top: -4rem !important;
}

.mt-n8 {
    margin-top: -5rem !important;
}

.mt-n9 {
    margin-top: -7.5rem !important;
}

.mt-n10 {
    margin-top: -10rem !important;
}

.mt-n11 {
    margin-top: -12.5rem !important;
}

.mt-n12 {
    margin-top: -15rem !important;
}

.me-n1 {
    margin-right: -0.25rem !important;
}

.me-n2 {
    margin-right: -0.5rem !important;
}

.me-n3 {
    margin-right: -1rem !important;
}

.me-n4 {
    margin-right: -1.8rem !important;
}

.me-n5 {
    margin-right: -2.5rem !important;
}

.me-n6 {
    margin-right: -3rem !important;
}

.me-n7 {
    margin-right: -4rem !important;
}

.me-n8 {
    margin-right: -5rem !important;
}

.me-n9 {
    margin-right: -7.5rem !important;
}

.me-n10 {
    margin-right: -10rem !important;
}

.me-n11 {
    margin-right: -12.5rem !important;
}

.me-n12 {
    margin-right: -15rem !important;
}

.mb-n1 {
    margin-bottom: -0.25rem !important;
}

.mb-n2 {
    margin-bottom: -0.5rem !important;
}

.mb-n3 {
    margin-bottom: -1rem !important;
}

.mb-n4 {
    margin-bottom: -1.8rem !important;
}

.mb-n5 {
    margin-bottom: -2.5rem !important;
}

.mb-n6 {
    margin-bottom: -3rem !important;
}

.mb-n7 {
    margin-bottom: -4rem !important;
}

.mb-n8 {
    margin-bottom: -5rem !important;
}

.mb-n9 {
    margin-bottom: -7.5rem !important;
}

.mb-n10 {
    margin-bottom: -10rem !important;
}

.mb-n11 {
    margin-bottom: -12.5rem !important;
}

.mb-n12 {
    margin-bottom: -15rem !important;
}

.ms-n1 {
    margin-left: -0.25rem !important;
}

.ms-n2 {
    margin-left: -0.5rem !important;
}

.ms-n3 {
    margin-left: -1rem !important;
}

.ms-n4 {
    margin-left: -1.8rem !important;
}

.ms-n5 {
    margin-left: -2.5rem !important;
}

.ms-n6 {
    margin-left: -3rem !important;
}

.ms-n7 {
    margin-left: -4rem !important;
}

.ms-n8 {
    margin-left: -5rem !important;
}

.ms-n9 {
    margin-left: -7.5rem !important;
}

.ms-n10 {
    margin-left: -10rem !important;
}

.ms-n11 {
    margin-left: -12.5rem !important;
}

.ms-n12 {
    margin-left: -15rem !important;
}

.p-0 {
    padding: 0 !important;
}

.p-1 {
    padding: 0.25rem !important;
}

.p-2 {
    padding: 0.5rem !important;
}

.p-3 {
    padding: 1rem !important;
}

.p-4 {
    padding: 1.8rem !important;
}

.p-5 {
    padding: 2.5rem !important;
}

.p-6 {
    padding: 3rem !important;
}

.p-7 {
    padding: 4rem !important;
}

.p-8 {
    padding: 5rem !important;
}

.p-9 {
    padding: 7.5rem !important;
}

.p-10 {
    padding: 10rem !important;
}

.p-11 {
    padding: 12.5rem !important;
}

.p-12 {
    padding: 15rem !important;
}

.px-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
}

.px-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
}

.px-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
}

.px-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
}

.px-4 {
    padding-right: 1.8rem !important;
    padding-left: 1.8rem !important;
}

.px-5 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
}

.px-6 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
}

.px-7 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
}

.px-8 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
}

.px-9 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important;
}

.px-10 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
}

.px-11 {
    padding-right: 12.5rem !important;
    padding-left: 12.5rem !important;
}

.px-12 {
    padding-right: 15rem !important;
    padding-left: 15rem !important;
}

.py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
}

.py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
}

.py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}

.py-4 {
    padding-top: 1.8rem !important;
    padding-bottom: 1.8rem !important;
}

/* .py-5 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
} */

.py-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
}

.py-7 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
}

.py-8 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
}

.py-9 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
}

.py-10 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
}

.py-11 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important;
}

.py-12 {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
}

.pt-0 {
    padding-top: 0 !important;
}

.pt-1 {
    padding-top: 0.25rem !important;
}

.pt-2 {
    padding-top: 0.5rem !important;
}

.pt-3 {
    padding-top: 1rem !important;
}

.pt-4 {
    padding-top: 1.8rem !important;
}

.pt-5 {
    padding-top: 2.5rem !important;
}

.pt-6 {
    padding-top: 3rem !important;
}

.pt-7 {
    padding-top: 4rem !important;
}

.pt-8 {
    padding-top: 5rem !important;
}

.pt-9 {
    padding-top: 7.5rem !important;
}

.pt-10 {
    padding-top: 10rem !important;
}

.pt-11 {
    padding-top: 12.5rem !important;
}

.pt-12 {
    padding-top: 15rem !important;
}

.pe-0 {
    padding-right: 0 !important;
}

.pe-1 {
    padding-right: 0.25rem !important;
}

.pe-2 {
    padding-right: 0.5rem !important;
}

.pe-3 {
    padding-right: 1rem !important;
}

.pe-4 {
    padding-right: 1.8rem !important;
}

.pe-5 {
    padding-right: 2.5rem !important;
}

.pe-6 {
    padding-right: 3rem !important;
}

.pe-7 {
    padding-right: 4rem !important;
}

.pe-8 {
    padding-right: 5rem !important;
}

.pe-9 {
    padding-right: 7.5rem !important;
}

.pe-10 {
    padding-right: 10rem !important;
}

.pe-11 {
    padding-right: 12.5rem !important;
}

.pe-12 {
    padding-right: 15rem !important;
}

.pb-0 {
    padding-bottom: 0 !important;
}

.pb-1 {
    padding-bottom: 0.25rem !important;
}

.pb-2 {
    padding-bottom: 0.5rem !important;
}

.pb-3 {
    padding-bottom: 1rem !important;
}

.pb-4 {
    padding-bottom: 1.8rem !important;
}

.pb-5 {
    padding-bottom: 2.5rem !important;
}

.pb-6 {
    padding-bottom: 3rem !important;
}

.pb-7 {
    padding-bottom: 4rem !important;
}

.pb-8 {
    padding-bottom: 5rem !important;
}

.pb-9 {
    padding-bottom: 7.5rem !important;
}

.pb-10 {
    padding-bottom: 10rem !important;
}

.pb-11 {
    padding-bottom: 12.5rem !important;
}

.pb-12 {
    padding-bottom: 15rem !important;
}

.ps-0 {
    padding-left: 0 !important;
}

.ps-1 {
    padding-left: 0.25rem !important;
}

.ps-2 {
    padding-left: 0.5rem !important;
}

.ps-3 {
    padding-left: 1rem !important;
}

.ps-4 {
    padding-left: 1.8rem !important;
}

.ps-5 {
    padding-left: 2.5rem !important;
}

.ps-6 {
    padding-left: 3rem !important;
}

.ps-7 {
    padding-left: 4rem !important;
}

.ps-8 {
    padding-left: 5rem !important;
}

.ps-9 {
    padding-left: 7.5rem !important;
}

.ps-10 {
    padding-left: 10rem !important;
}

.ps-11 {
    padding-left: 12.5rem !important;
}

.ps-12 {
    padding-left: 15rem !important;
}

.font-monospace {
    font-family: var(--bs-font-monospace) !important;
}

.fs--2 {
    font-size: 0.79012rem !important;
}

.fs--1 {
    font-size: 0.88889rem !important;
}

.fs-0 {
    font-size: 1rem !important;
}

.fs-1 {
    font-size: 1.125rem !important;
}

.fs-2 {
    font-size: 1.26563rem !important;
}

.fs-3 {
    font-size: 1.42383rem !important;
}

.fs-4 {
    font-size: 1.60181rem !important;
}

.fs-5 {
    font-size: 1.80203rem !important;
}

.fs-6 {
    font-size: 2.02729rem !important;
}

.fs-7 {
    font-size: 2.2807rem !important;
}

.fs-8 {
    font-size: 2.56578rem !important;
}

.fs-9 {
    font-size: 2.88651rem !important;
}

.fs-10 {
    font-size: 3.24732rem !important;
}

.fs-11 {
    font-size: 3.65324rem !important;
}

.fs-12 {
    font-size: 4.10989rem !important;
}

.fs-13 {
    font-size: 4.62363rem !important;
}

.fs-14 {
    font-size: 5.20158rem !important;
}

.fst-italic {
    font-style: italic !important;
}

.fst-normal {
    font-style: normal !important;
}

.fw-thin {
    font-weight: 100 !important;
}

.fw-lighter {
    font-weight: 200 !important;
}

.fw-light {
    font-weight: 300 !important;
}

.fw-normal {
    font-weight: 400 !important;
}

.fw-medium {
    font-weight: 500 !important;
}

.fw-semi-bold {
    font-weight: 600 !important;
}

.fw-bold {
    font-weight: 700 !important;
}

.fw-bolder {
    font-weight: 800 !important;
}

.fw-black {
    font-weight: 900 !important;
}

.lh-unset {
    line-height: unset !important;
}

.lh-1 {
    line-height: 1 !important;
}

.lh-1-3 {
    line-height: 1.3 !important;
}

.lh-1-4 {
    line-height: 1.4 !important;
}

.lh-1-5 {
    line-height: 1.5 !important;
}

.lh-1-6 {
    line-height: 1.6 !important;
}

.lh-1-7 {
    line-height: 1.7 !important;
}

.lh-1-8 {
    line-height: 1.8 !important;
}

.lh-1-9 {
    line-height: 1.9 !important;
}

.lh-2 {
    line-height: 2 !important;
}

.text-start {
    text-align: left !important;
}

.text-end {
    text-align: right !important;
}

.text-center {
    text-align: center !important;
}

.text-decoration-none {
    text-decoration: none !important;
}

.text-decoration-underline {
    text-decoration: underline !important;
}

.text-decoration-line-through {
    text-decoration: line-through !important;
}

.text-lowercase {
    text-transform: lowercase !important;
}

.text-uppercase {
    text-transform: uppercase !important;
}

.text-capitalize {
    text-transform: capitalize !important;
}

.text-wrap {
    white-space: normal !important;
}

.text-nowrap {
    white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
    word-wrap: break-word !important;
    word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
    color: #f1a501 !important;
}

.text-secondary {
    color: #5e6282 !important;
}

.text-success {
    color: #79b93c !important;
}

.text-info {
    color: #006380 !important;
}

.text-warning {
    color: #f1a501 !important;
}

.text-danger {
    color: #df6951 !important;
}

.text-light {
    color: #f0f4f9 !important;
}

.text-dark {
    color: #212832 !important;
}

.text-white {
    color: #fffefe !important;
}

.text-body {
    color: #5e6282 !important;
}

.text-muted {
    color: #bdbdbd !important;
}

.text-black-50 {
    color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
    color: rgba(255, 254, 254, 0.5) !important;
}

.text-reset {
    color: inherit !important;
}

.bg-facebook {
    background-color: #3c5a99 !important;
}

.bg-google-plus {
    background-color: #dd4b39 !important;
}

.bg-twitter {
    background-color: #1da1f2 !important;
}

.bg-whatsapp {
    background-color: #1da1f2 !important;
}

.bg-linkedin {
    background-color: #0077b5 !important;
}

.bg-youtube {
    background-color: #ff0000 !important;
}

.bg-github {
    background-color: #333 !important;
}

.bg-black {
    background-color: #000 !important;
}

.bg-100 {
    background-color: #fcfcfc !important;
}

.bg-200 {
    background-color: #f5f5f5 !important;
}

.bg-300 {
    background-color: #eeeeee !important;
}

.bg-400 {
    background-color: #e0e0e0 !important;
}

.bg-500 {
    background-color: #bdbdbd !important;
}

.bg-600 {
    background-color: #f0f4f9 !important;
}

.bg-700 {
    background-color: #757575 !important;
}

.bg-800 {
    background-color: #616161 !important;
}

.bg-900 {
    background-color: #5e6282 !important;
}

.bg-1000 {
    background-color: #212832 !important;
}

.bg-white {
    background-color: #fffefe !important;
}

.bg-primary {
    background-color: #f1a501 !important;
}

.bg-secondary {
    background-color: #5e6282 !important;
}

.bg-success {
    background-color: #79b93c !important;
}

.bg-info {
    background-color: #006380 !important;
}

.bg-warning {
    background-color: #f1a501 !important;
}

.bg-danger {
    background-color: #df6951 !important;
}

.bg-light {
    background-color: #f0f4f9 !important;
}

.bg-dark {
    background-color: #212832 !important;
}

.bg-body {
    background-color: "body" !important;
}

.bg-transparent {
    background-color: transparent !important;
}

.bg-gradient {
    background-image: var(--bs-gradient) !important;
}

.user-select-all {
    -webkit-user-select: all !important;
    -moz-user-select: all !important;
    -ms-user-select: all !important;
    user-select: all !important;
}

.user-select-auto {
    -webkit-user-select: auto !important;
    -moz-user-select: auto !important;
    -ms-user-select: auto !important;
    user-select: auto !important;
}

.user-select-none {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
}

.pe-none {
    pointer-events: none !important;
}

.pe-auto {
    pointer-events: auto !important;
}

.rounded {
    border-radius: 1.5rem !important;
}

.rounded-0 {
    border-radius: 0 !important;
}

.rounded-1 {
    border-radius: 0.625rem !important;
}

.rounded-2 {
    border-radius: 1.5rem !important;
}

.rounded-3 {
    border-radius: 2.25rem !important;
}

.rounded-circle {
    border-radius: 50% !important;
}

.rounded-pill {
    border-radius: 50rem !important;
}

.rounded-top {
    border-top-left-radius: 1.5rem !important;
    border-top-right-radius: 1.5rem !important;
}

.rounded-top-lg {
    border-top-left-radius: 2.25rem !important;
    border-top-right-radius: 2.25rem !important;
}

.rounded-top-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
}

.rounded-end {
    border-top-right-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
}

.rounded-end-lg {
    border-top-right-radius: 2.25rem !important;
    border-bottom-right-radius: 2.25rem !important;
}

.rounded-end-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.rounded-bottom {
    border-bottom-right-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
}

.rounded-bottom-lg {
    border-bottom-right-radius: 2.25rem !important;
    border-bottom-left-radius: 2.25rem !important;
}

.rounded-bottom-0 {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.rounded-start {
    border-bottom-left-radius: 1.5rem !important;
    border-top-left-radius: 1.5rem !important;
}

.rounded-start-lg {
    border-bottom-left-radius: 2.25rem !important;
    border-top-left-radius: 2.25rem !important;
}

.rounded-start-0 {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
}

.visible {
    visibility: visible !important;
}

.invisible {
    visibility: hidden !important;
}

.opacity-0 {
    opacity: 0 !important;
}

.opacity-25 {
    opacity: 0.25 !important;
}

.opacity-50 {
    opacity: 0.5 !important;
}

.opacity-75 {
    opacity: 0.75 !important;
}

.opacity-85 {
    opacity: 0.85 !important;
}

.opacity-100 {
    opacity: 1 !important;
}

.text-facebook {
    color: #3c5a99 !important;
}

.text-google-plus {
    color: #dd4b39 !important;
}

.text-twitter {
    color: #1da1f2 !important;
}

.text-whatsapp {
    border-color: #1da1f2 !important;
}

.text-linkedin {
    color: #0077b5 !important;
}

.text-youtube {
    color: #ff0000 !important;
}

.text-github {
    color: #333 !important;
}

.text-black {
    color: #000 !important;
}

.text-100 {
    color: #fcfcfc !important;
}

.text-200 {
    color: #f5f5f5 !important;
}

.text-300 {
    color: #eeeeee !important;
}

.text-400 {
    color: #e0e0e0 !important;
}

.text-500 {
    color: #bdbdbd !important;
}

.text-600 {
    color: #f0f4f9 !important;
}

.text-700 {
    color: #757575 !important;
}

.text-800 {
    color: #616161 !important;
}

.text-900 {
    color: #5e6282 !important;
}

.text-1000 {
    color: #212832 !important;
}

.text-white {
    color: #fffefe !important;
}

.max-vh-25 {
    max-height: 25vh !important;
}

.max-vh-50 {
    max-height: 50vh !important;
}

.max-vh-75 {
    max-height: 75vh !important;
}

.max-vh-100 {
    max-height: 100vh !important;
}

.border-x {
    border-left: 1px solid #eeeeee !important;
    border-right: 1px solid #eeeeee !important;
}

.border-x-0 {
    border-left: 0 !important;
    border-right: 0 !important;
}

.border-y {
    border-top: 1px solid #eeeeee !important;
    border-bottom: 1px solid #eeeeee !important;
}

.border-y-0 {
    border-top: 0 !important;
    border-bottom: 0 !important;
}

.border-dashed {
    border: 1px dashed #eeeeee !important;
}

.border-dashed-top {
    border-top: 1px dashed #eeeeee !important;
}

.border-dashed-end {
    border-right: 1px dashed #eeeeee !important;
}

.border-dashed-start {
    border-left: 1px dashed #eeeeee !important;
}

.border-dashed-bottom {
    border-bottom: 1px dashed #eeeeee !important;
}

.border-dashed-x {
    border-left: 1px dashed #eeeeee !important;
    border-right: 1px dashed #eeeeee !important;
}

.border-dashed-y {
    border-top: 1px dashed #eeeeee !important;
    border-bottom: 1px dashed #eeeeee !important;
}

.rounded-0 {
    border-radius: 0 !important;
}

.bg-soft-primary {
    background-color: #fdf2d9 !important;
}

.bg-soft-secondary {
    background-color: #e7e7ec !important;
}

.bg-soft-success {
    background-color: #ebf5e2 !important;
}

.bg-soft-info {
    background-color: #d9e8ec !important;
}

.bg-soft-warning {
    background-color: #fdf2d9 !important;
}

.bg-soft-danger {
    background-color: #fae9e5 !important;
}

.bg-soft-light {
    background-color: #fdfdfe !important;
}

.bg-soft-dark {
    background-color: #dedfe0 !important;
}

@media (min-width: 576px) {
    .float-sm-start {
        float: left !important;
    }

    .float-sm-end {
        float: right !important;
    }

    .float-sm-none {
        float: none !important;
    }

    .overflow-sm-unset {
        overflow: unset !important;
    }

    .overflow-sm-auto {
        overflow: auto !important;
    }

    .overflow-sm-hidden {
        overflow: hidden !important;
    }

    .overflow-sm-visible {
        overflow: visible !important;
    }

    .overflow-sm-scroll {
        overflow: scroll !important;
    }

    .d-sm-inline {
        display: inline !important;
    }

    .d-sm-inline-block {
        display: inline-block !important;
    }

    .d-sm-block {
        display: block !important;
    }

    .d-sm-grid {
        display: grid !important;
    }

    .d-sm-table {
        display: table !important;
    }

    .d-sm-table-row {
        display: table-row !important;
    }

    .d-sm-table-cell {
        display: table-cell !important;
    }

    .d-sm-flex {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
    }

    .d-sm-inline-flex {
        display: -webkit-inline-box !important;
        display: -ms-inline-flexbox !important;
        display: inline-flex !important;
    }

    .d-sm-none {
        display: none !important;
    }

    .position-sm-static {
        position: static !important;
    }

    .position-sm-absolute {
        position: absolute !important;
    }

    .position-sm-relative {
        position: relative !important;
    }

    .position-sm-fixed {
        position: fixed !important;
    }

    .position-sm-sticky {
        position: -webkit-sticky !important;
        position: sticky !important;
    }

    .top-sm-unset {
        top: unset !important;
    }

    .top-sm-0 {
        top: 0 !important;
    }

    .top-sm-50 {
        top: 50% !important;
    }

    .top-sm-100 {
        top: 100% !important;
    }

    .bottom-sm-unset {
        bottom: unset !important;
    }

    .bottom-sm-0 {
        bottom: 0 !important;
    }

    .bottom-sm-50 {
        bottom: 50% !important;
    }

    .bottom-sm-100 {
        bottom: 100% !important;
    }

    .start-sm-unset {
        left: unset !important;
    }

    .start-sm-0 {
        left: 0 !important;
    }

    .start-sm-50 {
        left: 50% !important;
    }

    .start-sm-100 {
        left: 100% !important;
    }

    .end-sm-unset {
        right: unset !important;
    }

    .end-sm-0 {
        right: 0 !important;
    }

    .end-sm-50 {
        right: 50% !important;
    }

    .end-sm-100 {
        right: 100% !important;
    }

    .translate-sm-middle {
        -webkit-transform: translateX(-50%) translateY(-50%) !important;
        -ms-transform: translateX(-50%) translateY(-50%) !important;
        transform: translateX(-50%) translateY(-50%) !important;
    }

    .translate-sm-middle-x {
        -webkit-transform: translateX(-50%) !important;
        -ms-transform: translateX(-50%) !important;
        transform: translateX(-50%) !important;
    }

    .translate-sm-middle-y {
        -webkit-transform: translateY(-50%) !important;
        -ms-transform: translateY(-50%) !important;
        transform: translateY(-50%) !important;
    }

    .border-sm {
        border: 1px solid #eeeeee !important;
    }

    .border-sm-0 {
        border: 0 !important;
    }

    .border-sm-top {
        border-top: 1px solid #eeeeee !important;
    }

    .border-sm-top-0 {
        border-top: 0 !important;
    }

    .border-sm-end {
        border-right: 1px solid #eeeeee !important;
    }

    .border-sm-end-0 {
        border-right: 0 !important;
    }

    .border-sm-bottom {
        border-bottom: 1px solid #eeeeee !important;
    }

    .border-sm-bottom-0 {
        border-bottom: 0 !important;
    }

    .border-sm-start {
        border-left: 1px solid #eeeeee !important;
    }

    .border-sm-start-0 {
        border-left: 0 !important;
    }

    .border-sm-facebook {
        border-color: #3c5a99 !important;
    }

    .border-sm-google-plus {
        border-color: #dd4b39 !important;
    }

    .border-sm-twitter {
        border-color: #1da1f2 !important;
    }

    .border-sm-whatsapp {
        border-color: #1da1f2 !important;
    }

    .border-sm-linkedin {
        border-color: #0077b5 !important;
    }

    .border-sm-youtube {
        border-color: #ff0000 !important;
    }

    .border-sm-github {
        border-color: #333 !important;
    }

    .border-sm-black {
        border-color: #000 !important;
    }

    .border-sm-100 {
        border-color: #fcfcfc !important;
    }

    .border-sm-200 {
        border-color: #f5f5f5 !important;
    }

    .border-sm-300 {
        border-color: #eeeeee !important;
    }

    .border-sm-400 {
        border-color: #e0e0e0 !important;
    }

    .border-sm-500 {
        border-color: #bdbdbd !important;
    }

    .border-sm-600 {
        border-color: #f0f4f9 !important;
    }

    .border-sm-700 {
        border-color: #757575 !important;
    }

    .border-sm-800 {
        border-color: #616161 !important;
    }

    .border-sm-900 {
        border-color: #5e6282 !important;
    }

    .border-sm-1000 {
        border-color: #212832 !important;
    }

    .border-sm-white {
        border-color: #fffefe !important;
    }

    .border-sm-primary {
        border-color: #f1a501 !important;
    }

    .border-sm-secondary {
        border-color: #5e6282 !important;
    }

    .border-sm-success {
        border-color: #79b93c !important;
    }

    .border-sm-info {
        border-color: #006380 !important;
    }

    .border-sm-warning {
        border-color: #f1a501 !important;
    }

    .border-sm-danger {
        border-color: #df6951 !important;
    }

    .border-sm-light {
        border-color: #f0f4f9 !important;
    }

    .border-sm-dark {
        border-color: #212832 !important;
    }

    .w-sm-25 {
        width: 25% !important;
    }

    .w-sm-50 {
        width: 50% !important;
    }

    .w-sm-75 {
        width: 75% !important;
    }

    .w-sm-100 {
        width: 100% !important;
    }

    .w-sm-auto {
        width: auto !important;
    }

    .vw-sm-25 {
        width: 25vw !important;
    }

    .vw-sm-50 {
        width: 50vw !important;
    }

    .vw-sm-75 {
        width: 75vw !important;
    }

    .vw-sm-100 {
        width: 100vw !important;
    }

    .h-sm-25 {
        height: 25% !important;
    }

    .h-sm-50 {
        height: 50% !important;
    }

    .h-sm-75 {
        height: 75% !important;
    }

    .h-sm-100 {
        height: 100% !important;
    }

    .h-sm-auto {
        height: auto !important;
    }

    .vh-sm-25 {
        height: 25vh !important;
    }

    .vh-sm-50 {
        height: 50vh !important;
    }

    .vh-sm-75 {
        height: 75vh !important;
    }

    .vh-sm-100 {
        height: 100vh !important;
    }

    .min-vh-sm-25 {
        min-height: 25vh !important;
    }

    .min-vh-sm-50 {
        min-height: 50vh !important;
    }

    .min-vh-sm-75 {
        min-height: 75vh !important;
    }

    .min-vh-sm-100 {
        min-height: 100vh !important;
    }

    .flex-sm-fill {
        -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important;
    }

    .flex-sm-row {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
        flex-direction: row !important;
    }

    .flex-sm-column {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
        flex-direction: column !important;
    }

    .flex-sm-row-reverse {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important;
    }

    .flex-sm-column-reverse {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important;
    }

    .flex-sm-grow-0 {
        -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
        flex-grow: 0 !important;
    }

    .flex-sm-grow-1 {
        -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
        flex-grow: 1 !important;
    }

    .flex-sm-shrink-0 {
        -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
    }

    .flex-sm-shrink-1 {
        -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
    }

    .flex-sm-wrap {
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
    }

    .flex-sm-nowrap {
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
    }

    .flex-sm-wrap-reverse {
        -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
    }

    .gap-sm-0 {
        gap: 0 !important;
    }

    .gap-sm-1 {
        gap: 0.25rem !important;
    }

    .gap-sm-2 {
        gap: 0.5rem !important;
    }

    .gap-sm-3 {
        gap: 1rem !important;
    }

    .gap-sm-4 {
        gap: 1.8rem !important;
    }

    .gap-sm-5 {
        gap: 2.5rem !important;
    }

    .gap-sm-6 {
        gap: 3rem !important;
    }

    .gap-sm-7 {
        gap: 4rem !important;
    }

    .gap-sm-8 {
        gap: 5rem !important;
    }

    .gap-sm-9 {
        gap: 7.5rem !important;
    }

    .gap-sm-10 {
        gap: 10rem !important;
    }

    .gap-sm-11 {
        gap: 12.5rem !important;
    }

    .gap-sm-12 {
        gap: 15rem !important;
    }

    .justify-content-sm-start {
        -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
        justify-content: flex-start !important;
    }

    .justify-content-sm-end {
        -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
        justify-content: flex-end !important;
    }

    .justify-content-sm-center {
        -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
        justify-content: center !important;
    }

    .justify-content-sm-between {
        -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
        justify-content: space-between !important;
    }

    .justify-content-sm-around {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
    }

    .justify-content-sm-evenly {
        -webkit-box-pack: space-evenly !important;
        -ms-flex-pack: space-evenly !important;
        justify-content: space-evenly !important;
    }

    .align-items-sm-start {
        -webkit-box-align: start !important;
        -ms-flex-align: start !important;
        align-items: flex-start !important;
    }

    .align-items-sm-end {
        -webkit-box-align: end !important;
        -ms-flex-align: end !important;
        align-items: flex-end !important;
    }

    .align-items-sm-center {
        -webkit-box-align: center !important;
        -ms-flex-align: center !important;
        align-items: center !important;
    }

    .align-items-sm-baseline {
        -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
        align-items: baseline !important;
    }

    .align-items-sm-stretch {
        -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
        align-items: stretch !important;
    }

    .align-content-sm-start {
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
    }

    .align-content-sm-end {
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
    }

    .align-content-sm-center {
        -ms-flex-line-pack: center !important;
        align-content: center !important;
    }

    .align-content-sm-between {
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
    }

    .align-content-sm-around {
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
    }

    .align-content-sm-stretch {
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
    }

    .align-self-sm-auto {
        -ms-flex-item-align: auto !important;
        align-self: auto !important;
    }

    .align-self-sm-start {
        -ms-flex-item-align: start !important;
        align-self: flex-start !important;
    }

    .align-self-sm-end {
        -ms-flex-item-align: end !important;
        align-self: flex-end !important;
    }

    .align-self-sm-center {
        -ms-flex-item-align: center !important;
        align-self: center !important;
    }

    .align-self-sm-baseline {
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
    }

    .align-self-sm-stretch {
        -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
    }

    .order-sm-first {
        -webkit-box-ordinal-group: 0 !important;
        -ms-flex-order: -1 !important;
        order: -1 !important;
    }

    .order-sm-0 {
        -webkit-box-ordinal-group: 1 !important;
        -ms-flex-order: 0 !important;
        order: 0 !important;
    }

    .order-sm-1 {
        -webkit-box-ordinal-group: 2 !important;
        -ms-flex-order: 1 !important;
        order: 1 !important;
    }

    .order-sm-2 {
        -webkit-box-ordinal-group: 3 !important;
        -ms-flex-order: 2 !important;
        order: 2 !important;
    }

    .order-sm-3 {
        -webkit-box-ordinal-group: 4 !important;
        -ms-flex-order: 3 !important;
        order: 3 !important;
    }

    .order-sm-4 {
        -webkit-box-ordinal-group: 5 !important;
        -ms-flex-order: 4 !important;
        order: 4 !important;
    }

    .order-sm-5 {
        -webkit-box-ordinal-group: 6 !important;
        -ms-flex-order: 5 !important;
        order: 5 !important;
    }

    .order-sm-last {
        -webkit-box-ordinal-group: 7 !important;
        -ms-flex-order: 6 !important;
        order: 6 !important;
    }

    .m-sm-0 {
        margin: 0 !important;
    }

    .m-sm-1 {
        margin: 0.25rem !important;
    }

    .m-sm-2 {
        margin: 0.5rem !important;
    }

    .m-sm-3 {
        margin: 1rem !important;
    }

    .m-sm-4 {
        margin: 1.8rem !important;
    }

    .m-sm-5 {
        margin: 2.5rem !important;
    }

    .m-sm-6 {
        margin: 3rem !important;
    }

    .m-sm-7 {
        margin: 4rem !important;
    }

    .m-sm-8 {
        margin: 5rem !important;
    }

    .m-sm-9 {
        margin: 7.5rem !important;
    }

    .m-sm-10 {
        margin: 10rem !important;
    }

    .m-sm-11 {
        margin: 12.5rem !important;
    }

    .m-sm-12 {
        margin: 15rem !important;
    }

    .m-sm-auto {
        margin: auto !important;
    }

    .mx-sm-0 {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }

    .mx-sm-1 {
        margin-right: 0.25rem !important;
        margin-left: 0.25rem !important;
    }

    .mx-sm-2 {
        margin-right: 0.5rem !important;
        margin-left: 0.5rem !important;
    }

    .mx-sm-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important;
    }

    .mx-sm-4 {
        margin-right: 1.8rem !important;
        margin-left: 1.8rem !important;
    }

    .mx-sm-5 {
        margin-right: 2.5rem !important;
        margin-left: 2.5rem !important;
    }

    .mx-sm-6 {
        margin-right: 3rem !important;
        margin-left: 3rem !important;
    }

    .mx-sm-7 {
        margin-right: 4rem !important;
        margin-left: 4rem !important;
    }

    .mx-sm-8 {
        margin-right: 5rem !important;
        margin-left: 5rem !important;
    }

    .mx-sm-9 {
        margin-right: 7.5rem !important;
        margin-left: 7.5rem !important;
    }

    .mx-sm-10 {
        margin-right: 10rem !important;
        margin-left: 10rem !important;
    }

    .mx-sm-11 {
        margin-right: 12.5rem !important;
        margin-left: 12.5rem !important;
    }

    .mx-sm-12 {
        margin-right: 15rem !important;
        margin-left: 15rem !important;
    }

    .mx-sm-auto {
        margin-right: auto !important;
        margin-left: auto !important;
    }

    .my-sm-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }

    .my-sm-1 {
        margin-top: 0.25rem !important;
        margin-bottom: 0.25rem !important;
    }

    .my-sm-2 {
        margin-top: 0.5rem !important;
        margin-bottom: 0.5rem !important;
    }

    .my-sm-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
    }

    .my-sm-4 {
        margin-top: 1.8rem !important;
        margin-bottom: 1.8rem !important;
    }

    .my-sm-5 {
        margin-top: 2.5rem !important;
        margin-bottom: 2.5rem !important;
    }

    .my-sm-6 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important;
    }

    .my-sm-7 {
        margin-top: 4rem !important;
        margin-bottom: 4rem !important;
    }

    .my-sm-8 {
        margin-top: 5rem !important;
        margin-bottom: 5rem !important;
    }

    .my-sm-9 {
        margin-top: 7.5rem !important;
        margin-bottom: 7.5rem !important;
    }

    .my-sm-10 {
        margin-top: 10rem !important;
        margin-bottom: 10rem !important;
    }

    .my-sm-11 {
        margin-top: 12.5rem !important;
        margin-bottom: 12.5rem !important;
    }

    .my-sm-12 {
        margin-top: 15rem !important;
        margin-bottom: 15rem !important;
    }

    .my-sm-auto {
        margin-top: auto !important;
        margin-bottom: auto !important;
    }

    .mt-sm-0 {
        margin-top: 0 !important;
    }

    .mt-sm-1 {
        margin-top: 0.25rem !important;
    }

    .mt-sm-2 {
        margin-top: 0.5rem !important;
    }

    .mt-sm-3 {
        margin-top: 1rem !important;
    }

    .mt-sm-4 {
        margin-top: 1.8rem !important;
    }

    .mt-sm-5 {
        margin-top: 2.5rem !important;
    }

    .mt-sm-6 {
        margin-top: 3rem !important;
    }

    .mt-sm-7 {
        margin-top: 4rem !important;
    }

    .mt-sm-8 {
        margin-top: 5rem !important;
    }

    .mt-sm-9 {
        margin-top: 7.5rem !important;
    }

    .mt-sm-10 {
        margin-top: 10rem !important;
    }

    .mt-sm-11 {
        margin-top: 12.5rem !important;
    }

    .mt-sm-12 {
        margin-top: 15rem !important;
    }

    .mt-sm-auto {
        margin-top: auto !important;
    }

    .me-sm-0 {
        margin-right: 0 !important;
    }

    .me-sm-1 {
        margin-right: 0.25rem !important;
    }

    .me-sm-2 {
        margin-right: 0.5rem !important;
    }

    .me-sm-3 {
        margin-right: 1rem !important;
    }

    .me-sm-4 {
        margin-right: 1.8rem !important;
    }

    .me-sm-5 {
        margin-right: 2.5rem !important;
    }

    .me-sm-6 {
        margin-right: 3rem !important;
    }

    .me-sm-7 {
        margin-right: 4rem !important;
    }

    .me-sm-8 {
        margin-right: 5rem !important;
    }

    .me-sm-9 {
        margin-right: 7.5rem !important;
    }

    .me-sm-10 {
        margin-right: 10rem !important;
    }

    .me-sm-11 {
        margin-right: 12.5rem !important;
    }

    .me-sm-12 {
        margin-right: 15rem !important;
    }

    .me-sm-auto {
        margin-right: auto !important;
    }

    .mb-sm-0 {
        margin-bottom: 0 !important;
    }

    .mb-sm-1 {
        margin-bottom: 0.25rem !important;
    }

    .mb-sm-2 {
        margin-bottom: 0.5rem !important;
    }

    .mb-sm-3 {
        margin-bottom: 1rem !important;
    }

    .mb-sm-4 {
        margin-bottom: 1.8rem !important;
    }

    .mb-sm-5 {
        margin-bottom: 2.5rem !important;
    }

    .mb-sm-6 {
        margin-bottom: 3rem !important;
    }

    .mb-sm-7 {
        margin-bottom: 4rem !important;
    }

    .mb-sm-8 {
        margin-bottom: 5rem !important;
    }

    .mb-sm-9 {
        margin-bottom: 7.5rem !important;
    }

    .mb-sm-10 {
        margin-bottom: 10rem !important;
    }

    .mb-sm-11 {
        margin-bottom: 12.5rem !important;
    }

    .mb-sm-12 {
        margin-bottom: 15rem !important;
    }

    .mb-sm-auto {
        margin-bottom: auto !important;
    }

    .ms-sm-0 {
        margin-left: 0 !important;
    }

    .ms-sm-1 {
        margin-left: 0.25rem !important;
    }

    .ms-sm-2 {
        margin-left: 0.5rem !important;
    }

    .ms-sm-3 {
        margin-left: 1rem !important;
    }

    .ms-sm-4 {
        margin-left: 1.8rem !important;
    }

    .ms-sm-5 {
        margin-left: 2.5rem !important;
    }

    .ms-sm-6 {
        margin-left: 3rem !important;
    }

    .ms-sm-7 {
        margin-left: 4rem !important;
    }

    .ms-sm-8 {
        margin-left: 5rem !important;
    }

    .ms-sm-9 {
        margin-left: 7.5rem !important;
    }

    .ms-sm-10 {
        margin-left: 10rem !important;
    }

    .ms-sm-11 {
        margin-left: 12.5rem !important;
    }

    .ms-sm-12 {
        margin-left: 15rem !important;
    }

    .ms-sm-auto {
        margin-left: auto !important;
    }

    .m-sm-n1 {
        margin: -0.25rem !important;
    }

    .m-sm-n2 {
        margin: -0.5rem !important;
    }

    .m-sm-n3 {
        margin: -1rem !important;
    }

    .m-sm-n4 {
        margin: -1.8rem !important;
    }

    .m-sm-n5 {
        margin: -2.5rem !important;
    }

    .m-sm-n6 {
        margin: -3rem !important;
    }

    .m-sm-n7 {
        margin: -4rem !important;
    }

    .m-sm-n8 {
        margin: -5rem !important;
    }

    .m-sm-n9 {
        margin: -7.5rem !important;
    }

    .m-sm-n10 {
        margin: -10rem !important;
    }

    .m-sm-n11 {
        margin: -12.5rem !important;
    }

    .m-sm-n12 {
        margin: -15rem !important;
    }

    .mx-sm-n1 {
        margin-right: -0.25rem !important;
        margin-left: -0.25rem !important;
    }

    .mx-sm-n2 {
        margin-right: -0.5rem !important;
        margin-left: -0.5rem !important;
    }

    .mx-sm-n3 {
        margin-right: -1rem !important;
        margin-left: -1rem !important;
    }

    .mx-sm-n4 {
        margin-right: -1.8rem !important;
        margin-left: -1.8rem !important;
    }

    .mx-sm-n5 {
        margin-right: -2.5rem !important;
        margin-left: -2.5rem !important;
    }

    .mx-sm-n6 {
        margin-right: -3rem !important;
        margin-left: -3rem !important;
    }

    .mx-sm-n7 {
        margin-right: -4rem !important;
        margin-left: -4rem !important;
    }

    .mx-sm-n8 {
        margin-right: -5rem !important;
        margin-left: -5rem !important;
    }

    .mx-sm-n9 {
        margin-right: -7.5rem !important;
        margin-left: -7.5rem !important;
    }

    .mx-sm-n10 {
        margin-right: -10rem !important;
        margin-left: -10rem !important;
    }

    .mx-sm-n11 {
        margin-right: -12.5rem !important;
        margin-left: -12.5rem !important;
    }

    .mx-sm-n12 {
        margin-right: -15rem !important;
        margin-left: -15rem !important;
    }

    .my-sm-n1 {
        margin-top: -0.25rem !important;
        margin-bottom: -0.25rem !important;
    }

    .my-sm-n2 {
        margin-top: -0.5rem !important;
        margin-bottom: -0.5rem !important;
    }

    .my-sm-n3 {
        margin-top: -1rem !important;
        margin-bottom: -1rem !important;
    }

    .my-sm-n4 {
        margin-top: -1.8rem !important;
        margin-bottom: -1.8rem !important;
    }

    .my-sm-n5 {
        margin-top: -2.5rem !important;
        margin-bottom: -2.5rem !important;
    }

    .my-sm-n6 {
        margin-top: -3rem !important;
        margin-bottom: -3rem !important;
    }

    .my-sm-n7 {
        margin-top: -4rem !important;
        margin-bottom: -4rem !important;
    }

    .my-sm-n8 {
        margin-top: -5rem !important;
        margin-bottom: -5rem !important;
    }

    .my-sm-n9 {
        margin-top: -7.5rem !important;
        margin-bottom: -7.5rem !important;
    }

    .my-sm-n10 {
        margin-top: -10rem !important;
        margin-bottom: -10rem !important;
    }

    .my-sm-n11 {
        margin-top: -12.5rem !important;
        margin-bottom: -12.5rem !important;
    }

    .my-sm-n12 {
        margin-top: -15rem !important;
        margin-bottom: -15rem !important;
    }

    .mt-sm-n1 {
        margin-top: -0.25rem !important;
    }

    .mt-sm-n2 {
        margin-top: -0.5rem !important;
    }

    .mt-sm-n3 {
        margin-top: -1rem !important;
    }

    .mt-sm-n4 {
        margin-top: -1.8rem !important;
    }

    .mt-sm-n5 {
        margin-top: -2.5rem !important;
    }

    .mt-sm-n6 {
        margin-top: -3rem !important;
    }

    .mt-sm-n7 {
        margin-top: -4rem !important;
    }

    .mt-sm-n8 {
        margin-top: -5rem !important;
    }

    .mt-sm-n9 {
        margin-top: -7.5rem !important;
    }

    .mt-sm-n10 {
        margin-top: -10rem !important;
    }

    .mt-sm-n11 {
        margin-top: -12.5rem !important;
    }

    .mt-sm-n12 {
        margin-top: -15rem !important;
    }

    .me-sm-n1 {
        margin-right: -0.25rem !important;
    }

    .me-sm-n2 {
        margin-right: -0.5rem !important;
    }

    .me-sm-n3 {
        margin-right: -1rem !important;
    }

    .me-sm-n4 {
        margin-right: -1.8rem !important;
    }

    .me-sm-n5 {
        margin-right: -2.5rem !important;
    }

    .me-sm-n6 {
        margin-right: -3rem !important;
    }

    .me-sm-n7 {
        margin-right: -4rem !important;
    }

    .me-sm-n8 {
        margin-right: -5rem !important;
    }

    .me-sm-n9 {
        margin-right: -7.5rem !important;
    }

    .me-sm-n10 {
        margin-right: -10rem !important;
    }

    .me-sm-n11 {
        margin-right: -12.5rem !important;
    }

    .me-sm-n12 {
        margin-right: -15rem !important;
    }

    .mb-sm-n1 {
        margin-bottom: -0.25rem !important;
    }

    .mb-sm-n2 {
        margin-bottom: -0.5rem !important;
    }

    .mb-sm-n3 {
        margin-bottom: -1rem !important;
    }

    .mb-sm-n4 {
        margin-bottom: -1.8rem !important;
    }

    .mb-sm-n5 {
        margin-bottom: -2.5rem !important;
    }

    .mb-sm-n6 {
        margin-bottom: -3rem !important;
    }

    .mb-sm-n7 {
        margin-bottom: -4rem !important;
    }

    .mb-sm-n8 {
        margin-bottom: -5rem !important;
    }

    .mb-sm-n9 {
        margin-bottom: -7.5rem !important;
    }

    .mb-sm-n10 {
        margin-bottom: -10rem !important;
    }

    .mb-sm-n11 {
        margin-bottom: -12.5rem !important;
    }

    .mb-sm-n12 {
        margin-bottom: -15rem !important;
    }

    .ms-sm-n1 {
        margin-left: -0.25rem !important;
    }

    .ms-sm-n2 {
        margin-left: -0.5rem !important;
    }

    .ms-sm-n3 {
        margin-left: -1rem !important;
    }

    .ms-sm-n4 {
        margin-left: -1.8rem !important;
    }

    .ms-sm-n5 {
        margin-left: -2.5rem !important;
    }

    .ms-sm-n6 {
        margin-left: -3rem !important;
    }

    .ms-sm-n7 {
        margin-left: -4rem !important;
    }

    .ms-sm-n8 {
        margin-left: -5rem !important;
    }

    .ms-sm-n9 {
        margin-left: -7.5rem !important;
    }

    .ms-sm-n10 {
        margin-left: -10rem !important;
    }

    .ms-sm-n11 {
        margin-left: -12.5rem !important;
    }

    .ms-sm-n12 {
        margin-left: -15rem !important;
    }

    .p-sm-0 {
        padding: 0 !important;
    }

    .p-sm-1 {
        padding: 0.25rem !important;
    }

    .p-sm-2 {
        padding: 0.5rem !important;
    }

    .p-sm-3 {
        padding: 1rem !important;
    }

    .p-sm-4 {
        padding: 1.8rem !important;
    }

    .p-sm-5 {
        padding: 2.5rem !important;
    }

    .p-sm-6 {
        padding: 3rem !important;
    }

    .p-sm-7 {
        padding: 4rem !important;
    }

    .p-sm-8 {
        padding: 5rem !important;
    }

    .p-sm-9 {
        padding: 7.5rem !important;
    }

    .p-sm-10 {
        padding: 10rem !important;
    }

    .p-sm-11 {
        padding: 12.5rem !important;
    }

    .p-sm-12 {
        padding: 15rem !important;
    }

    .px-sm-0 {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }

    .px-sm-1 {
        padding-right: 0.25rem !important;
        padding-left: 0.25rem !important;
    }

    .px-sm-2 {
        padding-right: 0.5rem !important;
        padding-left: 0.5rem !important;
    }

    .px-sm-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    }

    .px-sm-4 {
        padding-right: 1.8rem !important;
        padding-left: 1.8rem !important;
    }

    .px-sm-5 {
        padding-right: 2.5rem !important;
        padding-left: 2.5rem !important;
    }

    .px-sm-6 {
        padding-right: 3rem !important;
        padding-left: 3rem !important;
    }

    .px-sm-7 {
        padding-right: 4rem !important;
        padding-left: 4rem !important;
    }

    .px-sm-8 {
        padding-right: 5rem !important;
        padding-left: 5rem !important;
    }

    .px-sm-9 {
        padding-right: 7.5rem !important;
        padding-left: 7.5rem !important;
    }

    .px-sm-10 {
        padding-right: 10rem !important;
        padding-left: 10rem !important;
    }

    .px-sm-11 {
        padding-right: 12.5rem !important;
        padding-left: 12.5rem !important;
    }

    .px-sm-12 {
        padding-right: 15rem !important;
        padding-left: 15rem !important;
    }

    .py-sm-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    .py-sm-1 {
        padding-top: 0.25rem !important;
        padding-bottom: 0.25rem !important;
    }

    .py-sm-2 {
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
    }

    .py-sm-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
    }

    .py-sm-4 {
        padding-top: 1.8rem !important;
        padding-bottom: 1.8rem !important;
    }

    .py-sm-5 {
        padding-top: 2.5rem !important;
        padding-bottom: 2.5rem !important;
    }

    .py-sm-6 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important;
    }

    .py-sm-7 {
        padding-top: 4rem !important;
        padding-bottom: 4rem !important;
    }

    .py-sm-8 {
        padding-top: 5rem !important;
        padding-bottom: 5rem !important;
    }

    .py-sm-9 {
        padding-top: 7.5rem !important;
        padding-bottom: 7.5rem !important;
    }

    .py-sm-10 {
        padding-top: 10rem !important;
        padding-bottom: 10rem !important;
    }

    .py-sm-11 {
        padding-top: 12.5rem !important;
        padding-bottom: 12.5rem !important;
    }

    .py-sm-12 {
        padding-top: 15rem !important;
        padding-bottom: 15rem !important;
    }

    .pt-sm-0 {
        padding-top: 0 !important;
    }

    .pt-sm-1 {
        padding-top: 0.25rem !important;
    }

    .pt-sm-2 {
        padding-top: 0.5rem !important;
    }

    .pt-sm-3 {
        padding-top: 1rem !important;
    }

    .pt-sm-4 {
        padding-top: 1.8rem !important;
    }

    .pt-sm-5 {
        padding-top: 2.5rem !important;
    }

    .pt-sm-6 {
        padding-top: 3rem !important;
    }

    .pt-sm-7 {
        padding-top: 4rem !important;
    }

    .pt-sm-8 {
        padding-top: 5rem !important;
    }

    .pt-sm-9 {
        padding-top: 7.5rem !important;
    }

    .pt-sm-10 {
        padding-top: 10rem !important;
    }

    .pt-sm-11 {
        padding-top: 12.5rem !important;
    }

    .pt-sm-12 {
        padding-top: 15rem !important;
    }

    .pe-sm-0 {
        padding-right: 0 !important;
    }

    .pe-sm-1 {
        padding-right: 0.25rem !important;
    }

    .pe-sm-2 {
        padding-right: 0.5rem !important;
    }

    .pe-sm-3 {
        padding-right: 1rem !important;
    }

    .pe-sm-4 {
        padding-right: 1.8rem !important;
    }

    .pe-sm-5 {
        padding-right: 2.5rem !important;
    }

    .pe-sm-6 {
        padding-right: 3rem !important;
    }

    .pe-sm-7 {
        padding-right: 4rem !important;
    }

    .pe-sm-8 {
        padding-right: 5rem !important;
    }

    .pe-sm-9 {
        padding-right: 7.5rem !important;
    }

    .pe-sm-10 {
        padding-right: 10rem !important;
    }

    .pe-sm-11 {
        padding-right: 12.5rem !important;
    }

    .pe-sm-12 {
        padding-right: 15rem !important;
    }

    .pb-sm-0 {
        padding-bottom: 0 !important;
    }

    .pb-sm-1 {
        padding-bottom: 0.25rem !important;
    }

    .pb-sm-2 {
        padding-bottom: 0.5rem !important;
    }

    .pb-sm-3 {
        padding-bottom: 1rem !important;
    }

    .pb-sm-4 {
        padding-bottom: 1.8rem !important;
    }

    .pb-sm-5 {
        padding-bottom: 2.5rem !important;
    }

    .pb-sm-6 {
        padding-bottom: 3rem !important;
    }

    .pb-sm-7 {
        padding-bottom: 4rem !important;
    }

    .pb-sm-8 {
        padding-bottom: 5rem !important;
    }

    .pb-sm-9 {
        padding-bottom: 7.5rem !important;
    }

    .pb-sm-10 {
        padding-bottom: 10rem !important;
    }

    .pb-sm-11 {
        padding-bottom: 12.5rem !important;
    }

    .pb-sm-12 {
        padding-bottom: 15rem !important;
    }

    .ps-sm-0 {
        padding-left: 0 !important;
    }

    .ps-sm-1 {
        padding-left: 0.25rem !important;
    }

    .ps-sm-2 {
        padding-left: 0.5rem !important;
    }

    .ps-sm-3 {
        padding-left: 1rem !important;
    }

    .ps-sm-4 {
        padding-left: 1.8rem !important;
    }

    .ps-sm-5 {
        padding-left: 2.5rem !important;
    }

    .ps-sm-6 {
        padding-left: 3rem !important;
    }

    .ps-sm-7 {
        padding-left: 4rem !important;
    }

    .ps-sm-8 {
        padding-left: 5rem !important;
    }

    .ps-sm-9 {
        padding-left: 7.5rem !important;
    }

    .ps-sm-10 {
        padding-left: 10rem !important;
    }

    .ps-sm-11 {
        padding-left: 12.5rem !important;
    }

    .ps-sm-12 {
        padding-left: 15rem !important;
    }

    .fs-sm--2 {
        font-size: 0.79012rem !important;
    }

    .fs-sm--1 {
        font-size: 0.88889rem !important;
    }

    .fs-sm-0 {
        font-size: 1rem !important;
    }

    .fs-sm-1 {
        font-size: 1.125rem !important;
    }

    .fs-sm-2 {
        font-size: 1.26563rem !important;
    }

    .fs-sm-3 {
        font-size: 1.42383rem !important;
    }

    .fs-sm-4 {
        font-size: 1.60181rem !important;
    }

    .fs-sm-5 {
        font-size: 1.80203rem !important;
    }

    .fs-sm-6 {
        font-size: 2.02729rem !important;
    }

    .fs-sm-7 {
        font-size: 2.2807rem !important;
    }

    .fs-sm-8 {
        font-size: 2.56578rem !important;
    }

    .fs-sm-9 {
        font-size: 2.88651rem !important;
    }

    .fs-sm-10 {
        font-size: 3.24732rem !important;
    }

    .fs-sm-11 {
        font-size: 3.65324rem !important;
    }

    .fs-sm-12 {
        font-size: 4.10989rem !important;
    }

    .fs-sm-13 {
        font-size: 4.62363rem !important;
    }

    .fs-sm-14 {
        font-size: 5.20158rem !important;
    }

    .lh-sm-unset {
        line-height: unset !important;
    }

    .lh-sm-1 {
        line-height: 1 !important;
    }

    .lh-sm-1-3 {
        line-height: 1.3 !important;
    }

    .lh-sm-1-4 {
        line-height: 1.4 !important;
    }

    .lh-sm-1-5 {
        line-height: 1.5 !important;
    }

    .lh-sm-1-6 {
        line-height: 1.6 !important;
    }

    .lh-sm-1-7 {
        line-height: 1.7 !important;
    }

    .lh-sm-1-8 {
        line-height: 1.8 !important;
    }

    .lh-sm-1-9 {
        line-height: 1.9 !important;
    }

    .lh-sm-2 {
        line-height: 2 !important;
    }

    .text-sm-start {
        text-align: left !important;
    }

    .text-sm-end {
        text-align: right !important;
    }

    .text-sm-center {
        text-align: center !important;
    }

    .rounded-sm-top {
        border-top-left-radius: 1.5rem !important;
        border-top-right-radius: 1.5rem !important;
    }

    .rounded-sm-top-lg {
        border-top-left-radius: 2.25rem !important;
        border-top-right-radius: 2.25rem !important;
    }

    .rounded-sm-top-0 {
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
    }

    .rounded-sm-end {
        border-top-right-radius: 1.5rem !important;
        border-bottom-right-radius: 1.5rem !important;
    }

    .rounded-sm-end-lg {
        border-top-right-radius: 2.25rem !important;
        border-bottom-right-radius: 2.25rem !important;
    }

    .rounded-sm-end-0 {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }

    .rounded-sm-bottom {
        border-bottom-right-radius: 1.5rem !important;
        border-bottom-left-radius: 1.5rem !important;
    }

    .rounded-sm-bottom-lg {
        border-bottom-right-radius: 2.25rem !important;
        border-bottom-left-radius: 2.25rem !important;
    }

    .rounded-sm-bottom-0 {
        border-bottom-right-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }

    .rounded-sm-start {
        border-bottom-left-radius: 1.5rem !important;
        border-top-left-radius: 1.5rem !important;
    }

    .rounded-sm-start-lg {
        border-bottom-left-radius: 2.25rem !important;
        border-top-left-radius: 2.25rem !important;
    }

    .rounded-sm-start-0 {
        border-bottom-left-radius: 0 !important;
        border-top-left-radius: 0 !important;
    }

    .opacity-sm-0 {
        opacity: 0 !important;
    }

    .opacity-sm-25 {
        opacity: 0.25 !important;
    }

    .opacity-sm-50 {
        opacity: 0.5 !important;
    }

    .opacity-sm-75 {
        opacity: 0.75 !important;
    }

    .opacity-sm-85 {
        opacity: 0.85 !important;
    }

    .opacity-sm-100 {
        opacity: 1 !important;
    }

    .max-vh-sm-25 {
        max-height: 25vh !important;
    }

    .max-vh-sm-50 {
        max-height: 50vh !important;
    }

    .max-vh-sm-75 {
        max-height: 75vh !important;
    }

    .max-vh-sm-100 {
        max-height: 100vh !important;
    }

    .border-sm-x {
        border-left: 1px solid #eeeeee !important;
        border-right: 1px solid #eeeeee !important;
    }

    .border-sm-x-0 {
        border-left: 0 !important;
        border-right: 0 !important;
    }

    .border-sm-y {
        border-top: 1px solid #eeeeee !important;
        border-bottom: 1px solid #eeeeee !important;
    }

    .border-sm-y-0 {
        border-top: 0 !important;
        border-bottom: 0 !important;
    }

    .border-sm-dashed {
        border: 1px dashed #eeeeee !important;
    }

    .border-sm-dashed-top {
        border-top: 1px dashed #eeeeee !important;
    }

    .border-sm-dashed-end {
        border-right: 1px dashed #eeeeee !important;
    }

    .border-sm-dashed-start {
        border-left: 1px dashed #eeeeee !important;
    }

    .border-sm-dashed-bottom {
        border-bottom: 1px dashed #eeeeee !important;
    }

    .border-sm-dashed-x {
        border-left: 1px dashed #eeeeee !important;
        border-right: 1px dashed #eeeeee !important;
    }

    .border-sm-dashed-y {
        border-top: 1px dashed #eeeeee !important;
        border-bottom: 1px dashed #eeeeee !important;
    }

    .rounded-sm-0 {
        border-radius: 0 !important;
    }
}

@media (min-width: 768px) {
    .float-md-start {
        float: left !important;
    }

    .float-md-end {
        float: right !important;
    }

    .float-md-none {
        float: none !important;
    }

    .overflow-md-unset {
        overflow: unset !important;
    }

    .overflow-md-auto {
        overflow: auto !important;
    }

    .overflow-md-hidden {
        overflow: hidden !important;
    }

    .overflow-md-visible {
        overflow: visible !important;
    }

    .overflow-md-scroll {
        overflow: scroll !important;
    }

    .d-md-inline {
        display: inline !important;
    }

    .d-md-inline-block {
        display: inline-block !important;
    }

    .d-md-block {
        display: block !important;
    }

    .d-md-grid {
        display: grid !important;
    }

    .d-md-table {
        display: table !important;
    }

    .d-md-table-row {
        display: table-row !important;
    }

    .d-md-table-cell {
        display: table-cell !important;
    }

    .d-md-flex {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
    }

    .d-md-inline-flex {
        display: -webkit-inline-box !important;
        display: -ms-inline-flexbox !important;
        display: inline-flex !important;
    }

    .d-md-none {
        display: none !important;
    }

    .position-md-static {
        position: static !important;
    }

    .position-md-absolute {
        position: absolute !important;
    }

    .position-md-relative {
        position: relative !important;
    }

    .position-md-fixed {
        position: fixed !important;
    }

    .position-md-sticky {
        position: -webkit-sticky !important;
        position: sticky !important;
    }

    .top-md-unset {
        top: unset !important;
    }

    .top-md-0 {
        top: 0 !important;
    }

    .top-md-50 {
        top: 50% !important;
    }

    .top-md-100 {
        top: 100% !important;
    }

    .bottom-md-unset {
        bottom: unset !important;
    }

    .bottom-md-0 {
        bottom: 0 !important;
    }

    .bottom-md-50 {
        bottom: 50% !important;
    }

    .bottom-md-100 {
        bottom: 100% !important;
    }

    .start-md-unset {
        left: unset !important;
    }

    .start-md-0 {
        left: 0 !important;
    }

    .start-md-50 {
        left: 50% !important;
    }

    .start-md-100 {
        left: 100% !important;
    }

    .end-md-unset {
        right: unset !important;
    }

    .end-md-0 {
        right: 0 !important;
    }

    .end-md-50 {
        right: 50% !important;
    }

    .end-md-100 {
        right: 100% !important;
    }

    .translate-md-middle {
        -webkit-transform: translateX(-50%) translateY(-50%) !important;
        -ms-transform: translateX(-50%) translateY(-50%) !important;
        transform: translateX(-50%) translateY(-50%) !important;
    }

    .translate-md-middle-x {
        -webkit-transform: translateX(-50%) !important;
        -ms-transform: translateX(-50%) !important;
        transform: translateX(-50%) !important;
    }

    .translate-md-middle-y {
        -webkit-transform: translateY(-50%) !important;
        -ms-transform: translateY(-50%) !important;
        transform: translateY(-50%) !important;
    }

    .border-md {
        border: 1px solid #eeeeee !important;
    }

    .border-md-0 {
        border: 0 !important;
    }

    .border-md-top {
        border-top: 1px solid #eeeeee !important;
    }

    .border-md-top-0 {
        border-top: 0 !important;
    }

    .border-md-end {
        border-right: 1px solid #eeeeee !important;
    }

    .border-md-end-0 {
        border-right: 0 !important;
    }

    .border-md-bottom {
        border-bottom: 1px solid #eeeeee !important;
    }

    .border-md-bottom-0 {
        border-bottom: 0 !important;
    }

    .border-md-start {
        border-left: 1px solid #eeeeee !important;
    }

    .border-md-start-0 {
        border-left: 0 !important;
    }

    .border-md-facebook {
        border-color: #3c5a99 !important;
    }

    .border-md-google-plus {
        border-color: #dd4b39 !important;
    }

    .border-md-twitter {
        border-color: #1da1f2 !important;
    }

    .border-md-whatsapp {
        border-color: #1da1f2 !important;
    }

    .border-md-linkedin {
        border-color: #0077b5 !important;
    }

    .border-md-youtube {
        border-color: #ff0000 !important;
    }

    .border-md-github {
        border-color: #333 !important;
    }

    .border-md-black {
        border-color: #000 !important;
    }

    .border-md-100 {
        border-color: #fcfcfc !important;
    }

    .border-md-200 {
        border-color: #f5f5f5 !important;
    }

    .border-md-300 {
        border-color: #eeeeee !important;
    }

    .border-md-400 {
        border-color: #e0e0e0 !important;
    }

    .border-md-500 {
        border-color: #bdbdbd !important;
    }

    .border-md-600 {
        border-color: #f0f4f9 !important;
    }

    .border-md-700 {
        border-color: #757575 !important;
    }

    .border-md-800 {
        border-color: #616161 !important;
    }

    .border-md-900 {
        border-color: #5e6282 !important;
    }

    .border-md-1000 {
        border-color: #212832 !important;
    }

    .border-md-white {
        border-color: #fffefe !important;
    }

    .border-md-primary {
        border-color: #f1a501 !important;
    }

    .border-md-secondary {
        border-color: #5e6282 !important;
    }

    .border-md-success {
        border-color: #79b93c !important;
    }

    .border-md-info {
        border-color: #006380 !important;
    }

    .border-md-warning {
        border-color: #f1a501 !important;
    }

    .border-md-danger {
        border-color: #df6951 !important;
    }

    .border-md-light {
        border-color: #f0f4f9 !important;
    }

    .border-md-dark {
        border-color: #212832 !important;
    }

    .w-md-25 {
        width: 25% !important;
    }

    .w-md-50 {
        width: 50% !important;
    }

    .w-md-75 {
        width: 75% !important;
    }

    .w-md-100 {
        width: 100% !important;
    }

    .w-md-auto {
        width: auto !important;
    }

    .vw-md-25 {
        width: 25vw !important;
    }

    .vw-md-50 {
        width: 50vw !important;
    }

    .vw-md-75 {
        width: 75vw !important;
    }

    .vw-md-100 {
        width: 100vw !important;
    }

    .h-md-25 {
        height: 25% !important;
    }

    .h-md-50 {
        height: 50% !important;
    }

    .h-md-75 {
        height: 75% !important;
    }

    .h-md-100 {
        height: 100% !important;
    }

    .h-md-auto {
        height: auto !important;
    }

    .vh-md-25 {
        height: 25vh !important;
    }

    .vh-md-50 {
        height: 50vh !important;
    }

    .vh-md-75 {
        height: 75vh !important;
    }

    .vh-md-100 {
        height: 100vh !important;
    }

    .min-vh-md-25 {
        min-height: 25vh !important;
    }

    .min-vh-md-50 {
        min-height: 50vh !important;
    }

    .min-vh-md-75 {
        min-height: 75vh !important;
    }

    .min-vh-md-100 {
        min-height: 100vh !important;
    }

    .flex-md-fill {
        -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important;
    }

    .flex-md-row {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
        flex-direction: row !important;
    }

    .flex-md-column {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
        flex-direction: column !important;
    }

    .flex-md-row-reverse {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important;
    }

    .flex-md-column-reverse {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important;
    }

    .flex-md-grow-0 {
        -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
        flex-grow: 0 !important;
    }

    .flex-md-grow-1 {
        -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
        flex-grow: 1 !important;
    }

    .flex-md-shrink-0 {
        -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
    }

    .flex-md-shrink-1 {
        -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
    }

    .flex-md-wrap {
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
    }

    .flex-md-nowrap {
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
    }

    .flex-md-wrap-reverse {
        -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
    }

    .gap-md-0 {
        gap: 0 !important;
    }

    .gap-md-1 {
        gap: 0.25rem !important;
    }

    .gap-md-2 {
        gap: 0.5rem !important;
    }

    .gap-md-3 {
        gap: 1rem !important;
    }

    .gap-md-4 {
        gap: 1.8rem !important;
    }

    .gap-md-5 {
        gap: 2.5rem !important;
    }

    .gap-md-6 {
        gap: 3rem !important;
    }

    .gap-md-7 {
        gap: 4rem !important;
    }

    .gap-md-8 {
        gap: 5rem !important;
    }

    .gap-md-9 {
        gap: 7.5rem !important;
    }

    .gap-md-10 {
        gap: 10rem !important;
    }

    .gap-md-11 {
        gap: 12.5rem !important;
    }

    .gap-md-12 {
        gap: 15rem !important;
    }

    .justify-content-md-start {
        -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
        justify-content: flex-start !important;
    }

    .justify-content-md-end {
        -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
        justify-content: flex-end !important;
    }

    .justify-content-md-center {
        -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
        justify-content: center !important;
    }

    .justify-content-md-between {
        -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
        justify-content: space-between !important;
    }

    .justify-content-md-around {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
    }

    .justify-content-md-evenly {
        -webkit-box-pack: space-evenly !important;
        -ms-flex-pack: space-evenly !important;
        justify-content: space-evenly !important;
    }

    .align-items-md-start {
        -webkit-box-align: start !important;
        -ms-flex-align: start !important;
        align-items: flex-start !important;
    }

    .align-items-md-end {
        -webkit-box-align: end !important;
        -ms-flex-align: end !important;
        align-items: flex-end !important;
    }

    .align-items-md-center {
        -webkit-box-align: center !important;
        -ms-flex-align: center !important;
        align-items: center !important;
    }

    .align-items-md-baseline {
        -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
        align-items: baseline !important;
    }

    .align-items-md-stretch {
        -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
        align-items: stretch !important;
    }

    .align-content-md-start {
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
    }

    .align-content-md-end {
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
    }

    .align-content-md-center {
        -ms-flex-line-pack: center !important;
        align-content: center !important;
    }

    .align-content-md-between {
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
    }

    .align-content-md-around {
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
    }

    .align-content-md-stretch {
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
    }

    .align-self-md-auto {
        -ms-flex-item-align: auto !important;
        align-self: auto !important;
    }

    .align-self-md-start {
        -ms-flex-item-align: start !important;
        align-self: flex-start !important;
    }

    .align-self-md-end {
        -ms-flex-item-align: end !important;
        align-self: flex-end !important;
    }

    .align-self-md-center {
        -ms-flex-item-align: center !important;
        align-self: center !important;
    }

    .align-self-md-baseline {
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
    }

    .align-self-md-stretch {
        -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
    }

    .order-md-first {
        -webkit-box-ordinal-group: 0 !important;
        -ms-flex-order: -1 !important;
        order: -1 !important;
    }

    .order-md-0 {
        -webkit-box-ordinal-group: 1 !important;
        -ms-flex-order: 0 !important;
        order: 0 !important;
    }

    .order-md-1 {
        -webkit-box-ordinal-group: 2 !important;
        -ms-flex-order: 1 !important;
        order: 1 !important;
    }

    .order-md-2 {
        -webkit-box-ordinal-group: 3 !important;
        -ms-flex-order: 2 !important;
        order: 2 !important;
    }

    .order-md-3 {
        -webkit-box-ordinal-group: 4 !important;
        -ms-flex-order: 3 !important;
        order: 3 !important;
    }

    .order-md-4 {
        -webkit-box-ordinal-group: 5 !important;
        -ms-flex-order: 4 !important;
        order: 4 !important;
    }

    .order-md-5 {
        -webkit-box-ordinal-group: 6 !important;
        -ms-flex-order: 5 !important;
        order: 5 !important;
    }

    .order-md-last {
        -webkit-box-ordinal-group: 7 !important;
        -ms-flex-order: 6 !important;
        order: 6 !important;
    }

    .m-md-0 {
        margin: 0 !important;
    }

    .m-md-1 {
        margin: 0.25rem !important;
    }

    .m-md-2 {
        margin: 0.5rem !important;
    }

    .m-md-3 {
        margin: 1rem !important;
    }

    .m-md-4 {
        margin: 1.8rem !important;
    }

    .m-md-5 {
        margin: 2.5rem !important;
    }

    .m-md-6 {
        margin: 3rem !important;
    }

    .m-md-7 {
        margin: 4rem !important;
    }

    .m-md-8 {
        margin: 5rem !important;
    }

    .m-md-9 {
        margin: 7.5rem !important;
    }

    .m-md-10 {
        margin: 10rem !important;
    }

    .m-md-11 {
        margin: 12.5rem !important;
    }

    .m-md-12 {
        margin: 15rem !important;
    }

    .m-md-auto {
        margin: auto !important;
    }

    .mx-md-0 {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }

    .mx-md-1 {
        margin-right: 0.25rem !important;
        margin-left: 0.25rem !important;
    }

    .mx-md-2 {
        margin-right: 0.5rem !important;
        margin-left: 0.5rem !important;
    }

    .mx-md-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important;
    }

    .mx-md-4 {
        margin-right: 1.8rem !important;
        margin-left: 1.8rem !important;
    }

    .mx-md-5 {
        margin-right: 2.5rem !important;
        margin-left: 2.5rem !important;
    }

    .mx-md-6 {
        margin-right: 3rem !important;
        margin-left: 3rem !important;
    }

    .mx-md-7 {
        margin-right: 4rem !important;
        margin-left: 4rem !important;
    }

    .mx-md-8 {
        margin-right: 5rem !important;
        margin-left: 5rem !important;
    }

    .mx-md-9 {
        margin-right: 7.5rem !important;
        margin-left: 7.5rem !important;
    }

    .mx-md-10 {
        margin-right: 10rem !important;
        margin-left: 10rem !important;
    }

    .mx-md-11 {
        margin-right: 12.5rem !important;
        margin-left: 12.5rem !important;
    }

    .mx-md-12 {
        margin-right: 15rem !important;
        margin-left: 15rem !important;
    }

    .mx-md-auto {
        margin-right: auto !important;
        margin-left: auto !important;
    }

    .my-md-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }

    .my-md-1 {
        margin-top: 0.25rem !important;
        margin-bottom: 0.25rem !important;
    }

    .my-md-2 {
        margin-top: 0.5rem !important;
        margin-bottom: 0.5rem !important;
    }

    .my-md-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
    }

    .my-md-4 {
        margin-top: 1.8rem !important;
        margin-bottom: 1.8rem !important;
    }

    .my-md-5 {
        margin-top: 2.5rem !important;
        margin-bottom: 2.5rem !important;
    }

    .my-md-6 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important;
    }

    .my-md-7 {
        margin-top: 4rem !important;
        margin-bottom: 4rem !important;
    }

    .my-md-8 {
        margin-top: 5rem !important;
        margin-bottom: 5rem !important;
    }

    .my-md-9 {
        margin-top: 7.5rem !important;
        margin-bottom: 7.5rem !important;
    }

    .my-md-10 {
        margin-top: 10rem !important;
        margin-bottom: 10rem !important;
    }

    .my-md-11 {
        margin-top: 12.5rem !important;
        margin-bottom: 12.5rem !important;
    }

    .my-md-12 {
        margin-top: 15rem !important;
        margin-bottom: 15rem !important;
    }

    .my-md-auto {
        margin-top: auto !important;
        margin-bottom: auto !important;
    }

    .mt-md-0 {
        margin-top: 0 !important;
    }

    .mt-md-1 {
        margin-top: 0.25rem !important;
    }

    .mt-md-2 {
        margin-top: 0.5rem !important;
    }

    .mt-md-3 {
        margin-top: 1rem !important;
    }

    .mt-md-4 {
        margin-top: 1.8rem !important;
    }

    .mt-md-5 {
        margin-top: 2.5rem !important;
    }

    .mt-md-6 {
        margin-top: 3rem !important;
    }

    .mt-md-7 {
        margin-top: 4rem !important;
    }

    .mt-md-8 {
        margin-top: 5rem !important;
    }

    .mt-md-9 {
        margin-top: 7.5rem !important;
    }

    .mt-md-10 {
        margin-top: 10rem !important;
    }

    .mt-md-11 {
        margin-top: 12.5rem !important;
    }

    .mt-md-12 {
        margin-top: 15rem !important;
    }

    .mt-md-auto {
        margin-top: auto !important;
    }

    .me-md-0 {
        margin-right: 0 !important;
    }

    .me-md-1 {
        margin-right: 0.25rem !important;
    }

    .me-md-2 {
        margin-right: 0.5rem !important;
    }

    .me-md-3 {
        margin-right: 1rem !important;
    }

    .me-md-4 {
        margin-right: 1.8rem !important;
    }

    .me-md-5 {
        margin-right: 2.5rem !important;
    }

    .me-md-6 {
        margin-right: 3rem !important;
    }

    .me-md-7 {
        margin-right: 4rem !important;
    }

    .me-md-8 {
        margin-right: 5rem !important;
    }

    .me-md-9 {
        margin-right: 7.5rem !important;
    }

    .me-md-10 {
        margin-right: 10rem !important;
    }

    .me-md-11 {
        margin-right: 12.5rem !important;
    }

    .me-md-12 {
        margin-right: 15rem !important;
    }

    .me-md-auto {
        margin-right: auto !important;
    }

    .mb-md-0 {
        margin-bottom: 0 !important;
    }

    .mb-md-1 {
        margin-bottom: 0.25rem !important;
    }

    .mb-md-2 {
        margin-bottom: 0.5rem !important;
    }

    .mb-md-3 {
        margin-bottom: 1rem !important;
    }

    .mb-md-4 {
        margin-bottom: 1.8rem !important;
    }

    .mb-md-5 {
        margin-bottom: 2.5rem !important;
    }

    .mb-md-6 {
        margin-bottom: 3rem !important;
    }

    .mb-md-7 {
        margin-bottom: 4rem !important;
    }

    .mb-md-8 {
        margin-bottom: 5rem !important;
    }

    .mb-md-9 {
        margin-bottom: 7.5rem !important;
    }

    .mb-md-10 {
        margin-bottom: 10rem !important;
    }

    .mb-md-11 {
        margin-bottom: 12.5rem !important;
    }

    .mb-md-12 {
        margin-bottom: 15rem !important;
    }

    .mb-md-auto {
        margin-bottom: auto !important;
    }

    .ms-md-0 {
        margin-left: 0 !important;
    }

    .ms-md-1 {
        margin-left: 0.25rem !important;
    }

    .ms-md-2 {
        margin-left: 0.5rem !important;
    }

    .ms-md-3 {
        margin-left: 1rem !important;
    }

    .ms-md-4 {
        margin-left: 1.8rem !important;
    }

    .ms-md-5 {
        margin-left: 2.5rem !important;
    }

    .ms-md-6 {
        margin-left: 3rem !important;
    }

    .ms-md-7 {
        margin-left: 4rem !important;
    }

    .ms-md-8 {
        margin-left: 5rem !important;
    }

    .ms-md-9 {
        margin-left: 7.5rem !important;
    }

    .ms-md-10 {
        margin-left: 10rem !important;
    }

    .ms-md-11 {
        margin-left: 12.5rem !important;
    }

    .ms-md-12 {
        margin-left: 15rem !important;
    }

    .ms-md-auto {
        margin-left: auto !important;
    }

    .m-md-n1 {
        margin: -0.25rem !important;
    }

    .m-md-n2 {
        margin: -0.5rem !important;
    }

    .m-md-n3 {
        margin: -1rem !important;
    }

    .m-md-n4 {
        margin: -1.8rem !important;
    }

    .m-md-n5 {
        margin: -2.5rem !important;
    }

    .m-md-n6 {
        margin: -3rem !important;
    }

    .m-md-n7 {
        margin: -4rem !important;
    }

    .m-md-n8 {
        margin: -5rem !important;
    }

    .m-md-n9 {
        margin: -7.5rem !important;
    }

    .m-md-n10 {
        margin: -10rem !important;
    }

    .m-md-n11 {
        margin: -12.5rem !important;
    }

    .m-md-n12 {
        margin: -15rem !important;
    }

    .mx-md-n1 {
        margin-right: -0.25rem !important;
        margin-left: -0.25rem !important;
    }

    .mx-md-n2 {
        margin-right: -0.5rem !important;
        margin-left: -0.5rem !important;
    }

    .mx-md-n3 {
        margin-right: -1rem !important;
        margin-left: -1rem !important;
    }

    .mx-md-n4 {
        margin-right: -1.8rem !important;
        margin-left: -1.8rem !important;
    }

    .mx-md-n5 {
        margin-right: -2.5rem !important;
        margin-left: -2.5rem !important;
    }

    .mx-md-n6 {
        margin-right: -3rem !important;
        margin-left: -3rem !important;
    }

    .mx-md-n7 {
        margin-right: -4rem !important;
        margin-left: -4rem !important;
    }

    .mx-md-n8 {
        margin-right: -5rem !important;
        margin-left: -5rem !important;
    }

    .mx-md-n9 {
        margin-right: -7.5rem !important;
        margin-left: -7.5rem !important;
    }

    .mx-md-n10 {
        margin-right: -10rem !important;
        margin-left: -10rem !important;
    }

    .mx-md-n11 {
        margin-right: -12.5rem !important;
        margin-left: -12.5rem !important;
    }

    .mx-md-n12 {
        margin-right: -15rem !important;
        margin-left: -15rem !important;
    }

    .my-md-n1 {
        margin-top: -0.25rem !important;
        margin-bottom: -0.25rem !important;
    }

    .my-md-n2 {
        margin-top: -0.5rem !important;
        margin-bottom: -0.5rem !important;
    }

    .my-md-n3 {
        margin-top: -1rem !important;
        margin-bottom: -1rem !important;
    }

    .my-md-n4 {
        margin-top: -1.8rem !important;
        margin-bottom: -1.8rem !important;
    }

    .my-md-n5 {
        margin-top: -2.5rem !important;
        margin-bottom: -2.5rem !important;
    }

    .my-md-n6 {
        margin-top: -3rem !important;
        margin-bottom: -3rem !important;
    }

    .my-md-n7 {
        margin-top: -4rem !important;
        margin-bottom: -4rem !important;
    }

    .my-md-n8 {
        margin-top: -5rem !important;
        margin-bottom: -5rem !important;
    }

    .my-md-n9 {
        margin-top: -7.5rem !important;
        margin-bottom: -7.5rem !important;
    }

    .my-md-n10 {
        margin-top: -10rem !important;
        margin-bottom: -10rem !important;
    }

    .my-md-n11 {
        margin-top: -12.5rem !important;
        margin-bottom: -12.5rem !important;
    }

    .my-md-n12 {
        margin-top: -15rem !important;
        margin-bottom: -15rem !important;
    }

    .mt-md-n1 {
        margin-top: -0.25rem !important;
    }

    .mt-md-n2 {
        margin-top: -0.5rem !important;
    }

    .mt-md-n3 {
        margin-top: -1rem !important;
    }

    .mt-md-n4 {
        margin-top: -1.8rem !important;
    }

    .mt-md-n5 {
        margin-top: -2.5rem !important;
    }

    .mt-md-n6 {
        margin-top: -3rem !important;
    }

    .mt-md-n7 {
        margin-top: -4rem !important;
    }

    .mt-md-n8 {
        margin-top: -5rem !important;
    }

    .mt-md-n9 {
        margin-top: -7.5rem !important;
    }

    .mt-md-n10 {
        margin-top: -10rem !important;
    }

    .mt-md-n11 {
        margin-top: -12.5rem !important;
    }

    .mt-md-n12 {
        margin-top: -15rem !important;
    }

    .me-md-n1 {
        margin-right: -0.25rem !important;
    }

    .me-md-n2 {
        margin-right: -0.5rem !important;
    }

    .me-md-n3 {
        margin-right: -1rem !important;
    }

    .me-md-n4 {
        margin-right: -1.8rem !important;
    }

    .me-md-n5 {
        margin-right: -2.5rem !important;
    }

    .me-md-n6 {
        margin-right: -3rem !important;
    }

    .me-md-n7 {
        margin-right: -4rem !important;
    }

    .me-md-n8 {
        margin-right: -5rem !important;
    }

    .me-md-n9 {
        margin-right: -7.5rem !important;
    }

    .me-md-n10 {
        margin-right: -10rem !important;
    }

    .me-md-n11 {
        margin-right: -12.5rem !important;
    }

    .me-md-n12 {
        margin-right: -15rem !important;
    }

    .mb-md-n1 {
        margin-bottom: -0.25rem !important;
    }

    .mb-md-n2 {
        margin-bottom: -0.5rem !important;
    }

    .mb-md-n3 {
        margin-bottom: -1rem !important;
    }

    .mb-md-n4 {
        margin-bottom: -1.8rem !important;
    }

    .mb-md-n5 {
        margin-bottom: -2.5rem !important;
    }

    .mb-md-n6 {
        margin-bottom: -3rem !important;
    }

    .mb-md-n7 {
        margin-bottom: -4rem !important;
    }

    .mb-md-n8 {
        margin-bottom: -5rem !important;
    }

    .mb-md-n9 {
        margin-bottom: -7.5rem !important;
    }

    .mb-md-n10 {
        margin-bottom: -10rem !important;
    }

    .mb-md-n11 {
        margin-bottom: -12.5rem !important;
    }

    .mb-md-n12 {
        margin-bottom: -15rem !important;
    }

    .ms-md-n1 {
        margin-left: -0.25rem !important;
    }

    .ms-md-n2 {
        margin-left: -0.5rem !important;
    }

    .ms-md-n3 {
        margin-left: -1rem !important;
    }

    .ms-md-n4 {
        margin-left: -1.8rem !important;
    }

    .ms-md-n5 {
        margin-left: -2.5rem !important;
    }

    .ms-md-n6 {
        margin-left: -3rem !important;
    }

    .ms-md-n7 {
        margin-left: -4rem !important;
    }

    .ms-md-n8 {
        margin-left: -5rem !important;
    }

    .ms-md-n9 {
        margin-left: -7.5rem !important;
    }

    .ms-md-n10 {
        margin-left: -10rem !important;
    }

    .ms-md-n11 {
        margin-left: -12.5rem !important;
    }

    .ms-md-n12 {
        margin-left: -15rem !important;
    }

    .p-md-0 {
        padding: 0 !important;
    }

    .p-md-1 {
        padding: 0.25rem !important;
    }

    .p-md-2 {
        padding: 0.5rem !important;
    }

    .p-md-3 {
        padding: 1rem !important;
    }

    .p-md-4 {
        padding: 1.8rem !important;
    }

    .p-md-5 {
        padding: 2.5rem !important;
    }

    .p-md-6 {
        padding: 3rem !important;
    }

    .p-md-7 {
        padding: 4rem !important;
    }

    .p-md-8 {
        padding: 5rem !important;
    }

    .p-md-9 {
        padding: 7.5rem !important;
    }

    .p-md-10 {
        padding: 10rem !important;
    }

    .p-md-11 {
        padding: 12.5rem !important;
    }

    .p-md-12 {
        padding: 15rem !important;
    }

    .px-md-0 {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }

    .px-md-1 {
        padding-right: 0.25rem !important;
        padding-left: 0.25rem !important;
    }

    .px-md-2 {
        padding-right: 0.5rem !important;
        padding-left: 0.5rem !important;
    }

    .px-md-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    }

    .px-md-4 {
        padding-right: 1.8rem !important;
        padding-left: 1.8rem !important;
    }

    .px-md-5 {
        padding-right: 2.5rem !important;
        padding-left: 2.5rem !important;
    }

    .px-md-6 {
        padding-right: 3rem !important;
        padding-left: 3rem !important;
    }

    .px-md-7 {
        padding-right: 4rem !important;
        padding-left: 4rem !important;
    }

    .px-md-8 {
        padding-right: 5rem !important;
        padding-left: 5rem !important;
    }

    .px-md-9 {
        padding-right: 7.5rem !important;
        padding-left: 7.5rem !important;
    }

    .px-md-10 {
        padding-right: 10rem !important;
        padding-left: 10rem !important;
    }

    .px-md-11 {
        padding-right: 12.5rem !important;
        padding-left: 12.5rem !important;
    }

    .px-md-12 {
        padding-right: 15rem !important;
        padding-left: 15rem !important;
    }

    .py-md-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    .py-md-1 {
        padding-top: 0.25rem !important;
        padding-bottom: 0.25rem !important;
    }

    .py-md-2 {
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
    }

    .py-md-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
    }

    .py-md-4 {
        padding-top: 1.8rem !important;
        padding-bottom: 1.8rem !important;
    }

    .py-md-5 {
        padding-top: 2.5rem !important;
        padding-bottom: 2.5rem !important;
    }

    .py-md-6 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important;
    }

    .py-md-7 {
        padding-top: 4rem !important;
        padding-bottom: 4rem !important;
    }

    .py-md-8 {
        padding-top: 5rem !important;
        padding-bottom: 5rem !important;
    }

    .py-md-9 {
        padding-top: 7.5rem !important;
        padding-bottom: 7.5rem !important;
    }

    .py-md-10 {
        padding-top: 10rem !important;
        padding-bottom: 10rem !important;
    }

    .py-md-11 {
        padding-top: 12.5rem !important;
        padding-bottom: 12.5rem !important;
    }

    .py-md-12 {
        padding-top: 15rem !important;
        padding-bottom: 15rem !important;
    }

    .pt-md-0 {
        padding-top: 0 !important;
    }

    .pt-md-1 {
        padding-top: 0.25rem !important;
    }

    .pt-md-2 {
        padding-top: 0.5rem !important;
    }

    .pt-md-3 {
        padding-top: 1rem !important;
    }

    .pt-md-4 {
        padding-top: 1.8rem !important;
    }

    .pt-md-5 {
        padding-top: 2.5rem !important;
    }

    .pt-md-6 {
        padding-top: 3rem !important;
    }

    .pt-md-7 {
        padding-top: 4rem !important;
    }

    .pt-md-8 {
        padding-top: 5rem !important;
    }

    .pt-md-9 {
        padding-top: 7.5rem !important;
    }

    .pt-md-10 {
        padding-top: 10rem !important;
    }

    .pt-md-11 {
        padding-top: 12.5rem !important;
    }

    .pt-md-12 {
        padding-top: 15rem !important;
    }

    .pe-md-0 {
        padding-right: 0 !important;
    }

    .pe-md-1 {
        padding-right: 0.25rem !important;
    }

    .pe-md-2 {
        padding-right: 0.5rem !important;
    }

    .pe-md-3 {
        padding-right: 1rem !important;
    }

    .pe-md-4 {
        padding-right: 1.8rem !important;
    }

    .pe-md-5 {
        padding-right: 2.5rem !important;
    }

    .pe-md-6 {
        padding-right: 3rem !important;
    }

    .pe-md-7 {
        padding-right: 4rem !important;
    }

    .pe-md-8 {
        padding-right: 5rem !important;
    }

    .pe-md-9 {
        padding-right: 7.5rem !important;
    }

    .pe-md-10 {
        padding-right: 10rem !important;
    }

    .pe-md-11 {
        padding-right: 12.5rem !important;
    }

    .pe-md-12 {
        padding-right: 15rem !important;
    }

    .pb-md-0 {
        padding-bottom: 0 !important;
    }

    .pb-md-1 {
        padding-bottom: 0.25rem !important;
    }

    .pb-md-2 {
        padding-bottom: 0.5rem !important;
    }

    .pb-md-3 {
        padding-bottom: 1rem !important;
    }

    .pb-md-4 {
        padding-bottom: 1.8rem !important;
    }

    .pb-md-5 {
        padding-bottom: 2.5rem !important;
    }

    .pb-md-6 {
        padding-bottom: 3rem !important;
    }

    .pb-md-7 {
        padding-bottom: 4rem !important;
    }

    .pb-md-8 {
        padding-bottom: 5rem !important;
    }

    .pb-md-9 {
        padding-bottom: 7.5rem !important;
    }

    .pb-md-10 {
        padding-bottom: 10rem !important;
    }

    .pb-md-11 {
        padding-bottom: 12.5rem !important;
    }

    .pb-md-12 {
        padding-bottom: 15rem !important;
    }

    .ps-md-0 {
        padding-left: 0 !important;
    }

    .ps-md-1 {
        padding-left: 0.25rem !important;
    }

    .ps-md-2 {
        padding-left: 0.5rem !important;
    }

    .ps-md-3 {
        padding-left: 1rem !important;
    }

    .ps-md-4 {
        padding-left: 1.8rem !important;
    }

    .ps-md-5 {
        padding-left: 2.5rem !important;
    }

    .ps-md-6 {
        padding-left: 3rem !important;
    }

    .ps-md-7 {
        padding-left: 4rem !important;
    }

    .ps-md-8 {
        padding-left: 5rem !important;
    }

    .ps-md-9 {
        padding-left: 7.5rem !important;
    }

    .ps-md-10 {
        padding-left: 10rem !important;
    }

    .ps-md-11 {
        padding-left: 12.5rem !important;
    }

    .ps-md-12 {
        padding-left: 15rem !important;
    }

    .fs-md--2 {
        font-size: 0.79012rem !important;
    }

    .fs-md--1 {
        font-size: 0.88889rem !important;
    }

    .fs-md-0 {
        font-size: 1rem !important;
    }

    .fs-md-1 {
        font-size: 1.125rem !important;
    }

    .fs-md-2 {
        font-size: 1.26563rem !important;
    }

    .fs-md-3 {
        font-size: 1.42383rem !important;
    }

    .fs-md-4 {
        font-size: 1.60181rem !important;
    }

    .fs-md-5 {
        font-size: 1.80203rem !important;
    }

    .fs-md-6 {
        font-size: 2.02729rem !important;
    }

    .fs-md-7 {
        font-size: 2.2807rem !important;
    }

    .fs-md-8 {
        font-size: 2.56578rem !important;
    }

    .fs-md-9 {
        font-size: 2.88651rem !important;
    }

    .fs-md-10 {
        font-size: 3.24732rem !important;
    }

    .fs-md-11 {
        font-size: 3.65324rem !important;
    }

    .fs-md-12 {
        font-size: 4.10989rem !important;
    }

    .fs-md-13 {
        font-size: 4.62363rem !important;
    }

    .fs-md-14 {
        font-size: 5.20158rem !important;
    }

    .lh-md-unset {
        line-height: unset !important;
    }

    .lh-md-1 {
        line-height: 1 !important;
    }

    .lh-md-1-3 {
        line-height: 1.3 !important;
    }

    .lh-md-1-4 {
        line-height: 1.4 !important;
    }

    .lh-md-1-5 {
        line-height: 1.5 !important;
    }

    .lh-md-1-6 {
        line-height: 1.6 !important;
    }

    .lh-md-1-7 {
        line-height: 1.7 !important;
    }

    .lh-md-1-8 {
        line-height: 1.8 !important;
    }

    .lh-md-1-9 {
        line-height: 1.9 !important;
    }

    .lh-md-2 {
        line-height: 2 !important;
    }

    .text-md-start {
        text-align: left !important;
    }

    .text-md-end {
        text-align: right !important;
    }

    .text-md-center {
        text-align: center !important;
    }

    .rounded-md-top {
        border-top-left-radius: 1.5rem !important;
        border-top-right-radius: 1.5rem !important;
    }

    .rounded-md-top-lg {
        border-top-left-radius: 2.25rem !important;
        border-top-right-radius: 2.25rem !important;
    }

    .rounded-md-top-0 {
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
    }

    .rounded-md-end {
        border-top-right-radius: 1.5rem !important;
        border-bottom-right-radius: 1.5rem !important;
    }

    .rounded-md-end-lg {
        border-top-right-radius: 2.25rem !important;
        border-bottom-right-radius: 2.25rem !important;
    }

    .rounded-md-end-0 {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }

    .rounded-md-bottom {
        border-bottom-right-radius: 1.5rem !important;
        border-bottom-left-radius: 1.5rem !important;
    }

    .rounded-md-bottom-lg {
        border-bottom-right-radius: 2.25rem !important;
        border-bottom-left-radius: 2.25rem !important;
    }

    .rounded-md-bottom-0 {
        border-bottom-right-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }

    .rounded-md-start {
        border-bottom-left-radius: 1.5rem !important;
        border-top-left-radius: 1.5rem !important;
    }

    .rounded-md-start-lg {
        border-bottom-left-radius: 2.25rem !important;
        border-top-left-radius: 2.25rem !important;
    }

    .rounded-md-start-0 {
        border-bottom-left-radius: 0 !important;
        border-top-left-radius: 0 !important;
    }

    .opacity-md-0 {
        opacity: 0 !important;
    }

    .opacity-md-25 {
        opacity: 0.25 !important;
    }

    .opacity-md-50 {
        opacity: 0.5 !important;
    }

    .opacity-md-75 {
        opacity: 0.75 !important;
    }

    .opacity-md-85 {
        opacity: 0.85 !important;
    }

    .opacity-md-100 {
        opacity: 1 !important;
    }

    .max-vh-md-25 {
        max-height: 25vh !important;
    }

    .max-vh-md-50 {
        max-height: 50vh !important;
    }

    .max-vh-md-75 {
        max-height: 75vh !important;
    }

    .max-vh-md-100 {
        max-height: 100vh !important;
    }

    .border-md-x {
        border-left: 1px solid #eeeeee !important;
        border-right: 1px solid #eeeeee !important;
    }

    .border-md-x-0 {
        border-left: 0 !important;
        border-right: 0 !important;
    }

    .border-md-y {
        border-top: 1px solid #eeeeee !important;
        border-bottom: 1px solid #eeeeee !important;
    }

    .border-md-y-0 {
        border-top: 0 !important;
        border-bottom: 0 !important;
    }

    .border-md-dashed {
        border: 1px dashed #eeeeee !important;
    }

    .border-md-dashed-top {
        border-top: 1px dashed #eeeeee !important;
    }

    .border-md-dashed-end {
        border-right: 1px dashed #eeeeee !important;
    }

    .border-md-dashed-start {
        border-left: 1px dashed #eeeeee !important;
    }

    .border-md-dashed-bottom {
        border-bottom: 1px dashed #eeeeee !important;
    }

    .border-md-dashed-x {
        border-left: 1px dashed #eeeeee !important;
        border-right: 1px dashed #eeeeee !important;
    }

    .border-md-dashed-y {
        border-top: 1px dashed #eeeeee !important;
        border-bottom: 1px dashed #eeeeee !important;
    }

    .rounded-md-0 {
        border-radius: 0 !important;
    }
}

@media (min-width: 992px) {
    .float-lg-start {
        float: left !important;
    }

    .float-lg-end {
        float: right !important;
    }

    .float-lg-none {
        float: none !important;
    }

    .overflow-lg-unset {
        overflow: unset !important;
    }

    .overflow-lg-auto {
        overflow: auto !important;
    }

    .overflow-lg-hidden {
        overflow: hidden !important;
    }

    .overflow-lg-visible {
        overflow: visible !important;
    }

    .overflow-lg-scroll {
        overflow: scroll !important;
    }

    .d-lg-inline {
        display: inline !important;
    }

    .d-lg-inline-block {
        display: inline-block !important;
    }

    .d-lg-block {
        display: block !important;
    }

    .d-lg-grid {
        display: grid !important;
    }

    .d-lg-table {
        display: table !important;
    }

    .d-lg-table-row {
        display: table-row !important;
    }

    .d-lg-table-cell {
        display: table-cell !important;
    }

    .d-lg-flex {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
    }

    .d-lg-inline-flex {
        display: -webkit-inline-box !important;
        display: -ms-inline-flexbox !important;
        display: inline-flex !important;
    }

    .d-lg-none {
        display: none !important;
    }

    .position-lg-static {
        position: static !important;
    }

    .position-lg-absolute {
        position: absolute !important;
    }

    .position-lg-relative {
        position: relative !important;
    }

    .position-lg-fixed {
        position: fixed !important;
    }

    .position-lg-sticky {
        position: -webkit-sticky !important;
        position: sticky !important;
    }

    .top-lg-unset {
        top: unset !important;
    }

    .top-lg-0 {
        top: 0 !important;
    }

    .top-lg-50 {
        top: 50% !important;
    }

    .top-lg-100 {
        top: 100% !important;
    }

    .bottom-lg-unset {
        bottom: unset !important;
    }

    .bottom-lg-0 {
        bottom: 0 !important;
    }

    .bottom-lg-50 {
        bottom: 50% !important;
    }

    .bottom-lg-100 {
        bottom: 100% !important;
    }

    .start-lg-unset {
        left: unset !important;
    }

    .start-lg-0 {
        left: 0 !important;
    }

    .start-lg-50 {
        left: 50% !important;
    }

    .start-lg-100 {
        left: 100% !important;
    }

    .end-lg-unset {
        right: unset !important;
    }

    .end-lg-0 {
        right: 0 !important;
    }

    .end-lg-50 {
        right: 50% !important;
    }

    .end-lg-100 {
        right: 100% !important;
    }

    .translate-lg-middle {
        -webkit-transform: translateX(-50%) translateY(-50%) !important;
        -ms-transform: translateX(-50%) translateY(-50%) !important;
        transform: translateX(-50%) translateY(-50%) !important;
    }

    .translate-lg-middle-x {
        -webkit-transform: translateX(-50%) !important;
        -ms-transform: translateX(-50%) !important;
        transform: translateX(-50%) !important;
    }

    .translate-lg-middle-y {
        -webkit-transform: translateY(-50%) !important;
        -ms-transform: translateY(-50%) !important;
        transform: translateY(-50%) !important;
    }

    .border-lg {
        border: 1px solid #eeeeee !important;
    }

    .border-lg-0 {
        border: 0 !important;
    }

    .border-lg-top {
        border-top: 1px solid #eeeeee !important;
    }

    .border-lg-top-0 {
        border-top: 0 !important;
    }

    .border-lg-end {
        border-right: 1px solid #eeeeee !important;
    }

    .border-lg-end-0 {
        border-right: 0 !important;
    }

    .border-lg-bottom {
        border-bottom: 1px solid #eeeeee !important;
    }

    .border-lg-bottom-0 {
        border-bottom: 0 !important;
    }

    .border-lg-start {
        border-left: 1px solid #eeeeee !important;
    }

    .border-lg-start-0 {
        border-left: 0 !important;
    }

    .border-lg-facebook {
        border-color: #3c5a99 !important;
    }

    .border-lg-google-plus {
        border-color: #dd4b39 !important;
    }

    .border-lg-twitter {
        border-color: #1da1f2 !important;
    }

    .border-lg-whatsapp {
        border-color: #1da1f2 !important;
    }

    .border-lg-linkedin {
        border-color: #0077b5 !important;
    }

    .border-lg-youtube {
        border-color: #ff0000 !important;
    }

    .border-lg-github {
        border-color: #333 !important;
    }

    .border-lg-black {
        border-color: #000 !important;
    }

    .border-lg-100 {
        border-color: #fcfcfc !important;
    }

    .border-lg-200 {
        border-color: #f5f5f5 !important;
    }

    .border-lg-300 {
        border-color: #eeeeee !important;
    }

    .border-lg-400 {
        border-color: #e0e0e0 !important;
    }

    .border-lg-500 {
        border-color: #bdbdbd !important;
    }

    .border-lg-600 {
        border-color: #f0f4f9 !important;
    }

    .border-lg-700 {
        border-color: #757575 !important;
    }

    .border-lg-800 {
        border-color: #616161 !important;
    }

    .border-lg-900 {
        border-color: #5e6282 !important;
    }

    .border-lg-1000 {
        border-color: #212832 !important;
    }

    .border-lg-white {
        border-color: #fffefe !important;
    }

    .border-lg-primary {
        border-color: #f1a501 !important;
    }

    .border-lg-secondary {
        border-color: #5e6282 !important;
    }

    .border-lg-success {
        border-color: #79b93c !important;
    }

    .border-lg-info {
        border-color: #006380 !important;
    }

    .border-lg-warning {
        border-color: #f1a501 !important;
    }

    .border-lg-danger {
        border-color: #df6951 !important;
    }

    .border-lg-light {
        border-color: #f0f4f9 !important;
    }

    .border-lg-dark {
        border-color: #212832 !important;
    }

    .w-lg-25 {
        width: 25% !important;
    }

    .w-lg-50 {
        width: 50% !important;
    }

    .w-lg-75 {
        width: 75% !important;
    }

    .w-lg-100 {
        width: 100% !important;
    }

    .w-lg-auto {
        width: auto !important;
    }

    .vw-lg-25 {
        width: 25vw !important;
    }

    .vw-lg-50 {
        width: 50vw !important;
    }

    .vw-lg-75 {
        width: 75vw !important;
    }

    .vw-lg-100 {
        width: 100vw !important;
    }

    .h-lg-25 {
        height: 25% !important;
    }

    .h-lg-50 {
        height: 50% !important;
    }

    .h-lg-75 {
        height: 75% !important;
    }

    .h-lg-100 {
        height: 100% !important;
    }

    .h-lg-auto {
        height: auto !important;
    }

    .vh-lg-25 {
        height: 25vh !important;
    }

    .vh-lg-50 {
        height: 50vh !important;
    }

    .vh-lg-75 {
        height: 75vh !important;
    }

    .vh-lg-100 {
        height: 100vh !important;
    }

    .min-vh-lg-25 {
        min-height: 25vh !important;
    }

    .min-vh-lg-50 {
        min-height: 50vh !important;
    }

    .min-vh-lg-75 {
        min-height: 75vh !important;
    }

    .min-vh-lg-100 {
        min-height: 100vh !important;
    }

    .flex-lg-fill {
        -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important;
    }

    .flex-lg-row {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
        flex-direction: row !important;
    }

    .flex-lg-column {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
        flex-direction: column !important;
    }

    .flex-lg-row-reverse {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important;
    }

    .flex-lg-column-reverse {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important;
    }

    .flex-lg-grow-0 {
        -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
        flex-grow: 0 !important;
    }

    .flex-lg-grow-1 {
        -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
        flex-grow: 1 !important;
    }

    .flex-lg-shrink-0 {
        -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
    }

    .flex-lg-shrink-1 {
        -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
    }

    .flex-lg-wrap {
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
    }

    .flex-lg-nowrap {
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
    }

    .flex-lg-wrap-reverse {
        -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
    }

    .gap-lg-0 {
        gap: 0 !important;
    }

    .gap-lg-1 {
        gap: 0.25rem !important;
    }

    .gap-lg-2 {
        gap: 0.5rem !important;
    }

    .gap-lg-3 {
        gap: 1rem !important;
    }

    .gap-lg-4 {
        gap: 1.8rem !important;
    }

    .gap-lg-5 {
        gap: 2.5rem !important;
    }

    .gap-lg-6 {
        gap: 3rem !important;
    }

    .gap-lg-7 {
        gap: 4rem !important;
    }

    .gap-lg-8 {
        gap: 5rem !important;
    }

    .gap-lg-9 {
        gap: 7.5rem !important;
    }

    .gap-lg-10 {
        gap: 10rem !important;
    }

    .gap-lg-11 {
        gap: 12.5rem !important;
    }

    .gap-lg-12 {
        gap: 15rem !important;
    }

    .justify-content-lg-start {
        -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
        justify-content: flex-start !important;
    }

    .justify-content-lg-end {
        -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
        justify-content: flex-end !important;
    }

    .justify-content-lg-center {
        -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
        justify-content: center !important;
    }

    .justify-content-lg-between {
        -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
        justify-content: space-between !important;
    }

    .justify-content-lg-around {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
    }

    .justify-content-lg-evenly {
        -webkit-box-pack: space-evenly !important;
        -ms-flex-pack: space-evenly !important;
        justify-content: space-evenly !important;
    }

    .align-items-lg-start {
        -webkit-box-align: start !important;
        -ms-flex-align: start !important;
        align-items: flex-start !important;
    }

    .align-items-lg-end {
        -webkit-box-align: end !important;
        -ms-flex-align: end !important;
        align-items: flex-end !important;
    }

    .align-items-lg-center {
        -webkit-box-align: center !important;
        -ms-flex-align: center !important;
        align-items: center !important;
    }

    .align-items-lg-baseline {
        -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
        align-items: baseline !important;
    }

    .align-items-lg-stretch {
        -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
        align-items: stretch !important;
    }

    .align-content-lg-start {
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
    }

    .align-content-lg-end {
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
    }

    .align-content-lg-center {
        -ms-flex-line-pack: center !important;
        align-content: center !important;
    }

    .align-content-lg-between {
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
    }

    .align-content-lg-around {
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
    }

    .align-content-lg-stretch {
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
    }

    .align-self-lg-auto {
        -ms-flex-item-align: auto !important;
        align-self: auto !important;
    }

    .align-self-lg-start {
        -ms-flex-item-align: start !important;
        align-self: flex-start !important;
    }

    .align-self-lg-end {
        -ms-flex-item-align: end !important;
        align-self: flex-end !important;
    }

    .align-self-lg-center {
        -ms-flex-item-align: center !important;
        align-self: center !important;
    }

    .align-self-lg-baseline {
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
    }

    .align-self-lg-stretch {
        -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
    }

    .order-lg-first {
        -webkit-box-ordinal-group: 0 !important;
        -ms-flex-order: -1 !important;
        order: -1 !important;
    }

    .order-lg-0 {
        -webkit-box-ordinal-group: 1 !important;
        -ms-flex-order: 0 !important;
        order: 0 !important;
    }

    .order-lg-1 {
        -webkit-box-ordinal-group: 2 !important;
        -ms-flex-order: 1 !important;
        order: 1 !important;
    }

    .order-lg-2 {
        -webkit-box-ordinal-group: 3 !important;
        -ms-flex-order: 2 !important;
        order: 2 !important;
    }

    .order-lg-3 {
        -webkit-box-ordinal-group: 4 !important;
        -ms-flex-order: 3 !important;
        order: 3 !important;
    }

    .order-lg-4 {
        -webkit-box-ordinal-group: 5 !important;
        -ms-flex-order: 4 !important;
        order: 4 !important;
    }

    .order-lg-5 {
        -webkit-box-ordinal-group: 6 !important;
        -ms-flex-order: 5 !important;
        order: 5 !important;
    }

    .order-lg-last {
        -webkit-box-ordinal-group: 7 !important;
        -ms-flex-order: 6 !important;
        order: 6 !important;
    }

    .m-lg-0 {
        margin: 0 !important;
    }

    .m-lg-1 {
        margin: 0.25rem !important;
    }

    .m-lg-2 {
        margin: 0.5rem !important;
    }

    .m-lg-3 {
        margin: 1rem !important;
    }

    .m-lg-4 {
        margin: 1.8rem !important;
    }

    .m-lg-5 {
        margin: 2.5rem !important;
    }

    .m-lg-6 {
        margin: 3rem !important;
    }

    .m-lg-7 {
        margin: 4rem !important;
    }

    .m-lg-8 {
        margin: 5rem !important;
    }

    .m-lg-9 {
        margin: 7.5rem !important;
    }

    .m-lg-10 {
        margin: 10rem !important;
    }

    .m-lg-11 {
        margin: 12.5rem !important;
    }

    .m-lg-12 {
        margin: 15rem !important;
    }

    .m-lg-auto {
        margin: auto !important;
    }

    .mx-lg-0 {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }

    .mx-lg-1 {
        margin-right: 0.25rem !important;
        margin-left: 0.25rem !important;
    }

    .mx-lg-2 {
        margin-right: 0.5rem !important;
        margin-left: 0.5rem !important;
    }

    .mx-lg-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important;
    }

    .mx-lg-4 {
        margin-right: 1.8rem !important;
        margin-left: 1.8rem !important;
    }

    .mx-lg-5 {
        margin-right: 2.5rem !important;
        margin-left: 2.5rem !important;
    }

    .mx-lg-6 {
        margin-right: 3rem !important;
        margin-left: 3rem !important;
    }

    .mx-lg-7 {
        margin-right: 4rem !important;
        margin-left: 4rem !important;
    }

    .mx-lg-8 {
        margin-right: 5rem !important;
        margin-left: 5rem !important;
    }

    .mx-lg-9 {
        margin-right: 7.5rem !important;
        margin-left: 7.5rem !important;
    }

    .mx-lg-10 {
        margin-right: 10rem !important;
        margin-left: 10rem !important;
    }

    .mx-lg-11 {
        margin-right: 12.5rem !important;
        margin-left: 12.5rem !important;
    }

    .mx-lg-12 {
        margin-right: 15rem !important;
        margin-left: 15rem !important;
    }

    .mx-lg-auto {
        margin-right: auto !important;
        margin-left: auto !important;
    }

    .my-lg-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }

    .my-lg-1 {
        margin-top: 0.25rem !important;
        margin-bottom: 0.25rem !important;
    }

    .my-lg-2 {
        margin-top: 0.5rem !important;
        margin-bottom: 0.5rem !important;
    }

    .my-lg-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
    }

    .my-lg-4 {
        margin-top: 1.8rem !important;
        margin-bottom: 1.8rem !important;
    }

    .my-lg-5 {
        margin-top: 2.5rem !important;
        margin-bottom: 2.5rem !important;
    }

    .my-lg-6 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important;
    }

    .my-lg-7 {
        margin-top: 4rem !important;
        margin-bottom: 4rem !important;
    }

    .my-lg-8 {
        margin-top: 5rem !important;
        margin-bottom: 5rem !important;
    }

    .my-lg-9 {
        margin-top: 7.5rem !important;
        margin-bottom: 7.5rem !important;
    }

    .my-lg-10 {
        margin-top: 10rem !important;
        margin-bottom: 10rem !important;
    }

    .my-lg-11 {
        margin-top: 12.5rem !important;
        margin-bottom: 12.5rem !important;
    }

    .my-lg-12 {
        margin-top: 15rem !important;
        margin-bottom: 15rem !important;
    }

    .my-lg-auto {
        margin-top: auto !important;
        margin-bottom: auto !important;
    }

    .mt-lg-0 {
        margin-top: 0 !important;
    }

    .mt-lg-1 {
        margin-top: 0.25rem !important;
    }

    .mt-lg-2 {
        margin-top: 0.5rem !important;
    }

    .mt-lg-3 {
        margin-top: 1rem !important;
    }

    .mt-lg-4 {
        margin-top: 1.8rem !important;
    }

    .mt-lg-5 {
        margin-top: 2.5rem !important;
    }

    .mt-lg-6 {
        margin-top: 3rem !important;
    }

    .mt-lg-7 {
        margin-top: 4rem !important;
    }

    .mt-lg-8 {
        margin-top: 5rem !important;
    }

    .mt-lg-9 {
        margin-top: 7.5rem !important;
    }

    .mt-lg-10 {
        margin-top: 10rem !important;
    }

    .mt-lg-11 {
        margin-top: 12.5rem !important;
    }

    .mt-lg-12 {
        margin-top: 15rem !important;
    }

    .mt-lg-auto {
        margin-top: auto !important;
    }

    .me-lg-0 {
        margin-right: 0 !important;
    }

    .me-lg-1 {
        margin-right: 0.25rem !important;
    }

    .me-lg-2 {
        margin-right: 0.5rem !important;
    }

    .me-lg-3 {
        margin-right: 1rem !important;
    }

    .me-lg-4 {
        margin-right: 1.8rem !important;
    }

    .me-lg-5 {
        margin-right: 2.5rem !important;
    }

    .me-lg-6 {
        margin-right: 3rem !important;
    }

    .me-lg-7 {
        margin-right: 4rem !important;
    }

    .me-lg-8 {
        margin-right: 5rem !important;
    }

    .me-lg-9 {
        margin-right: 7.5rem !important;
    }

    .me-lg-10 {
        margin-right: 10rem !important;
    }

    .me-lg-11 {
        margin-right: 12.5rem !important;
    }

    .me-lg-12 {
        margin-right: 15rem !important;
    }

    .me-lg-auto {
        margin-right: auto !important;
    }

    .mb-lg-0 {
        margin-bottom: 0 !important;
    }

    .mb-lg-1 {
        margin-bottom: 0.25rem !important;
    }

    .mb-lg-2 {
        margin-bottom: 0.5rem !important;
    }

    .mb-lg-3 {
        margin-bottom: 1rem !important;
    }

    .mb-lg-4 {
        margin-bottom: 1.8rem !important;
    }

    .mb-lg-5 {
        margin-bottom: 2.5rem !important;
    }

    .mb-lg-6 {
        margin-bottom: 3rem !important;
    }

    .mb-lg-7 {
        margin-bottom: 4rem !important;
    }

    .mb-lg-8 {
        margin-bottom: 5rem !important;
    }

    .mb-lg-9 {
        margin-bottom: 7.5rem !important;
    }

    .mb-lg-10 {
        margin-bottom: 10rem !important;
    }

    .mb-lg-11 {
        margin-bottom: 12.5rem !important;
    }

    .mb-lg-12 {
        margin-bottom: 15rem !important;
    }

    .mb-lg-auto {
        margin-bottom: auto !important;
    }

    .ms-lg-0 {
        margin-left: 0 !important;
    }

    .ms-lg-1 {
        margin-left: 0.25rem !important;
    }

    .ms-lg-2 {
        margin-left: 0.5rem !important;
    }

    .ms-lg-3 {
        margin-left: 1rem !important;
    }

    .ms-lg-4 {
        margin-left: 1.8rem !important;
    }

    .ms-lg-5 {
        margin-left: 2.5rem !important;
    }

    .ms-lg-6 {
        margin-left: 3rem !important;
    }

    .ms-lg-7 {
        margin-left: 4rem !important;
    }

    .ms-lg-8 {
        margin-left: 5rem !important;
    }

    .ms-lg-9 {
        margin-left: 7.5rem !important;
    }

    .ms-lg-10 {
        margin-left: 10rem !important;
    }

    .ms-lg-11 {
        margin-left: 12.5rem !important;
    }

    .ms-lg-12 {
        margin-left: 15rem !important;
    }

    .ms-lg-auto {
        margin-left: auto !important;
    }

    .m-lg-n1 {
        margin: -0.25rem !important;
    }

    .m-lg-n2 {
        margin: -0.5rem !important;
    }

    .m-lg-n3 {
        margin: -1rem !important;
    }

    .m-lg-n4 {
        margin: -1.8rem !important;
    }

    .m-lg-n5 {
        margin: -2.5rem !important;
    }

    .m-lg-n6 {
        margin: -3rem !important;
    }

    .m-lg-n7 {
        margin: -4rem !important;
    }

    .m-lg-n8 {
        margin: -5rem !important;
    }

    .m-lg-n9 {
        margin: -7.5rem !important;
    }

    .m-lg-n10 {
        margin: -10rem !important;
    }

    .m-lg-n11 {
        margin: -12.5rem !important;
    }

    .m-lg-n12 {
        margin: -15rem !important;
    }

    .mx-lg-n1 {
        margin-right: -0.25rem !important;
        margin-left: -0.25rem !important;
    }

    .mx-lg-n2 {
        margin-right: -0.5rem !important;
        margin-left: -0.5rem !important;
    }

    .mx-lg-n3 {
        margin-right: -1rem !important;
        margin-left: -1rem !important;
    }

    .mx-lg-n4 {
        margin-right: -1.8rem !important;
        margin-left: -1.8rem !important;
    }

    .mx-lg-n5 {
        margin-right: -2.5rem !important;
        margin-left: -2.5rem !important;
    }

    .mx-lg-n6 {
        margin-right: -3rem !important;
        margin-left: -3rem !important;
    }

    .mx-lg-n7 {
        margin-right: -4rem !important;
        margin-left: -4rem !important;
    }

    .mx-lg-n8 {
        margin-right: -5rem !important;
        margin-left: -5rem !important;
    }

    .mx-lg-n9 {
        margin-right: -7.5rem !important;
        margin-left: -7.5rem !important;
    }

    .mx-lg-n10 {
        margin-right: -10rem !important;
        margin-left: -10rem !important;
    }

    .mx-lg-n11 {
        margin-right: -12.5rem !important;
        margin-left: -12.5rem !important;
    }

    .mx-lg-n12 {
        margin-right: -15rem !important;
        margin-left: -15rem !important;
    }

    .my-lg-n1 {
        margin-top: -0.25rem !important;
        margin-bottom: -0.25rem !important;
    }

    .my-lg-n2 {
        margin-top: -0.5rem !important;
        margin-bottom: -0.5rem !important;
    }

    .my-lg-n3 {
        margin-top: -1rem !important;
        margin-bottom: -1rem !important;
    }

    .my-lg-n4 {
        margin-top: -1.8rem !important;
        margin-bottom: -1.8rem !important;
    }

    .my-lg-n5 {
        margin-top: -2.5rem !important;
        margin-bottom: -2.5rem !important;
    }

    .my-lg-n6 {
        margin-top: -3rem !important;
        margin-bottom: -3rem !important;
    }

    .my-lg-n7 {
        margin-top: -4rem !important;
        margin-bottom: -4rem !important;
    }

    .my-lg-n8 {
        margin-top: -5rem !important;
        margin-bottom: -5rem !important;
    }

    .my-lg-n9 {
        margin-top: -7.5rem !important;
        margin-bottom: -7.5rem !important;
    }

    .my-lg-n10 {
        margin-top: -10rem !important;
        margin-bottom: -10rem !important;
    }

    .my-lg-n11 {
        margin-top: -12.5rem !important;
        margin-bottom: -12.5rem !important;
    }

    .my-lg-n12 {
        margin-top: -15rem !important;
        margin-bottom: -15rem !important;
    }

    .mt-lg-n1 {
        margin-top: -0.25rem !important;
    }

    .mt-lg-n2 {
        margin-top: -0.5rem !important;
    }

    .mt-lg-n3 {
        margin-top: -1rem !important;
    }

    .mt-lg-n4 {
        margin-top: -1.8rem !important;
    }

    .mt-lg-n5 {
        margin-top: -2.5rem !important;
    }

    .mt-lg-n6 {
        margin-top: -3rem !important;
    }

    .mt-lg-n7 {
        margin-top: -4rem !important;
    }

    .mt-lg-n8 {
        margin-top: -5rem !important;
    }

    .mt-lg-n9 {
        margin-top: -7.5rem !important;
    }

    .mt-lg-n10 {
        margin-top: -10rem !important;
    }

    .mt-lg-n11 {
        margin-top: -12.5rem !important;
    }

    .mt-lg-n12 {
        margin-top: -15rem !important;
    }

    .me-lg-n1 {
        margin-right: -0.25rem !important;
    }

    .me-lg-n2 {
        margin-right: -0.5rem !important;
    }

    .me-lg-n3 {
        margin-right: -1rem !important;
    }

    .me-lg-n4 {
        margin-right: -1.8rem !important;
    }

    .me-lg-n5 {
        margin-right: -2.5rem !important;
    }

    .me-lg-n6 {
        margin-right: -3rem !important;
    }

    .me-lg-n7 {
        margin-right: -4rem !important;
    }

    .me-lg-n8 {
        margin-right: -5rem !important;
    }

    .me-lg-n9 {
        margin-right: -7.5rem !important;
    }

    .me-lg-n10 {
        margin-right: -10rem !important;
    }

    .me-lg-n11 {
        margin-right: -12.5rem !important;
    }

    .me-lg-n12 {
        margin-right: -15rem !important;
    }

    .mb-lg-n1 {
        margin-bottom: -0.25rem !important;
    }

    .mb-lg-n2 {
        margin-bottom: -0.5rem !important;
    }

    .mb-lg-n3 {
        margin-bottom: -1rem !important;
    }

    .mb-lg-n4 {
        margin-bottom: -1.8rem !important;
    }

    .mb-lg-n5 {
        margin-bottom: -2.5rem !important;
    }

    .mb-lg-n6 {
        margin-bottom: -3rem !important;
    }

    .mb-lg-n7 {
        margin-bottom: -4rem !important;
    }

    .mb-lg-n8 {
        margin-bottom: -5rem !important;
    }

    .mb-lg-n9 {
        margin-bottom: -7.5rem !important;
    }

    .mb-lg-n10 {
        margin-bottom: -10rem !important;
    }

    .mb-lg-n11 {
        margin-bottom: -12.5rem !important;
    }

    .mb-lg-n12 {
        margin-bottom: -15rem !important;
    }

    .ms-lg-n1 {
        margin-left: -0.25rem !important;
    }

    .ms-lg-n2 {
        margin-left: -0.5rem !important;
    }

    .ms-lg-n3 {
        margin-left: -1rem !important;
    }

    .ms-lg-n4 {
        margin-left: -1.8rem !important;
    }

    .ms-lg-n5 {
        margin-left: -2.5rem !important;
    }

    .ms-lg-n6 {
        margin-left: -3rem !important;
    }

    .ms-lg-n7 {
        margin-left: -4rem !important;
    }

    .ms-lg-n8 {
        margin-left: -5rem !important;
    }

    .ms-lg-n9 {
        margin-left: -7.5rem !important;
    }

    .ms-lg-n10 {
        margin-left: -10rem !important;
    }

    .ms-lg-n11 {
        margin-left: -12.5rem !important;
    }

    .ms-lg-n12 {
        margin-left: -15rem !important;
    }

    .p-lg-0 {
        padding: 0 !important;
    }

    .p-lg-1 {
        padding: 0.25rem !important;
    }

    .p-lg-2 {
        padding: 0.5rem !important;
    }

    .p-lg-3 {
        padding: 1rem !important;
    }

    .p-lg-4 {
        padding: 1.8rem !important;
    }

    .p-lg-5 {
        padding: 2.5rem !important;
    }

    .p-lg-6 {
        padding: 3rem !important;
    }

    .p-lg-7 {
        padding: 4rem !important;
    }

    .p-lg-8 {
        padding: 5rem !important;
    }

    .p-lg-9 {
        padding: 7.5rem !important;
    }

    .p-lg-10 {
        padding: 10rem !important;
    }

    .p-lg-11 {
        padding: 12.5rem !important;
    }

    .p-lg-12 {
        padding: 15rem !important;
    }

    .px-lg-0 {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }

    .px-lg-1 {
        padding-right: 0.25rem !important;
        padding-left: 0.25rem !important;
    }

    .px-lg-2 {
        padding-right: 0.5rem !important;
        padding-left: 0.5rem !important;
    }

    .px-lg-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    }

    .px-lg-4 {
        padding-right: 1.8rem !important;
        padding-left: 1.8rem !important;
    }

    .px-lg-5 {
        padding-right: 2.5rem !important;
        padding-left: 2.5rem !important;
    }

    .px-lg-6 {
        padding-right: 3rem !important;
        padding-left: 3rem !important;
    }

    .px-lg-7 {
        padding-right: 4rem !important;
        padding-left: 4rem !important;
    }

    .px-lg-8 {
        padding-right: 5rem !important;
        padding-left: 5rem !important;
    }

    .px-lg-9 {
        padding-right: 7.5rem !important;
        padding-left: 7.5rem !important;
    }

    .px-lg-10 {
        padding-right: 10rem !important;
        padding-left: 10rem !important;
    }

    .px-lg-11 {
        padding-right: 12.5rem !important;
        padding-left: 12.5rem !important;
    }

    .px-lg-12 {
        padding-right: 15rem !important;
        padding-left: 15rem !important;
    }

    .py-lg-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    .py-lg-1 {
        padding-top: 0.25rem !important;
        padding-bottom: 0.25rem !important;
    }

    .py-lg-2 {
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
    }

    .py-lg-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
    }

    .py-lg-4 {
        padding-top: 1.8rem !important;
        padding-bottom: 1.8rem !important;
    }

    .py-lg-5 {
        padding-top: 2.5rem !important;
        padding-bottom: 2.5rem !important;
    }

    .py-lg-6 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important;
    }

    .py-lg-7 {
        padding-top: 4rem !important;
        padding-bottom: 4rem !important;
    }

    .py-lg-8 {
        padding-top: 5rem !important;
        padding-bottom: 5rem !important;
    }

    .py-lg-9 {
        padding-top: 7.5rem !important;
        padding-bottom: 7.5rem !important;
    }

    .py-lg-10 {
        padding-top: 10rem !important;
        padding-bottom: 10rem !important;
    }

    .py-lg-11 {
        padding-top: 12.5rem !important;
        padding-bottom: 12.5rem !important;
    }

    .py-lg-12 {
        padding-top: 15rem !important;
        padding-bottom: 15rem !important;
    }

    .pt-lg-0 {
        padding-top: 0 !important;
    }

    .pt-lg-1 {
        padding-top: 0.25rem !important;
    }

    .pt-lg-2 {
        padding-top: 0.5rem !important;
    }

    .pt-lg-3 {
        padding-top: 1rem !important;
    }

    .pt-lg-4 {
        padding-top: 1.8rem !important;
    }

    .pt-lg-5 {
        padding-top: 2.5rem !important;
    }

    .pt-lg-6 {
        padding-top: 3rem !important;
    }

    .pt-lg-7 {
        padding-top: 4rem !important;
    }

    .pt-lg-8 {
        padding-top: 5rem !important;
    }

    .pt-lg-9 {
        padding-top: 7.5rem !important;
    }

    .pt-lg-10 {
        padding-top: 10rem !important;
    }

    .pt-lg-11 {
        padding-top: 12.5rem !important;
    }

    .pt-lg-12 {
        padding-top: 15rem !important;
    }

    .pe-lg-0 {
        padding-right: 0 !important;
    }

    .pe-lg-1 {
        padding-right: 0.25rem !important;
    }

    .pe-lg-2 {
        padding-right: 0.5rem !important;
    }

    .pe-lg-3 {
        padding-right: 1rem !important;
    }

    .pe-lg-4 {
        padding-right: 1.8rem !important;
    }

    .pe-lg-5 {
        padding-right: 2.5rem !important;
    }

    .pe-lg-6 {
        padding-right: 3rem !important;
    }

    .pe-lg-7 {
        padding-right: 4rem !important;
    }

    .pe-lg-8 {
        padding-right: 5rem !important;
    }

    .pe-lg-9 {
        padding-right: 7.5rem !important;
    }

    .pe-lg-10 {
        padding-right: 10rem !important;
    }

    .pe-lg-11 {
        padding-right: 12.5rem !important;
    }

    .pe-lg-12 {
        padding-right: 15rem !important;
    }

    .pb-lg-0 {
        padding-bottom: 0 !important;
    }

    .pb-lg-1 {
        padding-bottom: 0.25rem !important;
    }

    .pb-lg-2 {
        padding-bottom: 0.5rem !important;
    }

    .pb-lg-3 {
        padding-bottom: 1rem !important;
    }

    .pb-lg-4 {
        padding-bottom: 1.8rem !important;
    }

    .pb-lg-5 {
        padding-bottom: 2.5rem !important;
    }

    .pb-lg-6 {
        padding-bottom: 3rem !important;
    }

    .pb-lg-7 {
        padding-bottom: 4rem !important;
    }

    .pb-lg-8 {
        padding-bottom: 5rem !important;
    }

    .pb-lg-9 {
        padding-bottom: 7.5rem !important;
    }

    .pb-lg-10 {
        padding-bottom: 10rem !important;
    }

    .pb-lg-11 {
        padding-bottom: 12.5rem !important;
    }

    .pb-lg-12 {
        padding-bottom: 15rem !important;
    }

    .ps-lg-0 {
        padding-left: 0 !important;
    }

    .ps-lg-1 {
        padding-left: 0.25rem !important;
    }

    .ps-lg-2 {
        padding-left: 0.5rem !important;
    }

    .ps-lg-3 {
        padding-left: 1rem !important;
    }

    .ps-lg-4 {
        padding-left: 1.8rem !important;
    }

    .ps-lg-5 {
        padding-left: 2.5rem !important;
    }

    .ps-lg-6 {
        padding-left: 3rem !important;
    }

    .ps-lg-7 {
        padding-left: 4rem !important;
    }

    .ps-lg-8 {
        padding-left: 5rem !important;
    }

    .ps-lg-9 {
        padding-left: 7.5rem !important;
    }

    .ps-lg-10 {
        padding-left: 10rem !important;
    }

    .ps-lg-11 {
        padding-left: 12.5rem !important;
    }

    .ps-lg-12 {
        padding-left: 15rem !important;
    }

    .fs-lg--2 {
        font-size: 0.79012rem !important;
    }

    .fs-lg--1 {
        font-size: 0.88889rem !important;
    }

    .fs-lg-0 {
        font-size: 1rem !important;
    }

    .fs-lg-1 {
        font-size: 1.125rem !important;
    }

    .fs-lg-2 {
        font-size: 1.26563rem !important;
    }

    .fs-lg-3 {
        font-size: 1.42383rem !important;
    }

    .fs-lg-4 {
        font-size: 1.60181rem !important;
    }

    .fs-lg-5 {
        font-size: 1.80203rem !important;
    }

    .fs-lg-6 {
        font-size: 2.02729rem !important;
    }

    .fs-lg-7 {
        font-size: 2.2807rem !important;
    }

    .fs-lg-8 {
        font-size: 2.56578rem !important;
    }

    .fs-lg-9 {
        font-size: 2.88651rem !important;
    }

    .fs-lg-10 {
        font-size: 3.24732rem !important;
    }

    .fs-lg-11 {
        font-size: 3.65324rem !important;
    }

    .fs-lg-12 {
        font-size: 4.10989rem !important;
    }

    .fs-lg-13 {
        font-size: 4.62363rem !important;
    }

    .fs-lg-14 {
        font-size: 5.20158rem !important;
    }

    .lh-lg-unset {
        line-height: unset !important;
    }

    .lh-lg-1 {
        line-height: 1 !important;
    }

    .lh-lg-1-3 {
        line-height: 1.3 !important;
    }

    .lh-lg-1-4 {
        line-height: 1.4 !important;
    }

    .lh-lg-1-5 {
        line-height: 1.5 !important;
    }

    .lh-lg-1-6 {
        line-height: 1.6 !important;
    }

    .lh-lg-1-7 {
        line-height: 1.7 !important;
    }

    .lh-lg-1-8 {
        line-height: 1.8 !important;
    }

    .lh-lg-1-9 {
        line-height: 1.9 !important;
    }

    .lh-lg-2 {
        line-height: 2 !important;
    }

    .text-lg-start {
        text-align: left !important;
    }

    .text-lg-end {
        text-align: right !important;
    }

    .text-lg-center {
        text-align: center !important;
    }

    .rounded-lg-top {
        border-top-left-radius: 1.5rem !important;
        border-top-right-radius: 1.5rem !important;
    }

    .rounded-lg-top-lg {
        border-top-left-radius: 2.25rem !important;
        border-top-right-radius: 2.25rem !important;
    }

    .rounded-lg-top-0 {
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
    }

    .rounded-lg-end {
        border-top-right-radius: 1.5rem !important;
        border-bottom-right-radius: 1.5rem !important;
    }

    .rounded-lg-end-lg {
        border-top-right-radius: 2.25rem !important;
        border-bottom-right-radius: 2.25rem !important;
    }

    .rounded-lg-end-0 {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }

    .rounded-lg-bottom {
        border-bottom-right-radius: 1.5rem !important;
        border-bottom-left-radius: 1.5rem !important;
    }

    .rounded-lg-bottom-lg {
        border-bottom-right-radius: 2.25rem !important;
        border-bottom-left-radius: 2.25rem !important;
    }

    .rounded-lg-bottom-0 {
        border-bottom-right-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }

    .rounded-lg-start {
        border-bottom-left-radius: 1.5rem !important;
        border-top-left-radius: 1.5rem !important;
    }

    .rounded-lg-start-lg {
        border-bottom-left-radius: 2.25rem !important;
        border-top-left-radius: 2.25rem !important;
    }

    .rounded-lg-start-0 {
        border-bottom-left-radius: 0 !important;
        border-top-left-radius: 0 !important;
    }

    .opacity-lg-0 {
        opacity: 0 !important;
    }

    .opacity-lg-25 {
        opacity: 0.25 !important;
    }

    .opacity-lg-50 {
        opacity: 0.5 !important;
    }

    .opacity-lg-75 {
        opacity: 0.75 !important;
    }

    .opacity-lg-85 {
        opacity: 0.85 !important;
    }

    .opacity-lg-100 {
        opacity: 1 !important;
    }

    .max-vh-lg-25 {
        max-height: 25vh !important;
    }

    .max-vh-lg-50 {
        max-height: 50vh !important;
    }

    .max-vh-lg-75 {
        max-height: 75vh !important;
    }

    .max-vh-lg-100 {
        max-height: 100vh !important;
    }

    .border-lg-x {
        border-left: 1px solid #eeeeee !important;
        border-right: 1px solid #eeeeee !important;
    }

    .border-lg-x-0 {
        border-left: 0 !important;
        border-right: 0 !important;
    }

    .border-lg-y {
        border-top: 1px solid #eeeeee !important;
        border-bottom: 1px solid #eeeeee !important;
    }

    .border-lg-y-0 {
        border-top: 0 !important;
        border-bottom: 0 !important;
    }

    .border-lg-dashed {
        border: 1px dashed #eeeeee !important;
    }

    .border-lg-dashed-top {
        border-top: 1px dashed #eeeeee !important;
    }

    .border-lg-dashed-end {
        border-right: 1px dashed #eeeeee !important;
    }

    .border-lg-dashed-start {
        border-left: 1px dashed #eeeeee !important;
    }

    .border-lg-dashed-bottom {
        border-bottom: 1px dashed #eeeeee !important;
    }

    .border-lg-dashed-x {
        border-left: 1px dashed #eeeeee !important;
        border-right: 1px dashed #eeeeee !important;
    }

    .border-lg-dashed-y {
        border-top: 1px dashed #eeeeee !important;
        border-bottom: 1px dashed #eeeeee !important;
    }

    .rounded-lg-0 {
        border-radius: 0 !important;
    }
}

@media (min-width: 1200px) {
    .float-xl-start {
        float: left !important;
    }

    .float-xl-end {
        float: right !important;
    }

    .float-xl-none {
        float: none !important;
    }

    .overflow-xl-unset {
        overflow: unset !important;
    }

    .overflow-xl-auto {
        overflow: auto !important;
    }

    .overflow-xl-hidden {
        overflow: hidden !important;
    }

    .overflow-xl-visible {
        overflow: visible !important;
    }

    .overflow-xl-scroll {
        overflow: scroll !important;
    }

    .d-xl-inline {
        display: inline !important;
    }

    .d-xl-inline-block {
        display: inline-block !important;
    }

    .d-xl-block {
        display: block !important;
    }

    .d-xl-grid {
        display: grid !important;
    }

    .d-xl-table {
        display: table !important;
    }

    .d-xl-table-row {
        display: table-row !important;
    }

    .d-xl-table-cell {
        display: table-cell !important;
    }

    .d-xl-flex {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
    }

    .d-xl-inline-flex {
        display: -webkit-inline-box !important;
        display: -ms-inline-flexbox !important;
        display: inline-flex !important;
    }

    .d-xl-none {
        display: none !important;
    }

    .position-xl-static {
        position: static !important;
    }

    .position-xl-absolute {
        position: absolute !important;
    }

    .position-xl-relative {
        position: relative !important;
    }

    .position-xl-fixed {
        position: fixed !important;
    }

    .position-xl-sticky {
        position: -webkit-sticky !important;
        position: sticky !important;
    }

    .top-xl-unset {
        top: unset !important;
    }

    .top-xl-0 {
        top: 0 !important;
    }

    .top-xl-50 {
        top: 50% !important;
    }

    .top-xl-100 {
        top: 100% !important;
    }

    .bottom-xl-unset {
        bottom: unset !important;
    }

    .bottom-xl-0 {
        bottom: 0 !important;
    }

    .bottom-xl-50 {
        bottom: 50% !important;
    }

    .bottom-xl-100 {
        bottom: 100% !important;
    }

    .start-xl-unset {
        left: unset !important;
    }

    .start-xl-0 {
        left: 0 !important;
    }

    .start-xl-50 {
        left: 50% !important;
    }

    .start-xl-100 {
        left: 100% !important;
    }

    .end-xl-unset {
        right: unset !important;
    }

    .end-xl-0 {
        right: 0 !important;
    }

    .end-xl-50 {
        right: 50% !important;
    }

    .end-xl-100 {
        right: 100% !important;
    }

    .translate-xl-middle {
        -webkit-transform: translateX(-50%) translateY(-50%) !important;
        -ms-transform: translateX(-50%) translateY(-50%) !important;
        transform: translateX(-50%) translateY(-50%) !important;
    }

    .translate-xl-middle-x {
        -webkit-transform: translateX(-50%) !important;
        -ms-transform: translateX(-50%) !important;
        transform: translateX(-50%) !important;
    }

    .translate-xl-middle-y {
        -webkit-transform: translateY(-50%) !important;
        -ms-transform: translateY(-50%) !important;
        transform: translateY(-50%) !important;
    }

    .border-xl {
        border: 1px solid #eeeeee !important;
    }

    .border-xl-0 {
        border: 0 !important;
    }

    .border-xl-top {
        border-top: 1px solid #eeeeee !important;
    }

    .border-xl-top-0 {
        border-top: 0 !important;
    }

    .border-xl-end {
        border-right: 1px solid #eeeeee !important;
    }

    .border-xl-end-0 {
        border-right: 0 !important;
    }

    .border-xl-bottom {
        border-bottom: 1px solid #eeeeee !important;
    }

    .border-xl-bottom-0 {
        border-bottom: 0 !important;
    }

    .border-xl-start {
        border-left: 1px solid #eeeeee !important;
    }

    .border-xl-start-0 {
        border-left: 0 !important;
    }

    .border-xl-facebook {
        border-color: #3c5a99 !important;
    }

    .border-xl-google-plus {
        border-color: #dd4b39 !important;
    }

    .border-xl-twitter {
        border-color: #1da1f2 !important;
    }

    .border-xl-linkedin {
        border-color: #0077b5 !important;
    }

    .border-xl-youtube {
        border-color: #ff0000 !important;
    }

    .border-xl-github {
        border-color: #333 !important;
    }

    .border-xl-black {
        border-color: #000 !important;
    }

    .border-xl-100 {
        border-color: #fcfcfc !important;
    }

    .border-xl-200 {
        border-color: #f5f5f5 !important;
    }

    .border-xl-300 {
        border-color: #eeeeee !important;
    }

    .border-xl-400 {
        border-color: #e0e0e0 !important;
    }

    .border-xl-500 {
        border-color: #bdbdbd !important;
    }

    .border-xl-600 {
        border-color: #f0f4f9 !important;
    }

    .border-xl-700 {
        border-color: #757575 !important;
    }

    .border-xl-800 {
        border-color: #616161 !important;
    }

    .border-xl-900 {
        border-color: #5e6282 !important;
    }

    .border-xl-1000 {
        border-color: #212832 !important;
    }

    .border-xl-white {
        border-color: #fffefe !important;
    }

    .border-xl-primary {
        border-color: #f1a501 !important;
    }

    .border-xl-secondary {
        border-color: #5e6282 !important;
    }

    .border-xl-success {
        border-color: #79b93c !important;
    }

    .border-xl-info {
        border-color: #006380 !important;
    }

    .border-xl-warning {
        border-color: #f1a501 !important;
    }

    .border-xl-danger {
        border-color: #df6951 !important;
    }

    .border-xl-light {
        border-color: #f0f4f9 !important;
    }

    .border-xl-dark {
        border-color: #212832 !important;
    }

    .w-xl-25 {
        width: 25% !important;
    }

    .w-xl-50 {
        width: 50% !important;
    }

    .w-xl-75 {
        width: 75% !important;
    }

    .w-xl-100 {
        width: 100% !important;
    }

    .w-xl-auto {
        width: auto !important;
    }

    .vw-xl-25 {
        width: 25vw !important;
    }

    .vw-xl-50 {
        width: 50vw !important;
    }

    .vw-xl-75 {
        width: 75vw !important;
    }

    .vw-xl-100 {
        width: 100vw !important;
    }

    .h-xl-25 {
        height: 25% !important;
    }

    .h-xl-50 {
        height: 50% !important;
    }

    .h-xl-75 {
        height: 75% !important;
    }

    .h-xl-100 {
        height: 100% !important;
    }

    .h-xl-auto {
        height: auto !important;
    }

    .vh-xl-25 {
        height: 25vh !important;
    }

    .vh-xl-50 {
        height: 50vh !important;
    }

    .vh-xl-75 {
        height: 75vh !important;
    }

    .vh-xl-100 {
        height: 100vh !important;
    }

    .min-vh-xl-25 {
        min-height: 25vh !important;
    }

    .min-vh-xl-50 {
        min-height: 50vh !important;
    }

    .min-vh-xl-75 {
        min-height: 75vh !important;
    }

    .min-vh-xl-100 {
        min-height: 100vh !important;
    }

    .flex-xl-fill {
        -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important;
    }

    .flex-xl-row {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
        flex-direction: row !important;
    }

    .flex-xl-column {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
        flex-direction: column !important;
    }

    .flex-xl-row-reverse {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important;
    }

    .flex-xl-column-reverse {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important;
    }

    .flex-xl-grow-0 {
        -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
        flex-grow: 0 !important;
    }

    .flex-xl-grow-1 {
        -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
        flex-grow: 1 !important;
    }

    .flex-xl-shrink-0 {
        -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
    }

    .flex-xl-shrink-1 {
        -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
    }

    .flex-xl-wrap {
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
    }

    .flex-xl-nowrap {
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
    }

    .flex-xl-wrap-reverse {
        -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
    }

    .gap-xl-0 {
        gap: 0 !important;
    }

    .gap-xl-1 {
        gap: 0.25rem !important;
    }

    .gap-xl-2 {
        gap: 0.5rem !important;
    }

    .gap-xl-3 {
        gap: 1rem !important;
    }

    .gap-xl-4 {
        gap: 1.8rem !important;
    }

    .gap-xl-5 {
        gap: 2.5rem !important;
    }

    .gap-xl-6 {
        gap: 3rem !important;
    }

    .gap-xl-7 {
        gap: 4rem !important;
    }

    .gap-xl-8 {
        gap: 5rem !important;
    }

    .gap-xl-9 {
        gap: 7.5rem !important;
    }

    .gap-xl-10 {
        gap: 10rem !important;
    }

    .gap-xl-11 {
        gap: 12.5rem !important;
    }

    .gap-xl-12 {
        gap: 15rem !important;
    }

    .justify-content-xl-start {
        -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
        justify-content: flex-start !important;
    }

    .justify-content-xl-end {
        -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
        justify-content: flex-end !important;
    }

    .justify-content-xl-center {
        -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
        justify-content: center !important;
    }

    .justify-content-xl-between {
        -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
        justify-content: space-between !important;
    }

    .justify-content-xl-around {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
    }

    .justify-content-xl-evenly {
        -webkit-box-pack: space-evenly !important;
        -ms-flex-pack: space-evenly !important;
        justify-content: space-evenly !important;
    }

    .align-items-xl-start {
        -webkit-box-align: start !important;
        -ms-flex-align: start !important;
        align-items: flex-start !important;
    }

    .align-items-xl-end {
        -webkit-box-align: end !important;
        -ms-flex-align: end !important;
        align-items: flex-end !important;
    }

    .align-items-xl-center {
        -webkit-box-align: center !important;
        -ms-flex-align: center !important;
        align-items: center !important;
    }

    .align-items-xl-baseline {
        -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
        align-items: baseline !important;
    }

    .align-items-xl-stretch {
        -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
        align-items: stretch !important;
    }

    .align-content-xl-start {
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
    }

    .align-content-xl-end {
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
    }

    .align-content-xl-center {
        -ms-flex-line-pack: center !important;
        align-content: center !important;
    }

    .align-content-xl-between {
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
    }

    .align-content-xl-around {
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
    }

    .align-content-xl-stretch {
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
    }

    .align-self-xl-auto {
        -ms-flex-item-align: auto !important;
        align-self: auto !important;
    }

    .align-self-xl-start {
        -ms-flex-item-align: start !important;
        align-self: flex-start !important;
    }

    .align-self-xl-end {
        -ms-flex-item-align: end !important;
        align-self: flex-end !important;
    }

    .align-self-xl-center {
        -ms-flex-item-align: center !important;
        align-self: center !important;
    }

    .align-self-xl-baseline {
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
    }

    .align-self-xl-stretch {
        -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
    }

    .order-xl-first {
        -webkit-box-ordinal-group: 0 !important;
        -ms-flex-order: -1 !important;
        order: -1 !important;
    }

    .order-xl-0 {
        -webkit-box-ordinal-group: 1 !important;
        -ms-flex-order: 0 !important;
        order: 0 !important;
    }

    .order-xl-1 {
        -webkit-box-ordinal-group: 2 !important;
        -ms-flex-order: 1 !important;
        order: 1 !important;
    }

    .order-xl-2 {
        -webkit-box-ordinal-group: 3 !important;
        -ms-flex-order: 2 !important;
        order: 2 !important;
    }

    .order-xl-3 {
        -webkit-box-ordinal-group: 4 !important;
        -ms-flex-order: 3 !important;
        order: 3 !important;
    }

    .order-xl-4 {
        -webkit-box-ordinal-group: 5 !important;
        -ms-flex-order: 4 !important;
        order: 4 !important;
    }

    .order-xl-5 {
        -webkit-box-ordinal-group: 6 !important;
        -ms-flex-order: 5 !important;
        order: 5 !important;
    }

    .order-xl-last {
        -webkit-box-ordinal-group: 7 !important;
        -ms-flex-order: 6 !important;
        order: 6 !important;
    }

    .m-xl-0 {
        margin: 0 !important;
    }

    .m-xl-1 {
        margin: 0.25rem !important;
    }

    .m-xl-2 {
        margin: 0.5rem !important;
    }

    .m-xl-3 {
        margin: 1rem !important;
    }

    .m-xl-4 {
        margin: 1.8rem !important;
    }

    .m-xl-5 {
        margin: 2.5rem !important;
    }

    .m-xl-6 {
        margin: 3rem !important;
    }

    .m-xl-7 {
        margin: 4rem !important;
    }

    .m-xl-8 {
        margin: 5rem !important;
    }

    .m-xl-9 {
        margin: 7.5rem !important;
    }

    .m-xl-10 {
        margin: 10rem !important;
    }

    .m-xl-11 {
        margin: 12.5rem !important;
    }

    .m-xl-12 {
        margin: 15rem !important;
    }

    .m-xl-auto {
        margin: auto !important;
    }

    .mx-xl-0 {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }

    .mx-xl-1 {
        margin-right: 0.25rem !important;
        margin-left: 0.25rem !important;
    }

    .mx-xl-2 {
        margin-right: 0.5rem !important;
        margin-left: 0.5rem !important;
    }

    .mx-xl-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important;
    }

    .mx-xl-4 {
        margin-right: 1.8rem !important;
        margin-left: 1.8rem !important;
    }

    .mx-xl-5 {
        margin-right: 2.5rem !important;
        margin-left: 2.5rem !important;
    }

    .mx-xl-6 {
        margin-right: 3rem !important;
        margin-left: 3rem !important;
    }

    .mx-xl-7 {
        margin-right: 4rem !important;
        margin-left: 4rem !important;
    }

    .mx-xl-8 {
        margin-right: 5rem !important;
        margin-left: 5rem !important;
    }

    .mx-xl-9 {
        margin-right: 7.5rem !important;
        margin-left: 7.5rem !important;
    }

    .mx-xl-10 {
        margin-right: 10rem !important;
        margin-left: 10rem !important;
    }

    .mx-xl-11 {
        margin-right: 12.5rem !important;
        margin-left: 12.5rem !important;
    }

    .mx-xl-12 {
        margin-right: 15rem !important;
        margin-left: 15rem !important;
    }

    .mx-xl-auto {
        margin-right: auto !important;
        margin-left: auto !important;
    }

    .my-xl-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }

    .my-xl-1 {
        margin-top: 0.25rem !important;
        margin-bottom: 0.25rem !important;
    }

    .my-xl-2 {
        margin-top: 0.5rem !important;
        margin-bottom: 0.5rem !important;
    }

    .my-xl-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
    }

    .my-xl-4 {
        margin-top: 1.8rem !important;
        margin-bottom: 1.8rem !important;
    }

    .my-xl-5 {
        margin-top: 2.5rem !important;
        margin-bottom: 2.5rem !important;
    }

    .my-xl-6 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important;
    }

    .my-xl-7 {
        margin-top: 4rem !important;
        margin-bottom: 4rem !important;
    }

    .my-xl-8 {
        margin-top: 5rem !important;
        margin-bottom: 5rem !important;
    }

    .my-xl-9 {
        margin-top: 7.5rem !important;
        margin-bottom: 7.5rem !important;
    }

    .my-xl-10 {
        margin-top: 10rem !important;
        margin-bottom: 10rem !important;
    }

    .my-xl-11 {
        margin-top: 12.5rem !important;
        margin-bottom: 12.5rem !important;
    }

    .my-xl-12 {
        margin-top: 15rem !important;
        margin-bottom: 15rem !important;
    }

    .my-xl-auto {
        margin-top: auto !important;
        margin-bottom: auto !important;
    }

    .mt-xl-0 {
        margin-top: 0 !important;
    }

    .mt-xl-1 {
        margin-top: 0.25rem !important;
    }

    .mt-xl-2 {
        margin-top: 0.5rem !important;
    }

    .mt-xl-3 {
        margin-top: 1rem !important;
    }

    .mt-xl-4 {
        margin-top: 1.8rem !important;
    }

    .mt-xl-5 {
        margin-top: 2.5rem !important;
    }

    .mt-xl-6 {
        margin-top: 3rem !important;
    }

    .mt-xl-7 {
        margin-top: 4rem !important;
    }

    .mt-xl-8 {
        margin-top: 5rem !important;
    }

    .mt-xl-9 {
        margin-top: 7.5rem !important;
    }

    .mt-xl-10 {
        margin-top: 10rem !important;
    }

    .mt-xl-11 {
        margin-top: 12.5rem !important;
    }

    .mt-xl-12 {
        margin-top: 15rem !important;
    }

    .mt-xl-auto {
        margin-top: auto !important;
    }

    .me-xl-0 {
        margin-right: 0 !important;
    }

    .me-xl-1 {
        margin-right: 0.25rem !important;
    }

    .me-xl-2 {
        margin-right: 0.5rem !important;
    }

    .me-xl-3 {
        margin-right: 1rem !important;
    }

    .me-xl-4 {
        margin-right: 1.8rem !important;
    }

    .me-xl-5 {
        margin-right: 2.5rem !important;
    }

    .me-xl-6 {
        margin-right: 3rem !important;
    }

    .me-xl-7 {
        margin-right: 4rem !important;
    }

    .me-xl-8 {
        margin-right: 5rem !important;
    }

    .me-xl-9 {
        margin-right: 7.5rem !important;
    }

    .me-xl-10 {
        margin-right: 10rem !important;
    }

    .me-xl-11 {
        margin-right: 12.5rem !important;
    }

    .me-xl-12 {
        margin-right: 15rem !important;
    }

    .me-xl-auto {
        margin-right: auto !important;
    }

    .mb-xl-0 {
        margin-bottom: 0 !important;
    }

    .mb-xl-1 {
        margin-bottom: 0.25rem !important;
    }

    .mb-xl-2 {
        margin-bottom: 0.5rem !important;
    }

    .mb-xl-3 {
        margin-bottom: 1rem !important;
    }

    .mb-xl-4 {
        margin-bottom: 1.8rem !important;
    }

    .mb-xl-5 {
        margin-bottom: 2.5rem !important;
    }

    .mb-xl-6 {
        margin-bottom: 3rem !important;
    }

    .mb-xl-7 {
        margin-bottom: 4rem !important;
    }

    .mb-xl-8 {
        margin-bottom: 5rem !important;
    }

    .mb-xl-9 {
        margin-bottom: 7.5rem !important;
    }

    .mb-xl-10 {
        margin-bottom: 10rem !important;
    }

    .mb-xl-11 {
        margin-bottom: 12.5rem !important;
    }

    .mb-xl-12 {
        margin-bottom: 15rem !important;
    }

    .mb-xl-auto {
        margin-bottom: auto !important;
    }

    .ms-xl-0 {
        margin-left: 0 !important;
    }

    .ms-xl-1 {
        margin-left: 0.25rem !important;
    }

    .ms-xl-2 {
        margin-left: 0.5rem !important;
    }

    .ms-xl-3 {
        margin-left: 1rem !important;
    }

    .ms-xl-4 {
        margin-left: 1.8rem !important;
    }

    .ms-xl-5 {
        margin-left: 2.5rem !important;
    }

    .ms-xl-6 {
        margin-left: 3rem !important;
    }

    .ms-xl-7 {
        margin-left: 4rem !important;
    }

    .ms-xl-8 {
        margin-left: 5rem !important;
    }

    .ms-xl-9 {
        margin-left: 7.5rem !important;
    }

    .ms-xl-10 {
        margin-left: 10rem !important;
    }

    .ms-xl-11 {
        margin-left: 12.5rem !important;
    }

    .ms-xl-12 {
        margin-left: 15rem !important;
    }

    .ms-xl-auto {
        margin-left: auto !important;
    }

    .m-xl-n1 {
        margin: -0.25rem !important;
    }

    .m-xl-n2 {
        margin: -0.5rem !important;
    }

    .m-xl-n3 {
        margin: -1rem !important;
    }

    .m-xl-n4 {
        margin: -1.8rem !important;
    }

    .m-xl-n5 {
        margin: -2.5rem !important;
    }

    .m-xl-n6 {
        margin: -3rem !important;
    }

    .m-xl-n7 {
        margin: -4rem !important;
    }

    .m-xl-n8 {
        margin: -5rem !important;
    }

    .m-xl-n9 {
        margin: -7.5rem !important;
    }

    .m-xl-n10 {
        margin: -10rem !important;
    }

    .m-xl-n11 {
        margin: -12.5rem !important;
    }

    .m-xl-n12 {
        margin: -15rem !important;
    }

    .mx-xl-n1 {
        margin-right: -0.25rem !important;
        margin-left: -0.25rem !important;
    }

    .mx-xl-n2 {
        margin-right: -0.5rem !important;
        margin-left: -0.5rem !important;
    }

    .mx-xl-n3 {
        margin-right: -1rem !important;
        margin-left: -1rem !important;
    }

    .mx-xl-n4 {
        margin-right: -1.8rem !important;
        margin-left: -1.8rem !important;
    }

    .mx-xl-n5 {
        margin-right: -2.5rem !important;
        margin-left: -2.5rem !important;
    }

    .mx-xl-n6 {
        margin-right: -3rem !important;
        margin-left: -3rem !important;
    }

    .mx-xl-n7 {
        margin-right: -4rem !important;
        margin-left: -4rem !important;
    }

    .mx-xl-n8 {
        margin-right: -5rem !important;
        margin-left: -5rem !important;
    }

    .mx-xl-n9 {
        margin-right: -7.5rem !important;
        margin-left: -7.5rem !important;
    }

    .mx-xl-n10 {
        margin-right: -10rem !important;
        margin-left: -10rem !important;
    }

    .mx-xl-n11 {
        margin-right: -12.5rem !important;
        margin-left: -12.5rem !important;
    }

    .mx-xl-n12 {
        margin-right: -15rem !important;
        margin-left: -15rem !important;
    }

    .my-xl-n1 {
        margin-top: -0.25rem !important;
        margin-bottom: -0.25rem !important;
    }

    .my-xl-n2 {
        margin-top: -0.5rem !important;
        margin-bottom: -0.5rem !important;
    }

    .my-xl-n3 {
        margin-top: -1rem !important;
        margin-bottom: -1rem !important;
    }

    .my-xl-n4 {
        margin-top: -1.8rem !important;
        margin-bottom: -1.8rem !important;
    }

    .my-xl-n5 {
        margin-top: -2.5rem !important;
        margin-bottom: -2.5rem !important;
    }

    .my-xl-n6 {
        margin-top: -3rem !important;
        margin-bottom: -3rem !important;
    }

    .my-xl-n7 {
        margin-top: -4rem !important;
        margin-bottom: -4rem !important;
    }

    .my-xl-n8 {
        margin-top: -5rem !important;
        margin-bottom: -5rem !important;
    }

    .my-xl-n9 {
        margin-top: -7.5rem !important;
        margin-bottom: -7.5rem !important;
    }

    .my-xl-n10 {
        margin-top: -10rem !important;
        margin-bottom: -10rem !important;
    }

    .my-xl-n11 {
        margin-top: -12.5rem !important;
        margin-bottom: -12.5rem !important;
    }

    .my-xl-n12 {
        margin-top: -15rem !important;
        margin-bottom: -15rem !important;
    }

    .mt-xl-n1 {
        margin-top: -0.25rem !important;
    }

    .mt-xl-n2 {
        margin-top: -0.5rem !important;
    }

    .mt-xl-n3 {
        margin-top: -1rem !important;
    }

    .mt-xl-n4 {
        margin-top: -1.8rem !important;
    }

    .mt-xl-n5 {
        margin-top: -2.5rem !important;
    }

    .mt-xl-n6 {
        margin-top: -3rem !important;
    }

    .mt-xl-n7 {
        margin-top: -4rem !important;
    }

    .mt-xl-n8 {
        margin-top: -5rem !important;
    }

    .mt-xl-n9 {
        margin-top: -7.5rem !important;
    }

    .mt-xl-n10 {
        margin-top: -10rem !important;
    }

    .mt-xl-n11 {
        margin-top: -12.5rem !important;
    }

    .mt-xl-n12 {
        margin-top: -15rem !important;
    }

    .me-xl-n1 {
        margin-right: -0.25rem !important;
    }

    .me-xl-n2 {
        margin-right: -0.5rem !important;
    }

    .me-xl-n3 {
        margin-right: -1rem !important;
    }

    .me-xl-n4 {
        margin-right: -1.8rem !important;
    }

    .me-xl-n5 {
        margin-right: -2.5rem !important;
    }

    .me-xl-n6 {
        margin-right: -3rem !important;
    }

    .me-xl-n7 {
        margin-right: -4rem !important;
    }

    .me-xl-n8 {
        margin-right: -5rem !important;
    }

    .me-xl-n9 {
        margin-right: -7.5rem !important;
    }

    .me-xl-n10 {
        margin-right: -10rem !important;
    }

    .me-xl-n11 {
        margin-right: -12.5rem !important;
    }

    .me-xl-n12 {
        margin-right: -15rem !important;
    }

    .mb-xl-n1 {
        margin-bottom: -0.25rem !important;
    }

    .mb-xl-n2 {
        margin-bottom: -0.5rem !important;
    }

    .mb-xl-n3 {
        margin-bottom: -1rem !important;
    }

    .mb-xl-n4 {
        margin-bottom: -1.8rem !important;
    }

    .mb-xl-n5 {
        margin-bottom: -2.5rem !important;
    }

    .mb-xl-n6 {
        margin-bottom: -3rem !important;
    }

    .mb-xl-n7 {
        margin-bottom: -4rem !important;
    }

    .mb-xl-n8 {
        margin-bottom: -5rem !important;
    }

    .mb-xl-n9 {
        margin-bottom: -7.5rem !important;
    }

    .mb-xl-n10 {
        margin-bottom: -10rem !important;
    }

    .mb-xl-n11 {
        margin-bottom: -12.5rem !important;
    }

    .mb-xl-n12 {
        margin-bottom: -15rem !important;
    }

    .ms-xl-n1 {
        margin-left: -0.25rem !important;
    }

    .ms-xl-n2 {
        margin-left: -0.5rem !important;
    }

    .ms-xl-n3 {
        margin-left: -1rem !important;
    }

    .ms-xl-n4 {
        margin-left: -1.8rem !important;
    }

    .ms-xl-n5 {
        margin-left: -2.5rem !important;
    }

    .ms-xl-n6 {
        margin-left: -3rem !important;
    }

    .ms-xl-n7 {
        margin-left: -4rem !important;
    }

    .ms-xl-n8 {
        margin-left: -5rem !important;
    }

    .ms-xl-n9 {
        margin-left: -7.5rem !important;
    }

    .ms-xl-n10 {
        margin-left: -10rem !important;
    }

    .ms-xl-n11 {
        margin-left: -12.5rem !important;
    }

    .ms-xl-n12 {
        margin-left: -15rem !important;
    }

    .p-xl-0 {
        padding: 0 !important;
    }

    .p-xl-1 {
        padding: 0.25rem !important;
    }

    .p-xl-2 {
        padding: 0.5rem !important;
    }

    .p-xl-3 {
        padding: 1rem !important;
    }

    .p-xl-4 {
        padding: 1.8rem !important;
    }

    .p-xl-5 {
        padding: 2.5rem !important;
    }

    .p-xl-6 {
        padding: 3rem !important;
    }

    .p-xl-7 {
        padding: 4rem !important;
    }

    .p-xl-8 {
        padding: 5rem !important;
    }

    .p-xl-9 {
        padding: 7.5rem !important;
    }

    .p-xl-10 {
        padding: 10rem !important;
    }

    .p-xl-11 {
        padding: 12.5rem !important;
    }

    .p-xl-12 {
        padding: 15rem !important;
    }

    .px-xl-0 {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }

    .px-xl-1 {
        padding-right: 0.25rem !important;
        padding-left: 0.25rem !important;
    }

    .px-xl-2 {
        padding-right: 0.5rem !important;
        padding-left: 0.5rem !important;
    }

    .px-xl-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    }

    .px-xl-4 {
        padding-right: 1.8rem !important;
        padding-left: 1.8rem !important;
    }

    .px-xl-5 {
        padding-right: 2.5rem !important;
        padding-left: 2.5rem !important;
    }

    .px-xl-6 {
        padding-right: 3rem !important;
        padding-left: 3rem !important;
    }

    .px-xl-7 {
        padding-right: 4rem !important;
        padding-left: 4rem !important;
    }

    .px-xl-8 {
        padding-right: 5rem !important;
        padding-left: 5rem !important;
    }

    .px-xl-9 {
        padding-right: 7.5rem !important;
        padding-left: 7.5rem !important;
    }

    .px-xl-10 {
        padding-right: 10rem !important;
        padding-left: 10rem !important;
    }

    .px-xl-11 {
        padding-right: 12.5rem !important;
        padding-left: 12.5rem !important;
    }

    .px-xl-12 {
        padding-right: 15rem !important;
        padding-left: 15rem !important;
    }

    .py-xl-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    .py-xl-1 {
        padding-top: 0.25rem !important;
        padding-bottom: 0.25rem !important;
    }

    .py-xl-2 {
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
    }

    .py-xl-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
    }

    .py-xl-4 {
        padding-top: 1.8rem !important;
        padding-bottom: 1.8rem !important;
    }

    .py-xl-5 {
        padding-top: 2.5rem !important;
        padding-bottom: 2.5rem !important;
    }

    .py-xl-6 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important;
    }

    .py-xl-7 {
        padding-top: 4rem !important;
        padding-bottom: 4rem !important;
    }

    .py-xl-8 {
        padding-top: 5rem !important;
        padding-bottom: 5rem !important;
    }

    .py-xl-9 {
        padding-top: 7.5rem !important;
        padding-bottom: 7.5rem !important;
    }

    .py-xl-10 {
        padding-top: 10rem !important;
        padding-bottom: 10rem !important;
    }

    .py-xl-11 {
        padding-top: 12.5rem !important;
        padding-bottom: 12.5rem !important;
    }

    .py-xl-12 {
        padding-top: 15rem !important;
        padding-bottom: 15rem !important;
    }

    .pt-xl-0 {
        padding-top: 0 !important;
    }

    .pt-xl-1 {
        padding-top: 0.25rem !important;
    }

    .pt-xl-2 {
        padding-top: 0.5rem !important;
    }

    .pt-xl-3 {
        padding-top: 1rem !important;
    }

    .pt-xl-4 {
        padding-top: 1.8rem !important;
    }

    .pt-xl-5 {
        padding-top: 2.5rem !important;
    }

    .pt-xl-6 {
        padding-top: 3rem !important;
    }

    .pt-xl-7 {
        padding-top: 4rem !important;
    }

    .pt-xl-8 {
        padding-top: 5rem !important;
    }

    .pt-xl-9 {
        padding-top: 7.5rem !important;
    }

    .pt-xl-10 {
        padding-top: 10rem !important;
    }

    .pt-xl-11 {
        padding-top: 12.5rem !important;
    }

    .pt-xl-12 {
        padding-top: 15rem !important;
    }

    .pe-xl-0 {
        padding-right: 0 !important;
    }

    .pe-xl-1 {
        padding-right: 0.25rem !important;
    }

    .pe-xl-2 {
        padding-right: 0.5rem !important;
    }

    .pe-xl-3 {
        padding-right: 1rem !important;
    }

    .pe-xl-4 {
        padding-right: 1.8rem !important;
    }

    .pe-xl-5 {
        padding-right: 2.5rem !important;
    }

    .pe-xl-6 {
        padding-right: 3rem !important;
    }

    .pe-xl-7 {
        padding-right: 4rem !important;
    }

    .pe-xl-8 {
        padding-right: 5rem !important;
    }

    .pe-xl-9 {
        padding-right: 7.5rem !important;
    }

    .pe-xl-10 {
        padding-right: 10rem !important;
    }

    .pe-xl-11 {
        padding-right: 12.5rem !important;
    }

    .pe-xl-12 {
        padding-right: 15rem !important;
    }

    .pb-xl-0 {
        padding-bottom: 0 !important;
    }

    .pb-xl-1 {
        padding-bottom: 0.25rem !important;
    }

    .pb-xl-2 {
        padding-bottom: 0.5rem !important;
    }

    .pb-xl-3 {
        padding-bottom: 1rem !important;
    }

    .pb-xl-4 {
        padding-bottom: 1.8rem !important;
    }

    .pb-xl-5 {
        padding-bottom: 2.5rem !important;
    }

    .pb-xl-6 {
        padding-bottom: 3rem !important;
    }

    .pb-xl-7 {
        padding-bottom: 4rem !important;
    }

    .pb-xl-8 {
        padding-bottom: 5rem !important;
    }

    .pb-xl-9 {
        padding-bottom: 7.5rem !important;
    }

    .pb-xl-10 {
        padding-bottom: 10rem !important;
    }

    .pb-xl-11 {
        padding-bottom: 12.5rem !important;
    }

    .pb-xl-12 {
        padding-bottom: 15rem !important;
    }

    .ps-xl-0 {
        padding-left: 0 !important;
    }

    .ps-xl-1 {
        padding-left: 0.25rem !important;
    }

    .ps-xl-2 {
        padding-left: 0.5rem !important;
    }

    .ps-xl-3 {
        padding-left: 1rem !important;
    }

    .ps-xl-4 {
        padding-left: 1.8rem !important;
    }

    .ps-xl-5 {
        padding-left: 2.5rem !important;
    }

    .ps-xl-6 {
        padding-left: 3rem !important;
    }

    .ps-xl-7 {
        padding-left: 4rem !important;
    }

    .ps-xl-8 {
        padding-left: 5rem !important;
    }

    .ps-xl-9 {
        padding-left: 7.5rem !important;
    }

    .ps-xl-10 {
        padding-left: 10rem !important;
    }

    .ps-xl-11 {
        padding-left: 12.5rem !important;
    }

    .ps-xl-12 {
        padding-left: 15rem !important;
    }

    .fs-xl--2 {
        font-size: 0.79012rem !important;
    }

    .fs-xl--1 {
        font-size: 0.88889rem !important;
    }

    .fs-xl-0 {
        font-size: 1rem !important;
    }

    .fs-xl-1 {
        font-size: 1.125rem !important;
    }

    .fs-xl-2 {
        font-size: 1.26563rem !important;
    }

    .fs-xl-3 {
        font-size: 1.42383rem !important;
    }

    .fs-xl-4 {
        font-size: 1.60181rem !important;
    }

    .fs-xl-5 {
        font-size: 1.80203rem !important;
    }

    .fs-xl-6 {
        font-size: 2.02729rem !important;
    }

    .fs-xl-7 {
        font-size: 2.2807rem !important;
    }

    .fs-xl-8 {
        font-size: 2.56578rem !important;
    }

    .fs-xl-9 {
        font-size: 2.88651rem !important;
    }

    .fs-xl-10 {
        font-size: 3.24732rem !important;
    }

    .fs-xl-11 {
        font-size: 3.65324rem !important;
    }

    .fs-xl-12 {
        font-size: 4.10989rem !important;
    }

    .fs-xl-13 {
        font-size: 4.62363rem !important;
    }

    .fs-xl-14 {
        font-size: 5.20158rem !important;
    }

    .lh-xl-unset {
        line-height: unset !important;
    }

    .lh-xl-1 {
        line-height: 1 !important;
    }

    .lh-xl-1-3 {
        line-height: 1.3 !important;
    }

    .lh-xl-1-4 {
        line-height: 1.4 !important;
    }

    .lh-xl-1-5 {
        line-height: 1.5 !important;
    }

    .lh-xl-1-6 {
        line-height: 1.6 !important;
    }

    .lh-xl-1-7 {
        line-height: 1.7 !important;
    }

    .lh-xl-1-8 {
        line-height: 1.8 !important;
    }

    .lh-xl-1-9 {
        line-height: 1.9 !important;
    }

    .lh-xl-2 {
        line-height: 2 !important;
    }

    .text-xl-start {
        text-align: left !important;
    }

    .text-xl-end {
        text-align: right !important;
    }

    .text-xl-center {
        text-align: center !important;
    }

    .rounded-xl-top {
        border-top-left-radius: 1.5rem !important;
        border-top-right-radius: 1.5rem !important;
    }

    .rounded-xl-top-lg {
        border-top-left-radius: 2.25rem !important;
        border-top-right-radius: 2.25rem !important;
    }

    .rounded-xl-top-0 {
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
    }

    .rounded-xl-end {
        border-top-right-radius: 1.5rem !important;
        border-bottom-right-radius: 1.5rem !important;
    }

    .rounded-xl-end-lg {
        border-top-right-radius: 2.25rem !important;
        border-bottom-right-radius: 2.25rem !important;
    }

    .rounded-xl-end-0 {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }

    .rounded-xl-bottom {
        border-bottom-right-radius: 1.5rem !important;
        border-bottom-left-radius: 1.5rem !important;
    }

    .rounded-xl-bottom-lg {
        border-bottom-right-radius: 2.25rem !important;
        border-bottom-left-radius: 2.25rem !important;
    }

    .rounded-xl-bottom-0 {
        border-bottom-right-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }

    .rounded-xl-start {
        border-bottom-left-radius: 1.5rem !important;
        border-top-left-radius: 1.5rem !important;
    }

    .rounded-xl-start-lg {
        border-bottom-left-radius: 2.25rem !important;
        border-top-left-radius: 2.25rem !important;
    }

    .rounded-xl-start-0 {
        border-bottom-left-radius: 0 !important;
        border-top-left-radius: 0 !important;
    }

    .opacity-xl-0 {
        opacity: 0 !important;
    }

    .opacity-xl-25 {
        opacity: 0.25 !important;
    }

    .opacity-xl-50 {
        opacity: 0.5 !important;
    }

    .opacity-xl-75 {
        opacity: 0.75 !important;
    }

    .opacity-xl-85 {
        opacity: 0.85 !important;
    }

    .opacity-xl-100 {
        opacity: 1 !important;
    }

    .max-vh-xl-25 {
        max-height: 25vh !important;
    }

    .max-vh-xl-50 {
        max-height: 50vh !important;
    }

    .max-vh-xl-75 {
        max-height: 75vh !important;
    }

    .max-vh-xl-100 {
        max-height: 100vh !important;
    }

    .border-xl-x {
        border-left: 1px solid #eeeeee !important;
        border-right: 1px solid #eeeeee !important;
    }

    .border-xl-x-0 {
        border-left: 0 !important;
        border-right: 0 !important;
    }

    .border-xl-y {
        border-top: 1px solid #eeeeee !important;
        border-bottom: 1px solid #eeeeee !important;
    }

    .border-xl-y-0 {
        border-top: 0 !important;
        border-bottom: 0 !important;
    }

    .border-xl-dashed {
        border: 1px dashed #eeeeee !important;
    }

    .border-xl-dashed-top {
        border-top: 1px dashed #eeeeee !important;
    }

    .border-xl-dashed-end {
        border-right: 1px dashed #eeeeee !important;
    }

    .border-xl-dashed-start {
        border-left: 1px dashed #eeeeee !important;
    }

    .border-xl-dashed-bottom {
        border-bottom: 1px dashed #eeeeee !important;
    }

    .border-xl-dashed-x {
        border-left: 1px dashed #eeeeee !important;
        border-right: 1px dashed #eeeeee !important;
    }

    .border-xl-dashed-y {
        border-top: 1px dashed #eeeeee !important;
        border-bottom: 1px dashed #eeeeee !important;
    }

    .rounded-xl-0 {
        border-radius: 0 !important;
    }
}

@media (min-width: 1400px) {
    .float-xxl-start {
        float: left !important;
    }

    .float-xxl-end {
        float: right !important;
    }

    .float-xxl-none {
        float: none !important;
    }

    .overflow-xxl-unset {
        overflow: unset !important;
    }

    .overflow-xxl-auto {
        overflow: auto !important;
    }

    .overflow-xxl-hidden {
        overflow: hidden !important;
    }

    .overflow-xxl-visible {
        overflow: visible !important;
    }

    .overflow-xxl-scroll {
        overflow: scroll !important;
    }

    .d-xxl-inline {
        display: inline !important;
    }

    .d-xxl-inline-block {
        display: inline-block !important;
    }

    .d-xxl-block {
        display: block !important;
    }

    .d-xxl-grid {
        display: grid !important;
    }

    .d-xxl-table {
        display: table !important;
    }

    .d-xxl-table-row {
        display: table-row !important;
    }

    .d-xxl-table-cell {
        display: table-cell !important;
    }

    .d-xxl-flex {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
    }

    .d-xxl-inline-flex {
        display: -webkit-inline-box !important;
        display: -ms-inline-flexbox !important;
        display: inline-flex !important;
    }

    .d-xxl-none {
        display: none !important;
    }

    .position-xxl-static {
        position: static !important;
    }

    .position-xxl-absolute {
        position: absolute !important;
    }

    .position-xxl-relative {
        position: relative !important;
    }

    .position-xxl-fixed {
        position: fixed !important;
    }

    .position-xxl-sticky {
        position: -webkit-sticky !important;
        position: sticky !important;
    }

    .top-xxl-unset {
        top: unset !important;
    }

    .top-xxl-0 {
        top: 0 !important;
    }

    .top-xxl-50 {
        top: 50% !important;
    }

    .top-xxl-100 {
        top: 100% !important;
    }

    .bottom-xxl-unset {
        bottom: unset !important;
    }

    .bottom-xxl-0 {
        bottom: 0 !important;
    }

    .bottom-xxl-50 {
        bottom: 50% !important;
    }

    .bottom-xxl-100 {
        bottom: 100% !important;
    }

    .start-xxl-unset {
        left: unset !important;
    }

    .start-xxl-0 {
        left: 0 !important;
    }

    .start-xxl-50 {
        left: 50% !important;
    }

    .start-xxl-100 {
        left: 100% !important;
    }

    .end-xxl-unset {
        right: unset !important;
    }

    .end-xxl-0 {
        right: 0 !important;
    }

    .end-xxl-50 {
        right: 50% !important;
    }

    .end-xxl-100 {
        right: 100% !important;
    }

    .translate-xxl-middle {
        -webkit-transform: translateX(-50%) translateY(-50%) !important;
        -ms-transform: translateX(-50%) translateY(-50%) !important;
        transform: translateX(-50%) translateY(-50%) !important;
    }

    .translate-xxl-middle-x {
        -webkit-transform: translateX(-50%) !important;
        -ms-transform: translateX(-50%) !important;
        transform: translateX(-50%) !important;
    }

    .translate-xxl-middle-y {
        -webkit-transform: translateY(-50%) !important;
        -ms-transform: translateY(-50%) !important;
        transform: translateY(-50%) !important;
    }

    .border-xxl {
        border: 1px solid #eeeeee !important;
    }

    .border-xxl-0 {
        border: 0 !important;
    }

    .border-xxl-top {
        border-top: 1px solid #eeeeee !important;
    }

    .border-xxl-top-0 {
        border-top: 0 !important;
    }

    .border-xxl-end {
        border-right: 1px solid #eeeeee !important;
    }

    .border-xxl-end-0 {
        border-right: 0 !important;
    }

    .border-xxl-bottom {
        border-bottom: 1px solid #eeeeee !important;
    }

    .border-xxl-bottom-0 {
        border-bottom: 0 !important;
    }

    .border-xxl-start {
        border-left: 1px solid #eeeeee !important;
    }

    .border-xxl-start-0 {
        border-left: 0 !important;
    }

    .border-xxl-facebook {
        border-color: #3c5a99 !important;
    }

    .border-xxl-google-plus {
        border-color: #dd4b39 !important;
    }

    .border-xxl-twitter {
        border-color: #1da1f2 !important;
    }

    .border-xxl-linkedin {
        border-color: #0077b5 !important;
    }

    .border-xxl-youtube {
        border-color: #ff0000 !important;
    }

    .border-xxl-github {
        border-color: #333 !important;
    }

    .border-xxl-black {
        border-color: #000 !important;
    }

    .border-xxl-100 {
        border-color: #fcfcfc !important;
    }

    .border-xxl-200 {
        border-color: #f5f5f5 !important;
    }

    .border-xxl-300 {
        border-color: #eeeeee !important;
    }

    .border-xxl-400 {
        border-color: #e0e0e0 !important;
    }

    .border-xxl-500 {
        border-color: #bdbdbd !important;
    }

    .border-xxl-600 {
        border-color: #f0f4f9 !important;
    }

    .border-xxl-700 {
        border-color: #757575 !important;
    }

    .border-xxl-800 {
        border-color: #616161 !important;
    }

    .border-xxl-900 {
        border-color: #5e6282 !important;
    }

    .border-xxl-1000 {
        border-color: #212832 !important;
    }

    .border-xxl-white {
        border-color: #fffefe !important;
    }

    .border-xxl-primary {
        border-color: #f1a501 !important;
    }

    .border-xxl-secondary {
        border-color: #5e6282 !important;
    }

    .border-xxl-success {
        border-color: #79b93c !important;
    }

    .border-xxl-info {
        border-color: #006380 !important;
    }

    .border-xxl-warning {
        border-color: #f1a501 !important;
    }

    .border-xxl-danger {
        border-color: #df6951 !important;
    }

    .border-xxl-light {
        border-color: #f0f4f9 !important;
    }

    .border-xxl-dark {
        border-color: #212832 !important;
    }

    .w-xxl-25 {
        width: 25% !important;
    }

    .w-xxl-50 {
        width: 50% !important;
    }

    .w-xxl-75 {
        width: 75% !important;
    }

    .w-xxl-100 {
        width: 100% !important;
    }

    .w-xxl-auto {
        width: auto !important;
    }

    .vw-xxl-25 {
        width: 25vw !important;
    }

    .vw-xxl-50 {
        width: 50vw !important;
    }

    .vw-xxl-75 {
        width: 75vw !important;
    }

    .vw-xxl-100 {
        width: 100vw !important;
    }

    .h-xxl-25 {
        height: 25% !important;
    }

    .h-xxl-50 {
        height: 50% !important;
    }

    .h-xxl-75 {
        height: 75% !important;
    }

    .h-xxl-100 {
        height: 100% !important;
    }

    .h-xxl-auto {
        height: auto !important;
    }

    .vh-xxl-25 {
        height: 25vh !important;
    }

    .vh-xxl-50 {
        height: 50vh !important;
    }

    .vh-xxl-75 {
        height: 75vh !important;
    }

    .vh-xxl-100 {
        height: 100vh !important;
    }

    .min-vh-xxl-25 {
        min-height: 25vh !important;
    }

    .min-vh-xxl-50 {
        min-height: 50vh !important;
    }

    .min-vh-xxl-75 {
        min-height: 75vh !important;
    }

    .min-vh-xxl-100 {
        min-height: 100vh !important;
    }

    .flex-xxl-fill {
        -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important;
    }

    .flex-xxl-row {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
        flex-direction: row !important;
    }

    .flex-xxl-column {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
        flex-direction: column !important;
    }

    .flex-xxl-row-reverse {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important;
    }

    .flex-xxl-column-reverse {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important;
    }

    .flex-xxl-grow-0 {
        -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
        flex-grow: 0 !important;
    }

    .flex-xxl-grow-1 {
        -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
        flex-grow: 1 !important;
    }

    .flex-xxl-shrink-0 {
        -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
    }

    .flex-xxl-shrink-1 {
        -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
    }

    .flex-xxl-wrap {
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
    }

    .flex-xxl-nowrap {
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
    }

    .flex-xxl-wrap-reverse {
        -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
    }

    .gap-xxl-0 {
        gap: 0 !important;
    }

    .gap-xxl-1 {
        gap: 0.25rem !important;
    }

    .gap-xxl-2 {
        gap: 0.5rem !important;
    }

    .gap-xxl-3 {
        gap: 1rem !important;
    }

    .gap-xxl-4 {
        gap: 1.8rem !important;
    }

    .gap-xxl-5 {
        gap: 2.5rem !important;
    }

    .gap-xxl-6 {
        gap: 3rem !important;
    }

    .gap-xxl-7 {
        gap: 4rem !important;
    }

    .gap-xxl-8 {
        gap: 5rem !important;
    }

    .gap-xxl-9 {
        gap: 7.5rem !important;
    }

    .gap-xxl-10 {
        gap: 10rem !important;
    }

    .gap-xxl-11 {
        gap: 12.5rem !important;
    }

    .gap-xxl-12 {
        gap: 15rem !important;
    }

    .justify-content-xxl-start {
        -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
        justify-content: flex-start !important;
    }

    .justify-content-xxl-end {
        -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
        justify-content: flex-end !important;
    }

    .justify-content-xxl-center {
        -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
        justify-content: center !important;
    }

    .justify-content-xxl-between {
        -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
        justify-content: space-between !important;
    }

    .justify-content-xxl-around {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
    }

    .justify-content-xxl-evenly {
        -webkit-box-pack: space-evenly !important;
        -ms-flex-pack: space-evenly !important;
        justify-content: space-evenly !important;
    }

    .align-items-xxl-start {
        -webkit-box-align: start !important;
        -ms-flex-align: start !important;
        align-items: flex-start !important;
    }

    .align-items-xxl-end {
        -webkit-box-align: end !important;
        -ms-flex-align: end !important;
        align-items: flex-end !important;
    }

    .align-items-xxl-center {
        -webkit-box-align: center !important;
        -ms-flex-align: center !important;
        align-items: center !important;
    }

    .align-items-xxl-baseline {
        -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
        align-items: baseline !important;
    }

    .align-items-xxl-stretch {
        -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
        align-items: stretch !important;
    }

    .align-content-xxl-start {
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
    }

    .align-content-xxl-end {
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
    }

    .align-content-xxl-center {
        -ms-flex-line-pack: center !important;
        align-content: center !important;
    }

    .align-content-xxl-between {
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
    }

    .align-content-xxl-around {
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
    }

    .align-content-xxl-stretch {
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
    }

    .align-self-xxl-auto {
        -ms-flex-item-align: auto !important;
        align-self: auto !important;
    }

    .align-self-xxl-start {
        -ms-flex-item-align: start !important;
        align-self: flex-start !important;
    }

    .align-self-xxl-end {
        -ms-flex-item-align: end !important;
        align-self: flex-end !important;
    }

    .align-self-xxl-center {
        -ms-flex-item-align: center !important;
        align-self: center !important;
    }

    .align-self-xxl-baseline {
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
    }

    .align-self-xxl-stretch {
        -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
    }

    .order-xxl-first {
        -webkit-box-ordinal-group: 0 !important;
        -ms-flex-order: -1 !important;
        order: -1 !important;
    }

    .order-xxl-0 {
        -webkit-box-ordinal-group: 1 !important;
        -ms-flex-order: 0 !important;
        order: 0 !important;
    }

    .order-xxl-1 {
        -webkit-box-ordinal-group: 2 !important;
        -ms-flex-order: 1 !important;
        order: 1 !important;
    }

    .order-xxl-2 {
        -webkit-box-ordinal-group: 3 !important;
        -ms-flex-order: 2 !important;
        order: 2 !important;
    }

    .order-xxl-3 {
        -webkit-box-ordinal-group: 4 !important;
        -ms-flex-order: 3 !important;
        order: 3 !important;
    }

    .order-xxl-4 {
        -webkit-box-ordinal-group: 5 !important;
        -ms-flex-order: 4 !important;
        order: 4 !important;
    }

    .order-xxl-5 {
        -webkit-box-ordinal-group: 6 !important;
        -ms-flex-order: 5 !important;
        order: 5 !important;
    }

    .order-xxl-last {
        -webkit-box-ordinal-group: 7 !important;
        -ms-flex-order: 6 !important;
        order: 6 !important;
    }

    .m-xxl-0 {
        margin: 0 !important;
    }

    .m-xxl-1 {
        margin: 0.25rem !important;
    }

    .m-xxl-2 {
        margin: 0.5rem !important;
    }

    .m-xxl-3 {
        margin: 1rem !important;
    }

    .m-xxl-4 {
        margin: 1.8rem !important;
    }

    .m-xxl-5 {
        margin: 2.5rem !important;
    }

    .m-xxl-6 {
        margin: 3rem !important;
    }

    .m-xxl-7 {
        margin: 4rem !important;
    }

    .m-xxl-8 {
        margin: 5rem !important;
    }

    .m-xxl-9 {
        margin: 7.5rem !important;
    }

    .m-xxl-10 {
        margin: 10rem !important;
    }

    .m-xxl-11 {
        margin: 12.5rem !important;
    }

    .m-xxl-12 {
        margin: 15rem !important;
    }

    .m-xxl-auto {
        margin: auto !important;
    }

    .mx-xxl-0 {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }

    .mx-xxl-1 {
        margin-right: 0.25rem !important;
        margin-left: 0.25rem !important;
    }

    .mx-xxl-2 {
        margin-right: 0.5rem !important;
        margin-left: 0.5rem !important;
    }

    .mx-xxl-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important;
    }

    .mx-xxl-4 {
        margin-right: 1.8rem !important;
        margin-left: 1.8rem !important;
    }

    .mx-xxl-5 {
        margin-right: 2.5rem !important;
        margin-left: 2.5rem !important;
    }

    .mx-xxl-6 {
        margin-right: 3rem !important;
        margin-left: 3rem !important;
    }

    .mx-xxl-7 {
        margin-right: 4rem !important;
        margin-left: 4rem !important;
    }

    .mx-xxl-8 {
        margin-right: 5rem !important;
        margin-left: 5rem !important;
    }

    .mx-xxl-9 {
        margin-right: 7.5rem !important;
        margin-left: 7.5rem !important;
    }

    .mx-xxl-10 {
        margin-right: 10rem !important;
        margin-left: 10rem !important;
    }

    .mx-xxl-11 {
        margin-right: 12.5rem !important;
        margin-left: 12.5rem !important;
    }

    .mx-xxl-12 {
        margin-right: 15rem !important;
        margin-left: 15rem !important;
    }

    .mx-xxl-auto {
        margin-right: auto !important;
        margin-left: auto !important;
    }

    .my-xxl-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }

    .my-xxl-1 {
        margin-top: 0.25rem !important;
        margin-bottom: 0.25rem !important;
    }

    .my-xxl-2 {
        margin-top: 0.5rem !important;
        margin-bottom: 0.5rem !important;
    }

    .my-xxl-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
    }

    .my-xxl-4 {
        margin-top: 1.8rem !important;
        margin-bottom: 1.8rem !important;
    }

    .my-xxl-5 {
        margin-top: 2.5rem !important;
        margin-bottom: 2.5rem !important;
    }

    .my-xxl-6 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important;
    }

    .my-xxl-7 {
        margin-top: 4rem !important;
        margin-bottom: 4rem !important;
    }

    .my-xxl-8 {
        margin-top: 5rem !important;
        margin-bottom: 5rem !important;
    }

    .my-xxl-9 {
        margin-top: 7.5rem !important;
        margin-bottom: 7.5rem !important;
    }

    .my-xxl-10 {
        margin-top: 10rem !important;
        margin-bottom: 10rem !important;
    }

    .my-xxl-11 {
        margin-top: 12.5rem !important;
        margin-bottom: 12.5rem !important;
    }

    .my-xxl-12 {
        margin-top: 15rem !important;
        margin-bottom: 15rem !important;
    }

    .my-xxl-auto {
        margin-top: auto !important;
        margin-bottom: auto !important;
    }

    .mt-xxl-0 {
        margin-top: 0 !important;
    }

    .mt-xxl-1 {
        margin-top: 0.25rem !important;
    }

    .mt-xxl-2 {
        margin-top: 0.5rem !important;
    }

    .mt-xxl-3 {
        margin-top: 1rem !important;
    }

    .mt-xxl-4 {
        margin-top: 1.8rem !important;
    }

    .mt-xxl-5 {
        margin-top: 2.5rem !important;
    }

    .mt-xxl-6 {
        margin-top: 3rem !important;
    }

    .mt-xxl-7 {
        margin-top: 4rem !important;
    }

    .mt-xxl-8 {
        margin-top: 5rem !important;
    }

    .mt-xxl-9 {
        margin-top: 7.5rem !important;
    }

    .mt-xxl-10 {
        margin-top: 10rem !important;
    }

    .mt-xxl-11 {
        margin-top: 12.5rem !important;
    }

    .mt-xxl-12 {
        margin-top: 15rem !important;
    }

    .mt-xxl-auto {
        margin-top: auto !important;
    }

    .me-xxl-0 {
        margin-right: 0 !important;
    }

    .me-xxl-1 {
        margin-right: 0.25rem !important;
    }

    .me-xxl-2 {
        margin-right: 0.5rem !important;
    }

    .me-xxl-3 {
        margin-right: 1rem !important;
    }

    .me-xxl-4 {
        margin-right: 1.8rem !important;
    }

    .me-xxl-5 {
        margin-right: 2.5rem !important;
    }

    .me-xxl-6 {
        margin-right: 3rem !important;
    }

    .me-xxl-7 {
        margin-right: 4rem !important;
    }

    .me-xxl-8 {
        margin-right: 5rem !important;
    }

    .me-xxl-9 {
        margin-right: 7.5rem !important;
    }

    .me-xxl-10 {
        margin-right: 10rem !important;
    }

    .me-xxl-11 {
        margin-right: 12.5rem !important;
    }

    .me-xxl-12 {
        margin-right: 15rem !important;
    }

    .me-xxl-auto {
        margin-right: auto !important;
    }

    .mb-xxl-0 {
        margin-bottom: 0 !important;
    }

    .mb-xxl-1 {
        margin-bottom: 0.25rem !important;
    }

    .mb-xxl-2 {
        margin-bottom: 0.5rem !important;
    }

    .mb-xxl-3 {
        margin-bottom: 1rem !important;
    }

    .mb-xxl-4 {
        margin-bottom: 1.8rem !important;
    }

    .mb-xxl-5 {
        margin-bottom: 2.5rem !important;
    }

    .mb-xxl-6 {
        margin-bottom: 3rem !important;
    }

    .mb-xxl-7 {
        margin-bottom: 4rem !important;
    }

    .mb-xxl-8 {
        margin-bottom: 5rem !important;
    }

    .mb-xxl-9 {
        margin-bottom: 7.5rem !important;
    }

    .mb-xxl-10 {
        margin-bottom: 10rem !important;
    }

    .mb-xxl-11 {
        margin-bottom: 12.5rem !important;
    }

    .mb-xxl-12 {
        margin-bottom: 15rem !important;
    }

    .mb-xxl-auto {
        margin-bottom: auto !important;
    }

    .ms-xxl-0 {
        margin-left: 0 !important;
    }

    .ms-xxl-1 {
        margin-left: 0.25rem !important;
    }

    .ms-xxl-2 {
        margin-left: 0.5rem !important;
    }

    .ms-xxl-3 {
        margin-left: 1rem !important;
    }

    .ms-xxl-4 {
        margin-left: 1.8rem !important;
    }

    .ms-xxl-5 {
        margin-left: 2.5rem !important;
    }

    .ms-xxl-6 {
        margin-left: 3rem !important;
    }

    .ms-xxl-7 {
        margin-left: 4rem !important;
    }

    .ms-xxl-8 {
        margin-left: 5rem !important;
    }

    .ms-xxl-9 {
        margin-left: 7.5rem !important;
    }

    .ms-xxl-10 {
        margin-left: 10rem !important;
    }

    .ms-xxl-11 {
        margin-left: 12.5rem !important;
    }

    .ms-xxl-12 {
        margin-left: 15rem !important;
    }

    .ms-xxl-auto {
        margin-left: auto !important;
    }

    .m-xxl-n1 {
        margin: -0.25rem !important;
    }

    .m-xxl-n2 {
        margin: -0.5rem !important;
    }

    .m-xxl-n3 {
        margin: -1rem !important;
    }

    .m-xxl-n4 {
        margin: -1.8rem !important;
    }

    .m-xxl-n5 {
        margin: -2.5rem !important;
    }

    .m-xxl-n6 {
        margin: -3rem !important;
    }

    .m-xxl-n7 {
        margin: -4rem !important;
    }

    .m-xxl-n8 {
        margin: -5rem !important;
    }

    .m-xxl-n9 {
        margin: -7.5rem !important;
    }

    .m-xxl-n10 {
        margin: -10rem !important;
    }

    .m-xxl-n11 {
        margin: -12.5rem !important;
    }

    .m-xxl-n12 {
        margin: -15rem !important;
    }

    .mx-xxl-n1 {
        margin-right: -0.25rem !important;
        margin-left: -0.25rem !important;
    }

    .mx-xxl-n2 {
        margin-right: -0.5rem !important;
        margin-left: -0.5rem !important;
    }

    .mx-xxl-n3 {
        margin-right: -1rem !important;
        margin-left: -1rem !important;
    }

    .mx-xxl-n4 {
        margin-right: -1.8rem !important;
        margin-left: -1.8rem !important;
    }

    .mx-xxl-n5 {
        margin-right: -2.5rem !important;
        margin-left: -2.5rem !important;
    }

    .mx-xxl-n6 {
        margin-right: -3rem !important;
        margin-left: -3rem !important;
    }

    .mx-xxl-n7 {
        margin-right: -4rem !important;
        margin-left: -4rem !important;
    }

    .mx-xxl-n8 {
        margin-right: -5rem !important;
        margin-left: -5rem !important;
    }

    .mx-xxl-n9 {
        margin-right: -7.5rem !important;
        margin-left: -7.5rem !important;
    }

    .mx-xxl-n10 {
        margin-right: -10rem !important;
        margin-left: -10rem !important;
    }

    .mx-xxl-n11 {
        margin-right: -12.5rem !important;
        margin-left: -12.5rem !important;
    }

    .mx-xxl-n12 {
        margin-right: -15rem !important;
        margin-left: -15rem !important;
    }

    .my-xxl-n1 {
        margin-top: -0.25rem !important;
        margin-bottom: -0.25rem !important;
    }

    .my-xxl-n2 {
        margin-top: -0.5rem !important;
        margin-bottom: -0.5rem !important;
    }

    .my-xxl-n3 {
        margin-top: -1rem !important;
        margin-bottom: -1rem !important;
    }

    .my-xxl-n4 {
        margin-top: -1.8rem !important;
        margin-bottom: -1.8rem !important;
    }

    .my-xxl-n5 {
        margin-top: -2.5rem !important;
        margin-bottom: -2.5rem !important;
    }

    .my-xxl-n6 {
        margin-top: -3rem !important;
        margin-bottom: -3rem !important;
    }

    .my-xxl-n7 {
        margin-top: -4rem !important;
        margin-bottom: -4rem !important;
    }

    .my-xxl-n8 {
        margin-top: -5rem !important;
        margin-bottom: -5rem !important;
    }

    .my-xxl-n9 {
        margin-top: -7.5rem !important;
        margin-bottom: -7.5rem !important;
    }

    .my-xxl-n10 {
        margin-top: -10rem !important;
        margin-bottom: -10rem !important;
    }

    .my-xxl-n11 {
        margin-top: -12.5rem !important;
        margin-bottom: -12.5rem !important;
    }

    .my-xxl-n12 {
        margin-top: -15rem !important;
        margin-bottom: -15rem !important;
    }

    .mt-xxl-n1 {
        margin-top: -0.25rem !important;
    }

    .mt-xxl-n2 {
        margin-top: -0.5rem !important;
    }

    .mt-xxl-n3 {
        margin-top: -1rem !important;
    }

    .mt-xxl-n4 {
        margin-top: -1.8rem !important;
    }

    .mt-xxl-n5 {
        margin-top: -2.5rem !important;
    }

    .mt-xxl-n6 {
        margin-top: -3rem !important;
    }

    .mt-xxl-n7 {
        margin-top: -4rem !important;
    }

    .mt-xxl-n8 {
        margin-top: -5rem !important;
    }

    .mt-xxl-n9 {
        margin-top: -7.5rem !important;
    }

    .mt-xxl-n10 {
        margin-top: -10rem !important;
    }

    .mt-xxl-n11 {
        margin-top: -12.5rem !important;
    }

    .mt-xxl-n12 {
        margin-top: -15rem !important;
    }

    .me-xxl-n1 {
        margin-right: -0.25rem !important;
    }

    .me-xxl-n2 {
        margin-right: -0.5rem !important;
    }

    .me-xxl-n3 {
        margin-right: -1rem !important;
    }

    .me-xxl-n4 {
        margin-right: -1.8rem !important;
    }

    .me-xxl-n5 {
        margin-right: -2.5rem !important;
    }

    .me-xxl-n6 {
        margin-right: -3rem !important;
    }

    .me-xxl-n7 {
        margin-right: -4rem !important;
    }

    .me-xxl-n8 {
        margin-right: -5rem !important;
    }

    .me-xxl-n9 {
        margin-right: -7.5rem !important;
    }

    .me-xxl-n10 {
        margin-right: -10rem !important;
    }

    .me-xxl-n11 {
        margin-right: -12.5rem !important;
    }

    .me-xxl-n12 {
        margin-right: -15rem !important;
    }

    .mb-xxl-n1 {
        margin-bottom: -0.25rem !important;
    }

    .mb-xxl-n2 {
        margin-bottom: -0.5rem !important;
    }

    .mb-xxl-n3 {
        margin-bottom: -1rem !important;
    }

    .mb-xxl-n4 {
        margin-bottom: -1.8rem !important;
    }

    .mb-xxl-n5 {
        margin-bottom: -2.5rem !important;
    }

    .mb-xxl-n6 {
        margin-bottom: -3rem !important;
    }

    .mb-xxl-n7 {
        margin-bottom: -4rem !important;
    }

    .mb-xxl-n8 {
        margin-bottom: -5rem !important;
    }

    .mb-xxl-n9 {
        margin-bottom: -7.5rem !important;
    }

    .mb-xxl-n10 {
        margin-bottom: -10rem !important;
    }

    .mb-xxl-n11 {
        margin-bottom: -12.5rem !important;
    }

    .mb-xxl-n12 {
        margin-bottom: -15rem !important;
    }

    .ms-xxl-n1 {
        margin-left: -0.25rem !important;
    }

    .ms-xxl-n2 {
        margin-left: -0.5rem !important;
    }

    .ms-xxl-n3 {
        margin-left: -1rem !important;
    }

    .ms-xxl-n4 {
        margin-left: -1.8rem !important;
    }

    .ms-xxl-n5 {
        margin-left: -2.5rem !important;
    }

    .ms-xxl-n6 {
        margin-left: -3rem !important;
    }

    .ms-xxl-n7 {
        margin-left: -4rem !important;
    }

    .ms-xxl-n8 {
        margin-left: -5rem !important;
    }

    .ms-xxl-n9 {
        margin-left: -7.5rem !important;
    }

    .ms-xxl-n10 {
        margin-left: -10rem !important;
    }

    .ms-xxl-n11 {
        margin-left: -12.5rem !important;
    }

    .ms-xxl-n12 {
        margin-left: -15rem !important;
    }

    .p-xxl-0 {
        padding: 0 !important;
    }

    .p-xxl-1 {
        padding: 0.25rem !important;
    }

    .p-xxl-2 {
        padding: 0.5rem !important;
    }

    .p-xxl-3 {
        padding: 1rem !important;
    }

    .p-xxl-4 {
        padding: 1.8rem !important;
    }

    .p-xxl-5 {
        padding: 2.5rem !important;
    }

    .p-xxl-6 {
        padding: 3rem !important;
    }

    .p-xxl-7 {
        padding: 4rem !important;
    }

    .p-xxl-8 {
        padding: 5rem !important;
    }

    .p-xxl-9 {
        padding: 7.5rem !important;
    }

    .p-xxl-10 {
        padding: 10rem !important;
    }

    .p-xxl-11 {
        padding: 12.5rem !important;
    }

    .p-xxl-12 {
        padding: 15rem !important;
    }

    .px-xxl-0 {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }

    .px-xxl-1 {
        padding-right: 0.25rem !important;
        padding-left: 0.25rem !important;
    }

    .px-xxl-2 {
        padding-right: 0.5rem !important;
        padding-left: 0.5rem !important;
    }

    .px-xxl-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    }

    .px-xxl-4 {
        padding-right: 1.8rem !important;
        padding-left: 1.8rem !important;
    }

    .px-xxl-5 {
        padding-right: 2.5rem !important;
        padding-left: 2.5rem !important;
    }

    .px-xxl-6 {
        padding-right: 3rem !important;
        padding-left: 3rem !important;
    }

    .px-xxl-7 {
        padding-right: 4rem !important;
        padding-left: 4rem !important;
    }

    .px-xxl-8 {
        padding-right: 5rem !important;
        padding-left: 5rem !important;
    }

    .px-xxl-9 {
        padding-right: 7.5rem !important;
        padding-left: 7.5rem !important;
    }

    .px-xxl-10 {
        padding-right: 10rem !important;
        padding-left: 10rem !important;
    }

    .px-xxl-11 {
        padding-right: 12.5rem !important;
        padding-left: 12.5rem !important;
    }

    .px-xxl-12 {
        padding-right: 15rem !important;
        padding-left: 15rem !important;
    }

    .py-xxl-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    .py-xxl-1 {
        padding-top: 0.25rem !important;
        padding-bottom: 0.25rem !important;
    }

    .py-xxl-2 {
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
    }

    .py-xxl-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
    }

    .py-xxl-4 {
        padding-top: 1.8rem !important;
        padding-bottom: 1.8rem !important;
    }

    .py-xxl-5 {
        padding-top: 2.5rem !important;
        padding-bottom: 2.5rem !important;
    }

    .py-xxl-6 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important;
    }

    .py-xxl-7 {
        padding-top: 4rem !important;
        padding-bottom: 4rem !important;
    }

    .py-xxl-8 {
        padding-top: 5rem !important;
        padding-bottom: 5rem !important;
    }

    .py-xxl-9 {
        padding-top: 7.5rem !important;
        padding-bottom: 7.5rem !important;
    }

    .py-xxl-10 {
        padding-top: 10rem !important;
        padding-bottom: 10rem !important;
    }

    .py-xxl-11 {
        padding-top: 12.5rem !important;
        padding-bottom: 12.5rem !important;
    }

    .py-xxl-12 {
        padding-top: 15rem !important;
        padding-bottom: 15rem !important;
    }

    .pt-xxl-0 {
        padding-top: 0 !important;
    }

    .pt-xxl-1 {
        padding-top: 0.25rem !important;
    }

    .pt-xxl-2 {
        padding-top: 0.5rem !important;
    }

    .pt-xxl-3 {
        padding-top: 1rem !important;
    }

    .pt-xxl-4 {
        padding-top: 1.8rem !important;
    }

    .pt-xxl-5 {
        padding-top: 2.5rem !important;
    }

    .pt-xxl-6 {
        padding-top: 3rem !important;
    }

    .pt-xxl-7 {
        padding-top: 4rem !important;
    }

    .pt-xxl-8 {
        padding-top: 5rem !important;
    }

    .pt-xxl-9 {
        padding-top: 7.5rem !important;
    }

    .pt-xxl-10 {
        padding-top: 10rem !important;
    }

    .pt-xxl-11 {
        padding-top: 12.5rem !important;
    }

    .pt-xxl-12 {
        padding-top: 15rem !important;
    }

    .pe-xxl-0 {
        padding-right: 0 !important;
    }

    .pe-xxl-1 {
        padding-right: 0.25rem !important;
    }

    .pe-xxl-2 {
        padding-right: 0.5rem !important;
    }

    .pe-xxl-3 {
        padding-right: 1rem !important;
    }

    .pe-xxl-4 {
        padding-right: 1.8rem !important;
    }

    .pe-xxl-5 {
        padding-right: 2.5rem !important;
    }

    .pe-xxl-6 {
        padding-right: 3rem !important;
    }

    .pe-xxl-7 {
        padding-right: 4rem !important;
    }

    .pe-xxl-8 {
        padding-right: 5rem !important;
    }

    .pe-xxl-9 {
        padding-right: 7.5rem !important;
    }

    .pe-xxl-10 {
        padding-right: 10rem !important;
    }

    .pe-xxl-11 {
        padding-right: 12.5rem !important;
    }

    .pe-xxl-12 {
        padding-right: 15rem !important;
    }

    .pb-xxl-0 {
        padding-bottom: 0 !important;
    }

    .pb-xxl-1 {
        padding-bottom: 0.25rem !important;
    }

    .pb-xxl-2 {
        padding-bottom: 0.5rem !important;
    }

    .pb-xxl-3 {
        padding-bottom: 1rem !important;
    }

    .pb-xxl-4 {
        padding-bottom: 1.8rem !important;
    }

    .pb-xxl-5 {
        padding-bottom: 2.5rem !important;
    }

    .pb-xxl-6 {
        padding-bottom: 3rem !important;
    }

    .pb-xxl-7 {
        padding-bottom: 4rem !important;
    }

    .pb-xxl-8 {
        padding-bottom: 5rem !important;
    }

    .pb-xxl-9 {
        padding-bottom: 7.5rem !important;
    }

    .pb-xxl-10 {
        padding-bottom: 10rem !important;
    }

    .pb-xxl-11 {
        padding-bottom: 12.5rem !important;
    }

    .pb-xxl-12 {
        padding-bottom: 15rem !important;
    }

    .ps-xxl-0 {
        padding-left: 0 !important;
    }

    .ps-xxl-1 {
        padding-left: 0.25rem !important;
    }

    .ps-xxl-2 {
        padding-left: 0.5rem !important;
    }

    .ps-xxl-3 {
        padding-left: 1rem !important;
    }

    .ps-xxl-4 {
        padding-left: 1.8rem !important;
    }

    .ps-xxl-5 {
        padding-left: 2.5rem !important;
    }

    .ps-xxl-6 {
        padding-left: 3rem !important;
    }

    .ps-xxl-7 {
        padding-left: 4rem !important;
    }

    .ps-xxl-8 {
        padding-left: 5rem !important;
    }

    .ps-xxl-9 {
        padding-left: 7.5rem !important;
    }

    .ps-xxl-10 {
        padding-left: 10rem !important;
    }

    .ps-xxl-11 {
        padding-left: 12.5rem !important;
    }

    .ps-xxl-12 {
        padding-left: 15rem !important;
    }

    .fs-xxl--2 {
        font-size: 0.79012rem !important;
    }

    .fs-xxl--1 {
        font-size: 0.88889rem !important;
    }

    .fs-xxl-0 {
        font-size: 1rem !important;
    }

    .fs-xxl-1 {
        font-size: 1.125rem !important;
    }

    .fs-xxl-2 {
        font-size: 1.26563rem !important;
    }

    .fs-xxl-3 {
        font-size: 1.42383rem !important;
    }

    .fs-xxl-4 {
        font-size: 1.60181rem !important;
    }

    .fs-xxl-5 {
        font-size: 1.80203rem !important;
    }

    .fs-xxl-6 {
        font-size: 2.02729rem !important;
    }

    .fs-xxl-7 {
        font-size: 2.2807rem !important;
    }

    .fs-xxl-8 {
        font-size: 2.56578rem !important;
    }

    .fs-xxl-9 {
        font-size: 2.88651rem !important;
    }

    .fs-xxl-10 {
        font-size: 3.24732rem !important;
    }

    .fs-xxl-11 {
        font-size: 3.65324rem !important;
    }

    .fs-xxl-12 {
        font-size: 4.10989rem !important;
    }

    .fs-xxl-13 {
        font-size: 4.62363rem !important;
    }

    .fs-xxl-14 {
        font-size: 5.20158rem !important;
    }

    .lh-xxl-unset {
        line-height: unset !important;
    }

    .lh-xxl-1 {
        line-height: 1 !important;
    }

    .lh-xxl-1-3 {
        line-height: 1.3 !important;
    }

    .lh-xxl-1-4 {
        line-height: 1.4 !important;
    }

    .lh-xxl-1-5 {
        line-height: 1.5 !important;
    }

    .lh-xxl-1-6 {
        line-height: 1.6 !important;
    }

    .lh-xxl-1-7 {
        line-height: 1.7 !important;
    }

    .lh-xxl-1-8 {
        line-height: 1.8 !important;
    }

    .lh-xxl-1-9 {
        line-height: 1.9 !important;
    }

    .lh-xxl-2 {
        line-height: 2 !important;
    }

    .text-xxl-start {
        text-align: left !important;
    }

    .text-xxl-end {
        text-align: right !important;
    }

    .text-xxl-center {
        text-align: center !important;
    }

    .rounded-xxl-top {
        border-top-left-radius: 1.5rem !important;
        border-top-right-radius: 1.5rem !important;
    }

    .rounded-xxl-top-lg {
        border-top-left-radius: 2.25rem !important;
        border-top-right-radius: 2.25rem !important;
    }

    .rounded-xxl-top-0 {
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
    }

    .rounded-xxl-end {
        border-top-right-radius: 1.5rem !important;
        border-bottom-right-radius: 1.5rem !important;
    }

    .rounded-xxl-end-lg {
        border-top-right-radius: 2.25rem !important;
        border-bottom-right-radius: 2.25rem !important;
    }

    .rounded-xxl-end-0 {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }

    .rounded-xxl-bottom {
        border-bottom-right-radius: 1.5rem !important;
        border-bottom-left-radius: 1.5rem !important;
    }

    .rounded-xxl-bottom-lg {
        border-bottom-right-radius: 2.25rem !important;
        border-bottom-left-radius: 2.25rem !important;
    }

    .rounded-xxl-bottom-0 {
        border-bottom-right-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }

    .rounded-xxl-start {
        border-bottom-left-radius: 1.5rem !important;
        border-top-left-radius: 1.5rem !important;
    }

    .rounded-xxl-start-lg {
        border-bottom-left-radius: 2.25rem !important;
        border-top-left-radius: 2.25rem !important;
    }

    .rounded-xxl-start-0 {
        border-bottom-left-radius: 0 !important;
        border-top-left-radius: 0 !important;
    }

    .opacity-xxl-0 {
        opacity: 0 !important;
    }

    .opacity-xxl-25 {
        opacity: 0.25 !important;
    }

    .opacity-xxl-50 {
        opacity: 0.5 !important;
    }

    .opacity-xxl-75 {
        opacity: 0.75 !important;
    }

    .opacity-xxl-85 {
        opacity: 0.85 !important;
    }

    .opacity-xxl-100 {
        opacity: 1 !important;
    }

    .max-vh-xxl-25 {
        max-height: 25vh !important;
    }

    .max-vh-xxl-50 {
        max-height: 50vh !important;
    }

    .max-vh-xxl-75 {
        max-height: 75vh !important;
    }

    .max-vh-xxl-100 {
        max-height: 100vh !important;
    }

    .border-xxl-x {
        border-left: 1px solid #eeeeee !important;
        border-right: 1px solid #eeeeee !important;
    }

    .border-xxl-x-0 {
        border-left: 0 !important;
        border-right: 0 !important;
    }

    .border-xxl-y {
        border-top: 1px solid #eeeeee !important;
        border-bottom: 1px solid #eeeeee !important;
    }

    .border-xxl-y-0 {
        border-top: 0 !important;
        border-bottom: 0 !important;
    }

    .border-xxl-dashed {
        border: 1px dashed #eeeeee !important;
    }

    .border-xxl-dashed-top {
        border-top: 1px dashed #eeeeee !important;
    }

    .border-xxl-dashed-end {
        border-right: 1px dashed #eeeeee !important;
    }

    .border-xxl-dashed-start {
        border-left: 1px dashed #eeeeee !important;
    }

    .border-xxl-dashed-bottom {
        border-bottom: 1px dashed #eeeeee !important;
    }

    .border-xxl-dashed-x {
        border-left: 1px dashed #eeeeee !important;
        border-right: 1px dashed #eeeeee !important;
    }

    .border-xxl-dashed-y {
        border-top: 1px dashed #eeeeee !important;
        border-bottom: 1px dashed #eeeeee !important;
    }

    .rounded-xxl-0 {
        border-radius: 0 !important;
    }
}

@media print {
    .d-print-inline {
        display: inline !important;
    }

    .d-print-inline-block {
        display: inline-block !important;
    }

    .d-print-block {
        display: block !important;
    }

    .d-print-grid {
        display: grid !important;
    }

    .d-print-table {
        display: table !important;
    }

    .d-print-table-row {
        display: table-row !important;
    }

    .d-print-table-cell {
        display: table-cell !important;
    }

    .d-print-flex {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
    }

    .d-print-inline-flex {
        display: -webkit-inline-box !important;
        display: -ms-inline-flexbox !important;
        display: inline-flex !important;
    }

    .d-print-none {
        display: none !important;
    }
}

/*-----------------------------------------------
  |   Theme Styles
  -----------------------------------------------*/
/*-----------------------------------------------
  |   Reboot
  -----------------------------------------------*/
::-webkit-input-placeholder {
    opacity: 1;
    color: #e0e0e0 !important;
}

::-moz-placeholder {
    opacity: 1;
    color: #e0e0e0 !important;
}

:-ms-input-placeholder {
    opacity: 1;
    color: #e0e0e0 !important;
}

::-ms-input-placeholder {
    opacity: 1;
    color: #e0e0e0 !important;
}

::placeholder {
    opacity: 1;
    color: #e0e0e0 !important;
}

html {
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: smooth;
    scroll-padding-top: 6.3125rem;
}

header {
    position: relative;
    z-index: 1020;
    background: #fffefe;
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
}

pre,
code {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
}

section {
    position: relative;
    padding-top: 3rem;
    padding-bottom: 3rem;
}

@media (min-width: 992px) {
    section {
        padding-top: 5rem;
        padding-bottom: 5rem;
    }
}

input,
button,
select,
optgroup,
textarea,
label,
.alert,
.badge,
.blockquote-footer,
.btn,
.navbar,
.pagination,
.valid-feedback,
.invalid-feedback {
    font-family: "Poppins", "Rubik", "Open Sans", -apple-system,
        BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif,
        "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

pre {
    margin: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

strong {
    font-weight: 600;
}

label {
    font-weight: 500 !important;
    margin-bottom: 0.5rem;
}

ol,
ul {
    padding-left: 1.1rem;
}

iframe {
    border: none;
}

/* -------------------------------------------------------------------------- */
/*                               Browser Fixing                               */
/* -------------------------------------------------------------------------- */
.firefox .dropcap:first-letter {
    margin-top: 0.175em;
}

.chrome .btn-close {
    background-clip: unset;
}

/* -------------------------------------------------------------------------- */
/*                                    Text                                    */
/* -------------------------------------------------------------------------- */
.text-smallcaps {
    font-variant: small-caps;
}

.text-superscript {
    vertical-align: super;
}

.text-word-break {
    word-break: break-word;
}

/*-----------------------------------------------
  |   Font family
  -----------------------------------------------*/
.font-sans-serif {
    font-family: "Poppins", "Rubik", "Open Sans", -apple-system,
        BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif,
        "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.font-base {
    font-family: "Poppins", "Rubik", -apple-system, BlinkMacSystemFont, "Segoe UI",
        "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
        "Segoe UI Symbol";
}

.font-cursive {
    font-family: "Volkhov", "DM Serif Display", cursive;
}

/*-----------------------------------------------
  |   Error Page
  -----------------------------------------------*/
.fs-error {
    font-size: 7rem;
}

@media (min-width: 576px) {
    .fs-error {
        font-size: 10rem;
    }
}

/*-----------------------------------------------
  |   Text alignment
  -----------------------------------------------*/
.text-justify {
    text-align: justify !important;
}

.text-gradient {
    background: -o-linear-gradient(40deg, #fb3c00, #ffb800);
    background: linear-gradient(50deg, #fb3c00, #ffb800);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

/*-----------------------------------------------
  |   Backgrounds
  -----------------------------------------------*/
.bg-holder {
    position: absolute;
    width: 100%;
    min-height: 100%;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    will-change: transform, opacity, filter;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background-repeat: no-repeat;
    z-index: 0;
}

.bg-holder.bg-right {
    left: auto;
    right: 0;
}

.bg-holder.overlay:before {
    position: absolute;
    content: "";
    background: rgba(0, 0, 0, 0.25);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.bg-holder.overlay-0:before {
    background: rgba(0, 0, 0, 0.7);
}

.bg-holder.overlay-1:before {
    background: rgba(0, 0, 0, 0.55);
}

.bg-holder.overlay-2:before {
    background: rgba(0, 0, 0, 0.4);
}

.bg-holder.overlay-light:before {
    background: rgba(255, 255, 255, 0.8);
}

.bg-holder .bg-video {
    position: absolute;
    display: block;
    z-index: -1;
    top: 0;
    left: 0;
    -o-object-fit: cover;
    object-fit: cover;
    height: 100%;
    min-width: 100%;
}

.bg-holder .bg-youtube {
    position: absolute !important;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.bg-primary-gradient {
    background: #fff7f5;
    -webkit-box-shadow: 0px 20px 40px rgba(238, 77, 71, 0.1);
    box-shadow: 0px 20px 40px rgba(238, 77, 71, 0.1);
}

/* -------------------------------------------------------------------------- */
/*                                   Borders                                  */
/* -------------------------------------------------------------------------- */
.overflow-hidden[class*="rounded"] {
    -webkit-mask-image: radial-gradient(#fffefe, #000);
    mask-image: radial-gradient(#fffefe, #000);
}

.border-top-2 {
    border-top-width: 0.125rem !important;
}

.border-end-2 {
    border-right-width: 0.125rem !important;
}

.border-bottom-2 {
    border-bottom-width: 0.125rem !important;
}

.border-start-2 {
    border-left-width: 0.125rem !important;
}

.hr-vertical {
    border-left: 2px solid #cfcfcf;
}

/* -------------------------------------------------------------------------- */
/*                                  Position                                  */
/* -------------------------------------------------------------------------- */
.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
    position: relative;
}

.all-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

/* -------------------------------------------------------------------------- */
/*                                    Flex                                    */
/* -------------------------------------------------------------------------- */
.flex-center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.flex-between-center {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.flex-end-center {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.flex-1 {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.link-black {
    color: #000 !important;
}

.link-black:hover,
.link-black:focus {
    color: #262626 !important;
}

.link-100 {
    color: #fcfcfc !important;
}

.link-100:hover,
.link-100:focus {
    color: white !important;
}

.link-200 {
    color: #f5f5f5 !important;
}

.link-200:hover,
.link-200:focus {
    color: white !important;
}

.link-300 {
    color: #eeeeee !important;
}

.link-300:hover,
.link-300:focus {
    color: white !important;
}

.link-400 {
    color: #e0e0e0 !important;
}

.link-400:hover,
.link-400:focus {
    color: white !important;
}

.link-500 {
    color: #bdbdbd !important;
}

.link-500:hover,
.link-500:focus {
    color: #e3e3e3 !important;
}

.link-600 {
    color: #f0f4f9 !important;
}

.link-600:hover,
.link-600:focus {
    color: white !important;
}

.link-700 {
    color: #757575 !important;
}

.link-700:hover,
.link-700:focus {
    color: #9b9b9b !important;
}

.link-800 {
    color: #616161 !important;
}

.link-800:hover,
.link-800:focus {
    color: #878787 !important;
}

.link-900 {
    color: #5e6282 !important;
}

.link-900:hover,
.link-900:focus {
    color: #f1a501 !important;
}

.link-1000 {
    color: #212832 !important;
}

.link-1000:hover,
.link-1000:focus {
    color: #3f4d60 !important;
}

.link-white {
    color: #fffefe !important;
}

.link-white:hover,
.link-white:focus {
    color: white !important;
}

.hover-top-shadow {
    -webkit-transform: translateY(0) translateZ(0);
    transform: translateY(0) translateZ(0);
    -webkit-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.hover-top-shadow.hover-top-shadow-lg:hover,
.hover-top-shadow.hover-top-shadow-lg:focus {
    -webkit-transform: translateY(-0.3125rem) translateZ(0);
    transform: translateY(-0.3125rem) translateZ(0);
}

.hover-top-shadow:hover,
.hover-top-shadow:focus {
    -webkit-transform: translateY(-0.125rem) translateZ(0);
    transform: translateY(-0.125rem) translateZ(0);
    -webkit-box-shadow: 0 0 0.5rem 0 rgba(241, 165, 1, 0.8) !important;
    box-shadow: 0 0 0.5rem 0 rgba(241, 165, 1, 0.8) !important;
}

.shadow-transition {
    -webkit-box-shadow: 0 1.5625rem 2.8125rem rgba(94, 98, 130, 0.075);
    box-shadow: 0 1.5625rem 2.8125rem rgba(94, 98, 130, 0.075);
    -webkit-transition: -webkit-box-shadow 0.4s ease;
    transition: -webkit-box-shadow 0.4s ease;
    -o-transition: box-shadow 0.4s ease;
    transition: box-shadow 0.4s ease;
    transition: box-shadow 0.4s ease, -webkit-box-shadow 0.4s ease;
}

.shadow-warning {
    -webkit-box-shadow: 0 0 3rem 0 rgba(241, 165, 1, 0.3) !important;
    box-shadow: 0 0 3rem 0 rgba(241, 165, 1, 0.3) !important;
}

.shadow-icon {
    -webkit-filter: drop-shadow(5px 5px 5px rgba(241, 165, 1, 0.3));
    filter: drop-shadow(5px 5px 5px rgba(241, 165, 1, 0.3));
}

.shadow-primary {
    -webkit-box-shadow: 0px 20px 40px rgba(238, 77, 71, 0.1);
    box-shadow: 0px 20px 40px rgba(238, 77, 71, 0.1);
}

/*-----------------------------------------------
  |   Icons group
  -----------------------------------------------*/
.icon-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.icon-group .icon-item:not(:last-child) {
    margin-right: 1.8rem;
}

.icon-item {
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #080809;
    background-color: #eeeeee;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    height: 2.5rem;
    width: 2.5rem;
    border: 0;
    font-size: 0.875rem;
}

.icon-item#facebook {
    background: #fffefe;
}

.icon-item#facebook:hover,
.icon-item#facebook:focus {
    background-color: #3c5a99;
}

.icon-item#instagram {
    background: #fffefe;
}

.icon-item#instagram:hover,
.icon-item#instagram:focus {
    background-image: conic-gradient(from 180deg at 50% 50%,
            #b8d2f1 0deg,
            #f289aa 60deg,
            #c68bf0 106.09deg,
            #d164da 153.75deg,
            #c963e8 221.25deg,
            #bfc2e8 258.75deg,
            #ffc999 288.75deg,
            #d0d8c9 315deg,
            #bad0f1 334.13deg,
            #ced8cb 358.97deg,
            rgba(255, 255, 255, 0) 360deg);
}

.icon-item#twitter {
    background: #fffefe;
}

.icon-item#twitter:hover,
.icon-item#twitter:focus {
    background-color: #1da1f2;
}

.icon-item#whatsapp {
    background: #fffefe;
}

.icon-item#whatsapp:hover,
.icon-item#whatsapp:focus {
    background-color: #25D366;
}

.icon-item:hover,
.icon-item:focus {
    color: #fffefe;
}

.icon-item.icon-item-sm {
    height: 1.875rem;
    width: 1.875rem;
}

.icon-item.icon-item-lg {
    height: 2.75rem;
    width: 2.75rem;
}

.fontawesome-i2svg-active .icon-indicator {
    opacity: 1;
}

/*-----------------------------------------------
  |   Object fit and Z-index
  -----------------------------------------------*/
.fit-cover {
    -o-object-fit: cover;
    object-fit: cover;
}

.z-index-1 {
    z-index: 1;
}

.z-index-2 {
    z-index: 2 !important;
}

.z-index--1 {
    z-index: -1;
}

/*-----------------------------------------------
  |   Sidebar
  -----------------------------------------------*/
.sticky-sidebar {
    position: -webkit-sticky;
    position: sticky;
    z-index: 1020;
    top: 5.3125rem;
}

/*-----------------------------------------------
  |   Custom circle
  -----------------------------------------------*/
.circle-dashed {
    border: 1px dashed #f1a501;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 50%;
    width: 3.5rem;
    height: 3.5rem;
}

/*-----------------------------------------------
  |   Miscellaneous
  -----------------------------------------------*/
.hover-text-decoration-none:hover,
.hover-text-decoration-none:focus {
    text-decoration: none;
}

.resize-none {
    resize: none;
}

.collapsed .collapse-icon {
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
}

.collapse-icon {
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}

[data-dismiss="dropdown"] *,
[data-offset-top] *,
[data-toggle="collapse"] *,
[data-toggle="tooltip"] *,
[data-toggle="popover"] * {
    pointer-events: none;
}

/*-----------------------------------------------
  |   Outline
  -----------------------------------------------*/
.outline-none {
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.outline-none:hover,
.outline-none:focus {
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

/*-----------------------------------------------
  |   Vertical Line (used in kanban header)
  -----------------------------------------------*/
.vertical-line:after {
    position: absolute;
    content: "";
    height: 75%;
    width: 1px;
    background: #eeeeee;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.vertical-line.vertical-line-400:after {
    background-color: #e0e0e0;
}

/* -------------------------------------------------------------------------- */
/*                                 Transition                                 */
/* -------------------------------------------------------------------------- */
.transition-base {
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.transition-none {
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
}

.fsp-75 {
    font-size: 75%;
}

/* -------------------------------------------------------------------------- */
/*                                    Width                                   */
/* -------------------------------------------------------------------------- */
.min-w-0 {
    min-width: 0;
}

/* -------------------------------------------------------------------------- */
/*                                Terms sidebar                               */
/* -------------------------------------------------------------------------- */
.icon {
    padding: 1rem;
    border-radius: 15px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    background-color: rgba(255, 254, 254, 0.2);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 254, 254, 0.3);
}

.icon-primary {
    color: #f1a501;
}

.icon-secondary {
    color: #5e6282;
}

.icon-success {
    color: #79b93c;
}

.icon-info {
    color: #006380;
}

.icon-warning {
    color: #f1a501;
}

.icon-danger {
    color: #df6951;
}

.icon-light {
    color: #f0f4f9;
}

.icon-dark {
    color: #212832;
}

/* -------------------------------------------------------------------------- */
/*        Heading                        Background                               */
/* -------------------------------------------------------------------------- */
.footer-heading-color {
    color: #080809;
}

.shadow-social {
    -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1) !important;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1) !important;
}

.backdrop {
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
}

.navbar {
    -webkit-transition: padding 0.3s linear;
    -o-transition: padding 0.3s linear;
    transition: padding 0.3s linear;
}

.padding-transition {
    padding: 1.25rem 0rem !important;
}

.show-onhover:hover .hideEl {
    visibility: visible;
    opacity: 1;
}

.show-onhover .hideEl {
    visibility: hidden;
    opacity: 0;
    -webkit-transition: opacity 0.15s linear;
    -o-transition: opacity 0.15s linear;
    transition: opacity 0.15s linear;
}

.shadow-hover {
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.shadow-hover:hover {
    -webkit-box-shadow: 0 6.25rem 5rem 0 rgba(0, 0, 0, 0.02),
        0 4.05063rem 2.92813rem 0 rgba(0, 0, 0, 0.0152),
        0 2.4075rem 1.5925rem 0 rgba(0, 0, 0, 0.0121),
        0 1.25rem 0.8125rem 0 rgba(0, 0, 0, 0.01),
        0 0.50938rem 0.4075rem 0 rgba(0, 0, 0, 0.0079),
        0 0.11563rem 0.19687rem 0 rgba(0, 0, 0, 0.0048);
    box-shadow: 0 6.25rem 5rem 0 rgba(0, 0, 0, 0.02),
        0 4.05063rem 2.92813rem 0 rgba(0, 0, 0, 0.0152),
        0 2.4075rem 1.5925rem 0 rgba(0, 0, 0, 0.0121),
        0 1.25rem 0.8125rem 0 rgba(0, 0, 0, 0.01),
        0 0.50938rem 0.4075rem 0 rgba(0, 0, 0, 0.0079),
        0 0.11563rem 0.19687rem 0 rgba(0, 0, 0, 0.0048);
}

.round-btn-lg {
    height: 3.25rem;
    width: 3.25rem;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 0;
}

.primary-btn-shadow {
    -webkit-box-shadow: 0 1.25rem 2.1875rem 0 rgba(241, 165, 1, 0.15);
    box-shadow: 0 1.25rem 2.1875rem 0 rgba(241, 165, 1, 0.15);
}

.danger-btn-shadow {
    -webkit-box-shadow: 0 0.9375rem 1.875rem 0 rgba(223, 105, 81, 0.3);
    box-shadow: 0 0.9375rem 1.875rem 0 rgba(223, 105, 81, 0.3);
}

.orange-gradient-btn {
    background-image: -webkit-gradient(linear,
            left top,
            left bottom,
            from(#ff946d),
            to(#ff7d68));
    background-image: -o-linear-gradient(top, #ff946d, #ff7d68);
    background-image: linear-gradient(to bottom, #ff946d, #ff7d68);
    border: none;
    border-radius: 0.625rem;
    padding: 1rem 2.5rem;
}

.orange-gradient-btn:hover,
.orange-gradient-btn:focus {
    background-image: -webkit-gradient(linear,
            left top,
            left bottom,
            from(#ff7d68),
            to(#ff946d));
    background-image: -o-linear-gradient(top, #ff7d68, #ff946d);
    background-image: linear-gradient(to bottom, #ff7d68, #ff946d);
}

/* Hover */
.hover-top {
    -webkit-transform: translateY(0) translateZ(0);
    transform: translateY(0) translateZ(0);
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.hover-top.hover-top-shadow-lg:hover,
.hover-top.hover-top-shadow-lg:focus {
    -webkit-transform: translateY(-0.3125rem) translateZ(0);
    transform: translateY(-0.3125rem) translateZ(0);
}

.hover-top:hover,
.hover-top:focus {
    -webkit-transform: translateY(-0.125rem) translateZ(0);
    transform: translateY(-0.125rem) translateZ(0);
    -webkit-box-shadow: 0.5rem 0.5rem 1.5rem rgba(110, 74, 156, 0.1) !important;
    box-shadow: 0.5rem 0.5rem 1.5rem rgba(110, 74, 156, 0.1) !important;
    background-color: #d42da3;
    color: #fffefe;
}

.hover-top:hover .heading-color,
.hover-top:focus .heading-color {
    color: #fffefe;
}

/* Icon Forward */
.hvr-icon-forward {
    -webkit-transition-duration: 0.1s;
    -o-transition-duration: 0.1s;
    transition-duration: 0.1s;
}

.hvr-icon-forward .hover-icon {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-transition-duration: 0.1s;
    -o-transition-duration: 0.1s;
    transition-duration: 0.1s;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    -o-transition-property: transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-timing-function: ease-out;
    -o-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}

.hvr-icon-forward:hover .hover-icon,
.hvr-icon-forward:focus .hover-icon,
.hvr-icon-forward:active .hover-icon {
    -webkit-transform: translateX(4px);
    -ms-transform: translateX(4px);
    transform: translateX(4px);
}

/* -------------------------------------------------------------------------- */
/*                                    Badge                                   */
/* -------------------------------------------------------------------------- */
.carousel-indicators {
    right: unset;
    margin-left: 1.2rem;
}

@media (max-width: 991.98px) {
    .carousel-indicators {
        -webkit-transform: translateY(6rem);
        -ms-transform: translateY(6rem);
        transform: translateY(6rem);
        right: 0;
        margin-left: 15%;
    }
}

.carousel-indicators [data-bs-target] {
    background-color: #212832;
    width: 0.6875rem;
    height: 0.6875rem;
    opacity: 0.3;
    border-radius: 50%;
}

.carousel-indicators [data-bs-target]:not(:last-child) {
    margin-right: 1.5625rem;
}

.carousel-indicators [data-bs-target].active {
    opacity: 1;
}

.carousel-inner {
    overflow: unset;
}

.hero-title {
    font-family: "Volkhov", "DM Serif Display", cursive;
    font-weight: 700;
    font-size: 1.88651rem;
    color: #181e4b;
    margin-bottom: 5px;
    position: relative;
    z-index: 1;
}

@media (min-width: 1200px) {
    .hero-title {
        font-size: 2.62363rem;
    }
}

@media (min-width: 1400px) {
    .hero-title {
        font-size: 3.20158rem;
    }
}

.hero-title:before {
    content: "";
    position: absolute;
    right: 0.9375rem;
    top: 2.5rem;
    width: 20rem;
    height: 0.8125rem;
    background-size: contain;
    z-index: -1;
}

@media (min-width: 992px) {
    .hero-title:before {
        top: 2.8125rem;
    }
}

@media (min-width: 1200px) {
    .hero-title:before {
        font-size: 4.62363rem;
        top: 4.1875rem;
        right: -0.9375rem;
    }
}

@media (min-width: 1400px) {
    .hero-title:before {
        font-size: 5.20158rem;
        top: 4.6875rem;
        width: 23.125rem;
    }
}

.hero-img {
    width: 105%;
    margin-left: -5rem;
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .hero-img {
        width: 135%;
        margin-left: -4rem;
    }
}

.card {
    position: relative;
    overflow: hidden;
}

.card.service-card:before {
    content: "";
    position: absolute;
    left: 0.625rem;
    bottom: 0.625rem;
    width: 6.25rem;
    height: 6.25rem;
    background-color: #df6951;
    border-radius: 1.875rem 0 0.625rem 0;
    z-index: -1;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

@media (max-width: 767.98px) {
    .card.service-card:before {
        width: 3.75rem;
        height: 3.75rem;
        border-radius: 1.25rem 0 0.5rem 0;
    }
}

.card.service-card:hover:before {
    left: -1.875rem;
    bottom: -2.1875rem;
}

@media (max-width: 767.98px) {
    .card.service-card:hover:before {
        left: -0.9375rem;
        bottom: -1.5625rem;
    }
}

.input-group-icon {
    position: relative;
}

.input-group-icon .input-box {
    padding-left: 2.8rem;
    vertical-align: middle;
}

.input-group-icon .input-box-icon {
    position: absolute;
    top: 50%;
    left: 1rem;
    color: #212529;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.form-little-squirrel-control {
    border-radius: 0;
    background-color: #ffffff;
    border-radius: 10px;
    color: #39425d;
    padding: 1rem 1rem 1rem 2.7rem;
    border: none;
    font-size: 14px;
}

.form-little-squirrel-control:hover,
.form-little-squirrel-control:focus {
    background-color: #ffffff;
}

.majestic-tabs {
    border-bottom: 0;
}

.majestic-tabs .nav-link {
    border: 2px solid transparent;
    color: #616161;
}

.majestic-tabs .nav-link:hover,
.majestic-tabs .nav-link:focus {
    border-color: transparent;
}

.majestic-tabs .nav-link.active,
.majestic-tabs .nav-item.show .nav-link {
    border-bottom-color: #df6951;
}

.custom-danger {
    font-size: 1.5rem;
    font-weight: 700;
    color: #DF6951;
}

.custom-text {
    display: flex;
    flex-direction: column;
    /* align-items: center;
    justify-content: center;
    text-align: center; */
}

.bold-text {
    font-size: 1.5rem;
    font-weight: 700;
    color: #181E4B;
    margin-right: 0.5rem;
}

.text-content {
    font-size: 1.2rem;
    font-weight: 400;
    color: #333;
    transition: color 0.3s ease, transform 0.3s ease;
}

.text-content:hover {
    color: #E94B3C;
    transform: scale(1.05);
}

.card-img-top {
    transition: transform 0.3s ease;
}

.card-img-top:hover {
    transform: scale(1.1);
}

.navbar {
    transition: background-color 0.3s ease;
    z-index: 999;
}

.navbar.scrolled {
    background-color: rgba(255, 255, 255, 0.9);
    /* White background with some transparency */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* Optional: adds a subtle shadow */
}

.navbar-light .navbar-nav .nav-link {
    color: #333;
}

.navbar.scrolled .navbar-light .navbar-nav .nav-link {
    color: #000;
}

.card {
    transition: transform 0.3s ease-in-out;
}

.card:hover {
    transform: scale(0.95);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    opacity: 0.9;
}